import { Box, Stack, Tab, Tabs, useTheme } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingBackdrop from "../../../Components/MaterialUI/LoadingBackdrop";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { getQueryAccountingArticles, getQueryAccountingBalance, getQueryAccountingGeneralJournal, getQueryAccountingGeneralLedger, getQueryAccountingTrialBalance } from "./API/apiAccountingGetQueries";
import callApi, { Query } from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import ResponsiveTableGrid from "../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import {TableQuerySnippet } from "./API/apiAccountingSnippets";


const AccountingPage: React.FC<any> = ({
}) => {
  // const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const location = useLocation();

  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [tabView, setTabView] = useState<number>(location.state?.tab || 0);
  const [tableData, setTableData] = useState<TableQuerySnippet | null>(null);
  const [balance, setBalance] = useState<TableQuerySnippet | null>(null)
  const [trialBalance, setTrialBalance] = useState<TableQuerySnippet | null>(null)
  const [generalJournal, setGeneralJournal] = useState<TableQuerySnippet | null>(null)
  const [generalLedger, setGeneralLedger] = useState<TableQuerySnippet | null>(null)
  
  const fetchData = async (
      queryFunction: () => Query, 
      setState: React.Dispatch<React.SetStateAction<TableQuerySnippet | null>>
  ) => {
    try {
        const data = await callApi<TableQuerySnippet>({
            query: queryFunction(),
            auth: { setAuthedUser },
        });
        setState(data);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

useEffect(() => {
    const loadData = async () => {
        setPageStatus("loading");
        await Promise.all([
            fetchData(getQueryAccountingArticles, setTableData),
            fetchData(getQueryAccountingBalance, setBalance),
            fetchData(getQueryAccountingTrialBalance, setTrialBalance),
            fetchData(getQueryAccountingGeneralJournal, setGeneralJournal),
            fetchData(getQueryAccountingGeneralLedger, setGeneralLedger)
        ]);
        setPageStatus(null);
    };
    loadData();
}, []);

  return (
    <>
      <Stack alignItems={"center"} justifyContent="center">
        <Box sx={{ width: { xs: "100%", md: "auto" } }} component="div">
          <Tabs
            css={styles.contentBreak}
            sx={{ overflowX: "auto" }}
            value={tabView}
            onChange={(_: any, val: React.SetStateAction<number>) => setTabView(val)}
            aria-label="configure tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Balance" />
            <Tab label="Trial Balance" />
            <Tab label="Accounting Articles" />
            <Tab label="General Journal" />
            <Tab label="General Ledger"/>
          </Tabs>
        </Box>
      </Stack>

      {pageStatus === "loading" ? (
        <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
          <LoadingBackdrop loading={pageStatus === "loading"} />
        </Box>
      ) : (      <>
        {tabView === 0 && (
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            editMode={false}
            responsive="desktop"
            configuration={tableData?.configuration}
            tableID="Accounting_articles_table"
          />
        )}

        {tabView === 1 && (
          <ResponsiveTableGrid
              rows={balance?.rows || []}
              colSchema={balance?.columns || []}
              editMode={false}
              responsive="desktop"
              configuration={balance?.configuration}
              tableID="Balance_modal_table"
            />
        )}

        {tabView === 2 && (
          <ResponsiveTableGrid
            rows={trialBalance?.rows || []}
            colSchema={trialBalance?.columns || []}
            editMode={false}
            responsive="desktop"
            configuration={trialBalance?.configuration}
            tableID="Trial_Balance_modal_table"
        />
        )}

        {tabView === 3 && (
          <ResponsiveTableGrid
            rows={generalJournal?.rows || []}
            colSchema={generalJournal?.columns || []}
            editMode={false}
            responsive="desktop"
            configuration={generalJournal?.configuration}
            tableID="General_Journal_modal_table"
        />
        )}

        {tabView === 4 && (
          <ResponsiveTableGrid
            rows={generalLedger?.rows || []}
            colSchema={generalLedger?.columns || []}
            editMode={false}
            responsive="desktop"
            configuration={generalLedger?.configuration}
            tableID="General_Ledger_modal_table"
        />
        )}  
      </>
    )}
  </>
  );
};

export default AccountingPage;

