
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { WorkflowsIntervalJoinNodeData } from "../../workflowUtils";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";
import { Stack, Box, Typography } from "@mui/material";

interface NodeIntervalJoinDataModalProps {
  data: WorkflowsIntervalJoinNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeIntervalJoinDataModal: React.FC<NodeIntervalJoinDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();
  
  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      <LabelWithBoldedPart text={t("Left Key")} bolded={data.left_key || ""} />
      <LabelWithBoldedPart text={t("Right Start Key")} bolded={data.right_start_key || ""} />
      <LabelWithBoldedPart text={t("Right End Key")} bolded={data.right_end_key || ""} />
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_RowAggregation_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeIntervalJoinDataModal; 