import React from "react";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import {
  OperatingConditionChartData,
  getMachineMessageFullName,
  getMessageColor,
  parseSecondsToHMS,
} from "./machineStatusUtils";
import { ResponsiveBar, BarCustomLayerProps, BarTooltipProps } from "@nivo/bar";
import ContentBox from "../../MaterialUI/ContentBox";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssComponentsStyles from "../../../Global/Styles/components";

interface MachineStatusOperatingConditionChartProps {
  operatingConditionChartData: OperatingConditionChartData[];
  selectedMachineId: string;
  selectedTimeRange: string;
  selectedMachineMessage: string;
}

const MachineStatusOperatingConditionChart: React.FC<
  MachineStatusOperatingConditionChartProps
> = ({ operatingConditionChartData, selectedMachineId }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };

  const keys = operatingConditionChartData.reduce((acc, item) => {
    Object.keys(item).forEach((key) => {
      if (key !== "id" && !acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, [] as string[]);

  return (
    <ContentBox>
      <Typography css={styles.leftSpacer2} variant="h5" color="textSecondary" mb={0.5}>
        Machine {selectedMachineId}
      </Typography>
      <Typography css={styles.leftSpacer2} variant="h3" mb={2}>
        Operating Condition
      </Typography>

      <Box component="div" height="415px">
        <ResponsiveBar
          data={operatingConditionChartData}
          keys={keys}
          indexBy="id"
          layout="horizontal"
          margin={{ top: 0, right: 80, bottom: 50, left: 60 }}
          padding={0.15}
          theme={{
            text: {
              fill: theme.palette.common.black,
            },
          }}
          enableGridY={false}
          enableLabel={false}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id, indexValue }) => {
            let messageType;
            if (id === "duration") {
              messageType = String(indexValue);
            } else {
              messageType = String(id).split(".").pop() ?? "AUTO";
            }
            return getMessageColor(messageType);
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 25,
            format: (value) => parseSecondsToHMS(value),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          tooltip={CustomTooltip}
          layers={["grid", "axes", "bars", CustomTotalLayer, "markers", "legends"]}
        />
      </Box>
    </ContentBox>
  );
};

export default MachineStatusOperatingConditionChart;

const CustomTotalLayer = ({ bars }: BarCustomLayerProps<OperatingConditionChartData>) => {
  const theme = useTheme();
  const durationBars = bars.filter((bar) => bar.data.id === "duration");

  const totalDuration = durationBars.reduce((sum, bar) => {
    return sum + (bar.data.value ?? 0);
  }, 0);
  const totalSections = bars.filter((bar) => bar.data.indexValue === "Total");
  const totalBarX = totalSections.reduce(
    (maxX, section) => Math.max(maxX, section.x + section.width),
    0
  );

  return (
    <>
      {durationBars.map((bar) => {
        if (bar.key === "duration.Total") {
          return (
            <text
              key={bar.key}
              x={totalBarX + 10}
              y={bar.y + bar.height / 2}
              textAnchor="start"
              dominantBaseline="central"
              style={{
                fill: theme.palette.text.primary,
                fontSize: 12,
              }}
            >
              {parseSecondsToHMS(totalDuration)}
            </text>
          );
        }

        const individualTotal = bar.data.value ?? 0;
        return (
          <text
            key={bar.key}
            x={bar.x + bar.width + 10}
            y={bar.y + bar.height / 2}
            textAnchor="start"
            dominantBaseline="central"
            style={{
              fill: theme.palette.text.primary,
              fontSize: 12,
            }}
          >
            {parseSecondsToHMS(individualTotal)}
          </text>
        );
      })}
    </>
  );
};

const CustomTooltip: React.FC<BarTooltipProps<OperatingConditionChartData>> = ({
  id,
  data,
  label,
  value,
  color,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  let fullName;
  let message;
  const durationHMS = parseSecondsToHMS(value as number);

  if (label.includes("Total")) {
    fullName = getMachineMessageFullName(String(id));
    message = id;
  } else {
    fullName = getMachineMessageFullName(String(data.id));
    message = data.id;
  }

  return (
    <Stack css={[styles.card, styles.leftRightPadding2, styles.tooltipStyle]} gap={0.5}>
      <Typography variant="body2" color={theme.palette.text.primary} pl={2.5}>
        {fullName}
      </Typography>
      <Divider />
      <Stack direction="row" css={[styles.flexCenter]} gap={1}>
        <Box
          sx={{
            width: 12,
            height: 12,
            backgroundColor: color,
            borderRadius: "50%",
          }}
          component="div"
        />
        <Typography variant="body2" color={theme.palette.customColors.greyText}>
          {message}:
        </Typography>
        <Typography variant="body2">{durationHMS}</Typography>
      </Stack>
    </Stack>
  );
};
