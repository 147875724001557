import { isLocalHostEnv } from "../Global/Utils/commonFunctions";

class WebSocketService {
  private static instance: WebSocketService;
  private ws: WebSocket | null = null;
  private messageHandlers: Map<string, (data: any) => void> = new Map();
  private heartbeatInterval: NodeJS.Timeout | null = null;
  private readonly HEARTBEAT_INTERVAL = 30000; 

  private constructor() {}

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  private startHeartbeat() {
    this.stopHeartbeat();
    this.heartbeatInterval = setInterval(() => {
      if (this.ws?.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify({ type: 'ping' }));
      }
    }, this.HEARTBEAT_INTERVAL);
  }

  private stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  public connect(userId: string): Promise<void> {
    const IS_LOCAL_HOST = isLocalHostEnv();
    const domain = IS_LOCAL_HOST ? 'ws://localhost:3000' : `wss://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}`;
    const url = `${domain}/api/v1/websocket/ws/user/${userId}`;

    this.ws = new WebSocket(url);

    return new Promise((resolve, reject) => {
      if (!this.ws) {
        reject(new Error('WebSocket not initialized'));
        return;
      }

      this.ws.onopen = () => {
        console.log('WebSocket connected successfully');
        this.startHeartbeat();
        resolve();
      };

      this.ws.onmessage = (event) => {
        try {
          let type = event.data.type;
          const data = JSON.parse(event.data);

          if (data.type === 'pong') return;

          // This would be unnecessary if the server would send the type of the message
          if(data.user_id && data.message) {
            type = "alert" 
          }
          
          if (type && this.messageHandlers.has(type)) {
            const handler = this.messageHandlers.get(type);
            if (handler) {
              handler(data);
            }
          }
        } catch (error) {
          console.error('Error handling WebSocket message:', error);
        }
      };

      this.ws.onclose = () => {
        console.log('WebSocket connection closed');
        this.stopHeartbeat();

        setTimeout(() => {
          if (!this.isConnected() && this.ws?.readyState !== WebSocket.CONNECTING) {
            console.log('Attempting to reconnect...');
            this.connect(userId);
          }
        }, 5000);
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
      };
    });
  }

  public disconnect(): void {
    this.stopHeartbeat();
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.messageHandlers.clear();
  }

  public addMessageHandler(type: string, handler: (data: any) => void): void {
    this.messageHandlers.set(type, handler);
  }

  public removeMessageHandler(type: string): void {
    this.messageHandlers.delete(type);
  }

  public isConnected(): boolean {
    return this.ws?.readyState === WebSocket.OPEN;
  }
} 

export default WebSocketService;
