import { Box, IconButton, Stack, useTheme, Typography } from "@mui/material";
import React from "react";
import { Handle, Position, Node } from "reactflow";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CustomIconsTooltip from "../../../../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { DATA_MANAGER_FLOW_NODE_TYPE as DATA_MANAGER_FLOW_NODE_TYPE } from "../../DataFlow/workflowUtils";

interface FlowNodeProps {
  id: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  nodeType: DATA_MANAGER_FLOW_NODE_TYPE;
  handleOpenEditModal: () => void;
  inputNodes?: Node[];
}

const FlowNode: React.FC<FlowNodeProps> = ({
  id,
  children,
  icon,
  nodeType,
  handleOpenEditModal,
  inputNodes = [],
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };

  const isInitialNode = nodeType === DATA_MANAGER_FLOW_NODE_TYPE.Initial;

  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1.5,
        p: 2,
        minWidth: 200,
        maxWidth: "100%",
      }}
      id={id}
    >
      {!isInitialNode && <Handle
        type="target"
        position={Position.Top}
        style={{
          width: 10,
          height: 10,
          background: "#1976d2",
        }}
      />}
      
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          width: 10,
          height: 10,
          background: "#1976d2",
        }}
      />

      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Box component="div">{icon}</Box>
        <Box component="div">
          <CustomIconsTooltip tooltipText={t("Edit")}>
            <IconButton onClick={handleOpenEditModal} size="small">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          </CustomIconsTooltip>
        </Box>
      </Stack>

      <Box component="div" css={styles.contentBreak}>
        {children}
      </Box>

      {!isInitialNode && inputNodes.length > 0 && (
        <Box component="div" sx={{ mt: 2 }} css={styles.textBreak}>
          <Typography variant="caption" color="text.secondary">
            {t("Input Nodes")}:
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {inputNodes.map((node) => (
              <Typography key={node.id} variant="caption">
                {node.data.name}
              </Typography>
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default FlowNode;
