import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import { convertObjectKeysToCamelCase } from "../../Global/Utils/commonFunctions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLanguageContext } from "../../context/LanguageContext";
import { BillingDocument } from "../../Api/EnergyManagement/apiEnergyManagementSnippets";
import { getQueryBillingDocument, getQueryBilling } from "../../Api/EnergyManagement/apiEnergyManagementGetQueries";

const BillingPage: React.FC = () => {
  const { setAuthedUser } = useAuthedContext();
  const [tableData, setTableData] = useState<any>();
  const { t } = useLanguageContext();

  const handleViewDocument = async (billingId: string) => {
    try {
      const document = await callApi<BillingDocument>({
        query: getQueryBillingDocument(billingId),
        auth: { setAuthedUser },
      });

      if (document?.url) {
        const response = await fetch(`https://${document.url}`);
        const blob = await response.blob();
        
        const blobUrl = window.URL.createObjectURL(blob);

        window.open(blobUrl, '_blank');

        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
        }, 1000);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await callApi<{columns: any[], rows: any[]}>({
        query: getQueryBilling(),
        auth: { setAuthedUser },
      });

      // Add actions column to the columns
      if (data?.columns) {
        data.columns.push({
          id: 'actions',
          label: t('Actions'),
          type: 'button',
          disableEditing: true,
        });
      }

      // Add actions to each row
      if (data?.rows) {
        data.rows = data.rows.map(row => ({
          ...row,
          actions: (
            <Tooltip title={t('View Details')}>
              <IconButton 
                size="small" 
                onClick={() => handleViewDocument(row.id)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          ),
        }));
      }

      setTableData(data);
    };

    fetchData();
  }, [setAuthedUser, t]);

  return (
    <Box component="div">
      {tableData && (
        <ResponsiveTableGrid
          rows={tableData?.rows || []}
          colSchema={tableData?.columns || []}
          responsive="responsive"
          configuration={convertObjectKeysToCamelCase(tableData?.configuration || {})}
          tableID="BillingPage_table"
        />
      )}
    </Box>
  );
};

export default BillingPage;
