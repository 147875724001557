import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DATA_MANAGER_FLOW_NODE_TYPE,
  WorkflowsColumnAggregationNodeData,
} from "../../workflowUtils";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses, SelectOption } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import Select from "../../../../../../Components/MaterialUI/FormFields/Select";

interface NodeColumnAggregationFormProps {
  handleCreateSubmit?: (data: WorkflowsColumnAggregationNodeData) => void;
  handleEditSubmit?: (data: WorkflowsColumnAggregationNodeData) => void;
  data?: WorkflowsColumnAggregationNodeData;
  nodes: Node<WorkflowsColumnAggregationNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
  columnOptions: SelectOption[];
}

const aggregationOptions: SelectOption[] = [
  { value: "sum", description: "Sum" },
  { value: "avg", description: "Average" },
  { value: "min", description: "Minimum" },
  { value: "max", description: "Maximum" },
  { value: "prod", description: "Product" },
  { value: "count", description: "Count" },
];

const timeGroupOptions: SelectOption[] = [
  { value: "minute", description: "Minute" },
  { value: "hour", description: "Hour" },
  { value: "day", description: "Day" },
  { value: "month", description: "Month" },
];

type GroupByType = "time" | "parameter";

const NodeColumnAggregationForm: React.FC<NodeColumnAggregationFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  columnOptions,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Column Aggregation");
  const [targetColumn, setTargetColumn] = useState<string>(data?.target_column || "");
  const [aggregation, setAggregation] = useState<string>(data?.aggregation || "sum");
  const [resultColumn, setResultColumn] = useState<string>(data?.result_column || "");
  const [groupByType, setGroupByType] = useState<GroupByType>(
    data?.group_by_time ? "time" : "parameter"
  );
  const [groupByTime, setGroupByTime] = useState<string>(data?.group_by_time || "hour");
  const [datetimeColumn, setDatetimeColumn] = useState<string>(data?.datetime_column || "");
  const [groupByColumn, setGroupByColumn] = useState<string>(data?.group_by_column || "");
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation;

  const initialFormState = useRef({
    name,
    targetColumn,
    aggregation,
    resultColumn,
    groupByType,
    groupByTime,
    datetimeColumn,
    groupByColumn,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    {
      name,
      targetColumn,
      aggregation,
      resultColumn,
      groupByType,
      groupByTime,
      datetimeColumn,
      groupByColumn,
    },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    if (!targetColumn) {
      setAlertMessage(t("Target column is required"));
      setFormStatus("warning");
      return;
    }

    if (!aggregation) {
      setAlertMessage(t("Aggregation type is required"));
      setFormStatus("warning");
      return;
    }

    if (groupByType === "time" && !datetimeColumn) {
      setAlertMessage(t("Datetime column is required for time-based grouping"));
      setFormStatus("warning");
      return;
    }

    if (groupByType === "parameter" && !groupByColumn) {
      setAlertMessage(t("Group by column is required for parameter-based grouping"));
      setFormStatus("warning");
      return;
    }

    const current = new Date().toISOString();

    const result: WorkflowsColumnAggregationNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      target_column: targetColumn,
      aggregation: aggregation as 'sum' | 'avg' | 'min' | 'max' | 'prod' | 'count',
      result_column: resultColumn || undefined,
      group_by_time: groupByType === "time" ? groupByTime as "minute" | "hour" | "day" | "month" : undefined,
      datetime_column: groupByType === "time" ? datetimeColumn : undefined,
      group_by_column: groupByType === "parameter" ? groupByColumn : undefined,
      createdOn: data?.createdOn || current,
      updatedOn: current,
      container_id: data?.container_id || "",
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Column Aggregation Configuration")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                selectOptions={columnOptions}
                fullWidth
                label={t("Target Column")}
                value={targetColumn}
                onChange={(e) => setTargetColumn(e.target.value)}
                required
                error={formStatus === "warning" && !targetColumn}
                helperText={formStatus === "warning" && !targetColumn ? t("Target column is required") : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                selectOptions={aggregationOptions}
                fullWidth
                label={t("Aggregation Type")}
                value={aggregation}
                onChange={(e) => setAggregation(e.target.value)}
                required
                error={formStatus === "warning" && !aggregation}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="resultColumn"
                label={t("Result Column (optional)")}
                onChange={(e) => setResultColumn(e.target.value)}
                value={resultColumn}
                placeholder="aggregation_result"
              />
            </Grid>
          </Grid>
        </Box>

        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Grouping Configuration")}
          </Typography>
          <FormControl>
            <RadioGroup
              value={groupByType}
              onChange={(e) => setGroupByType(e.target.value as GroupByType)}
            >
              <FormControlLabel
                value="time"
                control={<Radio />}
                label={t("Group by Time")}
              />
              <FormControlLabel
                value="parameter"
                control={<Radio />}
                label={t("Group by Parameter")}
              />
            </RadioGroup>
          </FormControl>

          {groupByType === "time" ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  selectOptions={timeGroupOptions}
                  fullWidth
                  label={t("Time Period")}
                  value={groupByTime}
                  onChange={(e) => setGroupByTime(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  selectOptions={columnOptions}
                  fullWidth
                  label={t("Datetime Column")}
                  value={datetimeColumn}
                  onChange={(e) => setDatetimeColumn(e.target.value)}
                  required
                  error={formStatus === "warning" && !datetimeColumn}
                  helperText={
                    formStatus === "warning" && !datetimeColumn
                      ? t("Datetime column is required for time-based grouping")
                      : ""
                  }
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  selectOptions={columnOptions}
                  fullWidth
                  label={t("Group by Column")}
                  value={groupByColumn}
                  onChange={(e) => setGroupByColumn(e.target.value)}
                  required
                  error={formStatus === "warning" && !groupByColumn}
                  helperText={
                    formStatus === "warning" && !groupByColumn
                      ? t("Group by column is required for parameter-based grouping")
                      : ""
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button 
          css={[styles.width100, styles.widthLimit20]} 
          onClick={handleFormSubmit}
        >
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeColumnAggregationForm;