import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@mui/material";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses, SelectOption } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { DATA_MANAGER_FLOW_NODE_TYPE } from "../../workflowUtils";
import { WorkflowsColumnFilterNodeData } from "../../workflowUtils";
import Select from "../../../../../../Components/MaterialUI/FormFields/Select";

interface NodeColumnFilterFormProps {
  handleCreateSubmit?: (data: WorkflowsColumnFilterNodeData) => void;
  handleEditSubmit?: (data: WorkflowsColumnFilterNodeData) => void;
  data?: WorkflowsColumnFilterNodeData;
  nodes: Node<WorkflowsColumnFilterNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
  columnOptions: SelectOption[];
}

type FilterType = "include" | "exclude";

const NodeColumnFilterForm: React.FC<NodeColumnFilterFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  columnOptions,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Column Filter");
  const [filterType, setFilterType] = useState<FilterType>(
    data?.include_columns?.length ? "include" : "exclude"
  );
  const [includeColumns, setIncludeColumns] = useState<string[]>(
    data?.include_columns || []
  );
  const [excludeColumns, setExcludeColumns] = useState<string[]>(
    data?.exclude_columns || []
  );
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter;

  const initialFormState = useRef({
    name,
    filterType,
    includeColumns,
    excludeColumns,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, filterType, includeColumns, excludeColumns },
    handleSetUnsavedChanges
  );

  const handleFilterTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(event.target.value as FilterType);
  };

  const handleFormSubmit = () => {
    if (filterType === "include" && includeColumns.length === 0) {
      setAlertMessage(t("Include columns is required when using include filter"));
      setFormStatus("warning");
      return;
    }

    if (filterType === "exclude" && excludeColumns.length === 0) {
      setAlertMessage(t("Exclude columns is required when using exclude filter"));
      setFormStatus("warning");
      return;
    }

    const current = new Date().toISOString();
    const result: WorkflowsColumnFilterNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      include_columns: filterType === "include" ? includeColumns : undefined,
      exclude_columns: filterType === "exclude" ? excludeColumns : undefined,
      createdOn: data?.createdOn || current,
      updatedOn: current,
      container_id: data?.container_id || "",
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Column Filter Configuration")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel>{t("Filter Type")}</FormLabel>
                <RadioGroup
                  value={filterType}
                  onChange={handleFilterTypeChange}
                >
                  <FormControlLabel
                    value="include"
                    control={<Radio />}
                    label={t("Include Columns")}
                  />
                  <FormControlLabel
                    value="exclude"
                    control={<Radio />}
                    label={t("Exclude Columns")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {filterType === "include" ? (
                <Select
                  selectOptions={columnOptions}
                  fullWidth
                  label={t("Include Columns")}
                  value={includeColumns}
                  onChange={(e) => setIncludeColumns(Array.isArray(e.target.value) ? e.target.value : [])}
                  required
                  error={formStatus === "warning" && includeColumns.length === 0}
                  helperText={formStatus === "warning" && includeColumns.length === 0 ? t("Include columns are required") : ""}
                  multiple
                />
              ) : (
                <Select
                  selectOptions={columnOptions}
                  fullWidth
                  label={t("Exclude Columns")}
                  value={excludeColumns}
                  onChange={(e) => setExcludeColumns(Array.isArray(e.target.value) ? e.target.value : [])}
                  required
                  error={formStatus === "warning" && excludeColumns.length === 0}
                  helperText={formStatus === "warning" && excludeColumns.length === 0 ? t("Exclude columns are required") : ""}
                  multiple
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button 
          css={[styles.width100, styles.widthLimit20]} 
          onClick={handleFormSubmit}
        >
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeColumnFilterForm;