import { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  CircularProgress, 
  MenuItem,
  Paper, 
  Select, 
  Stack,
  useTheme,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { 
  getQueryProcessWizzardTemplates,
} from "../../../Api/ProcessWizzard/apiProcessWizzardGetQueries";
import { postQueryProcessWizzardSaveStatement } from "../../../Api/ProcessWizzard/apiProcessWizzardPostQueries";
import { useLanguageContext } from "../../../context/LanguageContext";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import SaveIcon from '@mui/icons-material/Save';
import Alert from "../../../Components/MaterialUI/Alert";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import QuillEditor from "../../../Components/SmallComponents/QuillEditor/QuillEditor";
import { css } from "@emotion/react";

interface StatementGeneratorProps {
  orderId: string;
  statement?: string;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Template {
  id: string;
  name: string;
  text: string;
}

const StatementGenerator: React.FC<StatementGeneratorProps> = ({ orderId, statement, setRefetch }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { 
    ...cssSpacingStyles(theme), 
    ...cssLayoutStyles,
    height250: css({
      height: '250px'
    })
  };
  const { setAuthedUser } = useAuthedContext();
  
  const [editorContent, setEditorContent] = useState<string>("");
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [editorKey, setEditorKey] = useState<number>(0);
  
  useEffect(() => {
    fetchTemplates();
    setEditorContent(statement || "");
  }, []);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const data = await callApi<Array<Template>>({
        query: getQueryProcessWizzardTemplates(),
        auth: { setAuthedUser },
      });
      
      setTemplates(data || []);
    } catch (error) {
      console.error("Error fetching templates:", error);
      setAlertMessage(t("Failed to load templates"));
      setFormStatus("error");
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    const templateId = event.target.value;
    setSelectedTemplate(templateId);
    
    if (templateId) {
      const template = templates.find(t => t.id === templateId);
      if (template && template.text) {
        const newContent = editorContent 
          ? editorContent + "<br>" + template.text 
          : template.text;
        
        setEditorContent(newContent);
        setEditorKey(prevKey => prevKey + 1);
      }
    }
  };

  const handleSave = async () => {
    if (!orderId) return;
    
    setFormStatus("loading");
    setAlertMessage(t("Saving..."));
    
    try {
      await callApi({
        query: postQueryProcessWizzardSaveStatement(orderId, editorContent),
        auth: { setAuthedUser },
      });
      
      setFormStatus("success");
      setAlertMessage(t("Statement saved successfully"));
      setRefetch(true);
    } catch (error) {
      console.error("Error saving statement:", error);
      const errorDetail = error.response?.data?.detail || '';
      setFormStatus("error");
      setAlertMessage(errorDetail ? 
        `${t("Failed to save statement")}: ${errorDetail}` : 
        t("Failed to save statement")
      );
    }
  };

  return (
    <Box component="div" sx={{ width: '100%' }}>
      {alertMessage && (
        <Alert 
          severity={formStatus === "error" ? "error" : formStatus === "success" ? "success" : "info"} 
          showAlert={!!alertMessage}
          message={alertMessage} 
          css={[styles.contentBreak]}
        />
      )}
      
      <Stack spacing={2} mb={2}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Box component="div" sx={{ minWidth: 200 }}>
            <Select
              value={selectedTemplate}
              onChange={handleTemplateChange}
              displayEmpty
              variant="outlined"
              sx={{ height: 40, width: '100%' }}
            >
              <MenuItem value=""><em>{t("Select Template")}</em></MenuItem>
              {templates.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          
          <Button
            variant="contained"
            startIcon={formStatus === "loading" ? <CircularProgress size={24} /> : <SaveIcon />}
            onClick={handleSave}
            disabled={formStatus === "loading"}
          >
            {t("Save")}
          </Button>
        </Stack>
        
        <Paper elevation={2} sx={{ p: 2, minHeight: 400 }}>
          {loading ? (
            <Box component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: 300 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography
                sx={{ 
                  mb: 1,
                  fontWeight: 500,
                  color: theme.palette.text.primary
                }}
                variant="body2"
              >
                {t("Statement")}
              </Typography>
            
              <QuillEditor
                key={editorKey}
                label=""
                css={[styles.width100, styles.labelBreak, styles.overflowYAuto, styles.height250]}
                editorState={editorContent}
                onEditorStateChange={(content: string) => setEditorContent(content)}
              />
            </>
          )}
        </Paper>
      </Stack>
    </Box>
  );
};

export default StatementGenerator; 