import React, { useEffect, useRef, useState } from "react";
import { BarTooltipProps, ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import {
  MachineStatusChartData,
  getMachineMessageFullName,
  getMessageColor,
  parseSecondsToHMS,
} from "./machineStatusUtils";
import { css } from "@emotion/react";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import ContentBox from "../../MaterialUI/ContentBox";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssComponentsStyles from "../../../Global/Styles/components";

const cssStyles = () => ({
  boxChartParentStyle: css({
    height: "220px",
  }),
});

interface MachineStatusHistoryChartProps {
  machineStatusHistoryChartData: MachineStatusChartData[];
  selectedMachineMessage: string;
  selectedMachineId: string;
}

const MachineStatusHistoryChart: React.FC<MachineStatusHistoryChartProps> = ({
  machineStatusHistoryChartData,
  selectedMachineMessage,
  selectedMachineId,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(),
  };
  const [customAxisBottomValues, setCustomAxisBottomValues] = useState<string[]>([]);
  const timeChartContainerRef = useRef<HTMLDivElement>(null);
  const { width: timeChartWidth } = useContainerDimensions(timeChartContainerRef);

  useEffect(() => {
    const uniqueKeys = new Set<string>();
    machineStatusHistoryChartData.forEach((data) => {
      Object.keys(data).forEach((key) => {
        if (key !== "id") uniqueKeys.add(key);
      });
    });
    const uniqueKeysArray = Array.from(uniqueKeys).sort();

    setCustomAxisBottomValues(uniqueKeysArray);
  }, [machineStatusHistoryChartData]);

  const getSelectedMessageColor = (datum: ComputedDatum<MachineStatusChartData>) => {
    const messageType = String(datum.id).split("-").pop();
    return messageType === selectedMachineMessage && selectedMachineMessage !== "All"
      ? getMessageColor(messageType)
      : theme.palette.customColors?.themeBackground || "rgba(0, 0, 0, 0)";
  };

  const getChartColor = (datum: ComputedDatum<MachineStatusChartData>) => {
    const messageType = String(datum.id).split("-").pop() ?? "AUTO";
    return selectedMachineMessage === "All"
      ? getMessageColor(messageType)
      : getSelectedMessageColor(datum);
  };

  return (
    <ContentBox>
      <Box css={styles.boxChartParentStyle} ref={timeChartContainerRef} component="div">
        <Typography css={styles.leftSpacer2} variant="h5" color="textSecondary" mb={0.5}>
          Machine {selectedMachineId}
        </Typography>
        <Typography css={styles.leftSpacer2} variant="h3">
          Operating Status History
        </Typography>

        {machineStatusHistoryChartData.length > 0 && (
          <ResponsiveBar
            data={machineStatusHistoryChartData}
            keys={customAxisBottomValues}
            indexBy="id"
            margin={{ top: 0, right: 20, bottom: 90, left: 20 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={(datum) =>
              getChartColor(datum as ComputedDatum<MachineStatusChartData>)
            }
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickValues: customAxisBottomValues,
              renderTick: (tickProps) =>
                CustomAxisBottom(
                  tickProps.y,
                  tickProps.value,
                  customAxisBottomValues,
                  timeChartWidth
                ),
            }}
            tooltip={CustomTooltip}
            axisLeft={null}
            enableLabel={false}
          />
        )}
      </Box>
    </ContentBox>
  );
};

export default MachineStatusHistoryChart;

const CustomTooltip: React.FC<BarTooltipProps<MachineStatusChartData>> = ({
  id,
  value,
  color,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const idString = id as string;
  const parts = idString.split("-");

  if (parts.length < 2) {
    return null;
  }
  const messageType = parts[parts.length - 1];
  const fullName = getMachineMessageFullName(messageType);
  const durationHMS = parseSecondsToHMS(value as number);

  return (
    <Stack css={[styles.card, styles.leftRightPadding2, styles.tooltipStyle]} gap={0.5}>
      <Typography variant="body2" color={theme.palette.text.primary} pl={2.5}>
        {fullName}
      </Typography>
      <Divider />
      <Stack direction="row" css={[styles.flexCenter]} gap={1}>
        <Box
          sx={{
            width: 12,
            height: 12,
            backgroundColor: color,
            borderRadius: "50%",
          }}
          component="div"
        />
        <Typography variant="body2" color={theme.palette.customColors.greyText}>
          {messageType}:
        </Typography>
        <Typography variant="body2">{durationHMS}</Typography>
      </Stack>
    </Stack>
  );
};

const CustomAxisBottom = (
  y: number,
  value: string,
  customAxisBottomValues: string[],
  timeChartWidth: number
) => {
  const theme = useTheme();
  const index = customAxisBottomValues.indexOf(value);
  const tickValue = customAxisBottomValues[index] || value;
  const formattedTickValue = tickValue.slice(0, tickValue.lastIndexOf("-"));

  const chartWidth = timeChartWidth - 130;
  const spacing = chartWidth / (customAxisBottomValues.length - 1);
  const adjustedX = spacing * index + 90;

  return (
    <g transform={`translate(${adjustedX},${y + 30}) rotate(25)`}>
      <text textAnchor="end" style={{ fontSize: 10, fill: theme.palette.common.black }}>
        {formattedTickValue}
      </text>
    </g>
  );
};
