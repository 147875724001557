import { Query } from "../callApi";

export const getQueryBilling = (): Query => ({
    endpoint: `data_manager/energy-billing`,
    method: "GET",
  });
  
export const getQueryBillingDocument = (billingId: string): Query => ({
  endpoint: `data_manager/energy-billing/${billingId}/documents`,
  method: "GET",
});