import { Box, Stack, Typography } from "@mui/material";
import { WorkflowsColumnAggregationNodeData } from "../../workflowUtils";
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";

interface NodeColumnAggregationDataModalProps {
  data: WorkflowsColumnAggregationNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeColumnAggregationDataModal: React.FC<NodeColumnAggregationDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();

  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      <LabelWithBoldedPart text={t("Target Column")} bolded={data.target_column || ""} />
      <LabelWithBoldedPart text={t("Aggregation")} bolded={data.aggregation || ""} />
      <LabelWithBoldedPart text={t("Result Column")} bolded={data.result_column || ""} />
      {data.group_by_time && (
        <>
          <LabelWithBoldedPart text={t("Group By Time")} bolded={data.group_by_time} />
          <LabelWithBoldedPart text={t("Datetime Column")} bolded={data.datetime_column || ""} />
        </>
      )}
      {data.group_by_column && (
        <LabelWithBoldedPart text={t("Group By Column")} bolded={data.group_by_column} />
      )}
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_ColumnAggregation_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeColumnAggregationDataModal; 