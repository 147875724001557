import { useState, useEffect } from "react";
import { 
  Box, 
  Card,
  CircularProgress, 
  IconButton,
  Stack, 
  Typography, 
  useTheme 
} from "@mui/material";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { getQueryProcessWizzardOrderDocuments } from "../../../Api/ProcessWizzard/apiProcessWizzardGetQueries";
import { postQueryProcessWizzardUploadDocument } from "../../../Api/ProcessWizzard/apiProcessWizzardPostQueries";
import { useLanguageContext } from "../../../context/LanguageContext";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import DownloadIcon from "@mui/icons-material/Download";
import Alert from "../../../Components/MaterialUI/Alert";
import Button from "../../../Components/MaterialUI/Button";
import FileDropzone from "../../../Components/SmallComponents/Dropzone/FileDropzone";
import { FileWithPath } from "react-dropzone";

interface OrderDocumentsProps {
  orderData: any;
}

interface Document {
  id: string;
  name: string;
  url: string;
  createDate: string;
  size: number;
}

const OrderDocuments: React.FC<OrderDocumentsProps> = ({ orderData }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [files, setFiles] = useState<FileWithPath[] | null>(null);
  
  const orderId = orderData?.id || orderData?._id;

  useEffect(() => {
    fetchDocuments();
  }, []);

  // Process files when they're selected
  useEffect(() => {
    if (files && files.length > 0) {
      handleFileUpload(files[0]);
    }
  }, [files]);

  const fetchDocuments = async () => {
    if (!orderId) return;
    
    setLoading(true);
    try {
      const data = await callApi<Array<any>>({
        query: getQueryProcessWizzardOrderDocuments(orderId),
        auth: { setAuthedUser },
      });
      
      setDocuments(data || []);
      setErrorMessage(null);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setErrorMessage(t("Failed to load documents"));
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (file: FileWithPath) => {
    setUploading(true);
    setErrorMessage(null);
    setSuccessMessage(null);
    
    try {
      await callApi({
        query: postQueryProcessWizzardUploadDocument(orderId, file),
        auth: { setAuthedUser },
      });
      
      setSuccessMessage(t("Document uploaded successfully"));
      fetchDocuments();
    } catch (error) {
      console.error("Error uploading document:", error);
      setErrorMessage(t("Failed to upload document"));
    } finally {
      setUploading(false);
      setFiles(null); // Reset files after upload
    }
  };

  const handleDownloadFile = async (url: string) => {
    try {
      if (!url) return;
      
      const downloadLink = document.createElement("a");
      downloadLink.href = "https://" + url;
      downloadLink.setAttribute('download', '');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
      setErrorMessage(t("Failed to download document"));
    }
  };

  return (
    <Box component="div" sx={{ width: '100%' }}>
      <Typography variant="body1" css={styles.contentBreak}>
        {t("Upload and manage documents related to this order.")}
      </Typography>
      
      {errorMessage && (
        <Alert 
          severity="error" 
          showAlert={!!errorMessage}
          message={errorMessage} 
          css={[styles.contentBreak]}
        />
      )}
      
      {successMessage && (
        <Alert 
          severity="success" 
          showAlert={!!successMessage}
          message={successMessage} 
          css={[styles.contentBreak]}
        />
      )}
      
      {loading ? (
        <Box component="div" sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {documents.length > 0 && (
            <Stack css={[styles.sectionBreak]} spacing={2}>
              {documents.map((doc) => (
                <Card key={doc.id} elevation={1}>
                  <Box component="div" sx={{ p: 2 }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography style={{ flex: 1 }} variant="body1">
                        {doc.name}
                      </Typography>
                      <IconButton
                        aria-label="download file"
                        onClick={() => handleDownloadFile(doc.url)}
                      >
                        <DownloadIcon color="primary" />
                      </IconButton>
                    </Stack>
                  </Box>
                </Card>
              ))}
            </Stack>
          )}
          
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            css={styles.sectionBreak}
          >
            <FileDropzone
              accept={{}}
              setFiles={setFiles}
              setErrorMessage={setErrorMessage}
              multiFileUpload={false}
            >
              <Button
                color="secondary"
                loading={uploading}
                disabled={uploading}
              >
                {t("Upload Document")}
              </Button>
            </FileDropzone>
          </Stack>
          
          {documents.length === 0 && !loading && (
            <Box component="div" sx={{ p: 3, textAlign: 'center' }}>
              <Typography color="text.secondary">
                {t("No documents found. Upload a document to get started.")}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default OrderDocuments; 