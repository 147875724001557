import { Box, Grid, Stack, useTheme } from "@mui/material";
import { useState, useRef } from "react";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { useLanguageContext } from "../../../../context/LanguageContext";
import TextField from "../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../Components/MaterialUI/Button";
import Alert from "../../../../Components/MaterialUI/Alert";
import { useDetectFormsUnsavedChanges } from "../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { postQueryDataManagerCreateWorkflow } from "../../../../Api/DataManager/apiDataManagerPostQueries";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import ROUTES_MAPPING from "../../../../Layout/Router/routesMapping";
import { useNavigate } from "react-router-dom";
import { DataManagerWorkflowSnippet } from "../../../../Api/DataManager/apiDataManagerSnippets";
import { convertToCompactFormat, DATA_MANAGER_FLOW_NODE_TYPE } from "./workflowUtils";
import { v4 as uuidv4 } from "uuid";

interface CreateDataWorkflowFormProps {
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateDataWorkflowForm: React.FC<CreateDataWorkflowFormProps> = ({
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const navigate = useNavigate();
  const { setAuthedUser } = useAuthedContext();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const initialFormState = useRef({
    name,
    description,
  }).current;

  useDetectFormsUnsavedChanges(initialFormState, { name, description }, handleSetUnsavedChanges);

  const handleSubmit = async () => {
    try {
      if (!name) {
        setFormStatus("warning");
        setAlertMessage(t("Workflow name is required"));
        return;
      }

      setFormStatus("loading");
      setAlertMessage(t("Creating workflow..."));

      const initialNodeId = uuidv4().split("-")[0];

      // Create an initial node for the workflow
      const initialNode = {
        id: initialNodeId,
        type: DATA_MANAGER_FLOW_NODE_TYPE.Initial,
        position: { x: 250, y: 100 },
        data: { 
          id: initialNodeId,
          name: name,
          createdOn: new Date().toISOString(),
          updatedOn: new Date().toISOString()
        }
      };
      
      // Initialize workflow data with the initial node
      const workflowData = {
        nodes: [initialNode],
        edges: []
      };

      const workFlowDataFormatted = convertToCompactFormat(workflowData);

      const res = await callApi<DataManagerWorkflowSnippet>({
        query: postQueryDataManagerCreateWorkflow(name, description, workFlowDataFormatted),
        auth: { setAuthedUser },
      })

      if (res) {
        setFormStatus("success");
        setAlertMessage(t("Workflow created successfully"));
        setUnsavedChanges(false);
        console.log(ROUTES_MAPPING["Data Manager-Workflows"])
        navigate(`${ROUTES_MAPPING["Data Manager-Workflows"]}/${res.id}`);
      }
    } catch (error) {
      console.error("Error creating workflow:", error);
      setFormStatus("error");
      setAlertMessage(t("Error creating workflow"));
    }
  };

  return (
    <Stack css={styles.width100} spacing={4}>
      <Box component="div">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label={t("Workflow Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              error={formStatus === "warning" && !name}
              helperText={
                formStatus === "warning" && !name
                  ? t("Workflow name is required")
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("Description")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
            />
          </Grid>
        </Grid>
      </Box>

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "info"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button onClick={handleSubmit} css={[styles.width100, styles.widthLimit25]}>
          {t("Create Workflow")}
        </Button>
      </Box>
    </Stack>
  );
};

export default CreateDataWorkflowForm; 