import { SerializedStyles } from "@emotion/react";
import TimeChart from "../../../../ExcellenceWidgets/TimeChart/TimeChart";
import {
  ChartXScaleMinMax,
  TimeChartAlertPoint,
  TimeChartCircle,
  TimeChartConfigAndData,
} from "../../../../ExcellenceWidgets/TimeChart/timeChartTypes";
import { useEffect, useState } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { graphQlQueryExcellenceTimeSeriesData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { ExcellenceWidgetItemMode } from "../../excellenceUtils";
import {
  ExcellenceDateTimeConfigurationData,
  timeChartConvertToSeconds,
} from "../../../../SmallComponents/DateTimeConfiguration/dateTimeConfigurationUtils";
import {
  ExcellenceChartDataSchema,
  ExcellenceChartDataSchemaParameter,
  ExcellenceParameter,
} from "../../../../../GraphQL/Excellence/graphQLTypesExcellence";
import {
  getTimeChartStartEndTimes,
  getTimeChartXScaleMinMax,
} from "../../../../ExcellenceWidgets/TimeChart/timeChartUtils";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import ChartScopeMode from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartScopeMode";
import ChartPeriodViewer from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import { PeriodModeDates } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { add, isBefore } from "date-fns";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import { TIME_CHART_LIVE_DATA_SUBSCRIPTION } from "../../../../../GraphQL/Excellence/graphQLSubscriptions";
import { TimePeriodType } from "../../../OEE/oEEUtils";
import { getQueryExcellenceAlertDetails } from "../../../../../Api/Excellence/apiExcellenceGetQueries";
import callApi from "../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../context/AuthContext";

const MODE_HEIGHT = "25px";
type LiveDataVars = {
  parameters: ExcellenceChartDataSchemaParameter[];
  timeWindowInS: number;
};

interface ExcellenceTimeChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  timeChart: TimeChartConfigAndData;
  isStatic?: boolean;
  loading: boolean;
  chartMode: ExcellenceWidgetItemMode | null;
  titleComponent: React.ReactNode;
  resetChartFetch: boolean;
  tempFilters: GraphqlFilter[];
  refetchOnFilters?: boolean;
  parameters?: ExcellenceParameter[];
}

const ExcellenceTimeChart: React.FC<ExcellenceTimeChartProps> = ({
  className,
  timeChart,
  isStatic,
  loading,
  chartMode,
  titleComponent,
  resetChartFetch,
  tempFilters,
  refetchOnFilters,
  parameters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };

  const { setAuthedUser } = useAuthedContext();
  const [stateChart, setStateChart] = useState<TimeChartConfigAndData>(timeChart);
  const [formStatus, setFormStatus] = useState<FormStatuses>(loading ? "loading" : null);
  const [chartAnimate, setChartAnimate] = useState<boolean>(false);

  const [xScaleMinMax, setXScaleMinMax] = useState<ChartXScaleMinMax | undefined>(
    undefined
  );
  const [period, setPeriod] = useState<TimePeriodType | null>("h");
  const [dates, setDates] = useState<PeriodModeDates | null>(null);
  const [circles, setCircles] = useState<TimeChartCircle[] | null>(null);
  const [startSubscription, setStartSubscription] = useState<boolean>(false);
  const [liveDataVars, setLiveDataVars] = useState<LiveDataVars | null>(null);
  const [shouldSubscribe, setShouldSubscribe] = useState<boolean>(true);
  const [alertPoints, setAlertPoints] = useState<TimeChartAlertPoint[] | null>(null);

  const [getTimeData, { loading: loadingLineData }] = useLazyQuery(
    graphQlQueryExcellenceTimeSeriesData
  );
  const { data: subData } = useSubscription(TIME_CHART_LIVE_DATA_SUBSCRIPTION, {
    variables: {
      input: {
        parameters: liveDataVars?.parameters,
        timeWindowInS: liveDataVars?.timeWindowInS,
      },
      skip: !shouldSubscribe || !startSubscription,
    },
  });

  const periodPreviewMode = chartMode?.timeChart?.periodPreviewMode;
  const scopeMode = chartMode?.timeChart?.scopeMode;

  const paramMapping: Record<string, string> | undefined = parameters?.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr.name,
      };
    },
    {}
  );

  useEffect(() => {
    // Toggle the subscription to trigger re-subscription on variable change
    setShouldSubscribe(false);
    if (liveDataVars?.timeWindowInS && liveDataVars?.parameters) {
      setTimeout(() => setShouldSubscribe(true), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (formStatus === "success") {
        const { dataSchema, dateTimeConfig } = timeChart;
        if (dateTimeConfig && dataSchema) {
          await handleFetchData(dateTimeConfig, dataSchema, true, tempFilters);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchOnFilters]);

  /** Initial data fetch for time chart */
  useEffect(() => {
    (async () => {
      if (formStatus === "loading" && !resetChartFetch) {
        return;
      }
      setFormStatus("loading");
      try {
        const { data, dataSchema, dateTimeConfig, ...rest } = timeChart;
        let updatedChart: any = null;

        if (dateTimeConfig && dataSchema) {
          const chartWithUpdatedData = await handleFetchData(
            dateTimeConfig,
            dataSchema,
            true
          );
          if (chartWithUpdatedData) {
            updatedChart = chartWithUpdatedData;
          }
        }

        setStateChart(() => {
          if (updatedChart) {
            return {
              ...timeChart,
              ...updatedChart,
              ...rest,
            };
          }
          return {
            ...timeChart,
            ...rest,
          };
        });
        setFormStatus("success");
      } catch (err) {
        console.log("ExcellenceTimeChart - useEffect ", err);
        setFormStatus("error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, resetChartFetch]);

  /** Chart mode changes */
  useEffect(() => {
    (async () => {
      const dateTimeConfig = timeChart?.dateTimeConfig;
      const schema = timeChart?.dataSchema;
      const periodMode = chartMode?.timeChart?.periodPreviewMode;
      const zoomMode = chartMode?.timeChart?.scopeMode;

      if (periodMode && schema?.startTime && schema.endTime) {
        const currentDate = new Date();
        setDates({
          startDate: add(currentDate, { hours: -1 }),
          endDate: currentDate,
        });
      }

      if (zoomMode && schema?.startTime && schema.endTime) {
        const startEndTimes = dateTimeConfig
          ? getTimeChartStartEndTimes(dateTimeConfig)
          : null;
        setDates({
          startDate: new Date(startEndTimes?.startTime || schema.startTime),
          endDate: new Date(startEndTimes?.endTime || schema.endTime),
        });
      }

      if (!periodMode && !zoomMode && schema) {
        setDates(null);
        setCircles(null);
        await handleFetchData(dateTimeConfig || null, schema);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartMode]);

  /** New data based on mode */
  useEffect(() => {
    (async () => {
      const dataSchema = timeChart?.dataSchema;

      if (dataSchema && dates) {
        // 1. get the schema start and end point
        const schemaToUse = {
          ...dataSchema,
          startTime: dates.startDate.toISOString(),
          endTime: dates.endDate.toISOString(),
        };

        // fetch data and update state
        await handleFetchData(null, schemaToUse);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  /** Circle placing logic */
  useEffect(() => {
    if (circles?.length && circles.length > 1) {
      const circle1Date = new Date(circles[0].xFormatted);
      const circle2Date = new Date(circles[1].xFormatted);
      const firstCircleIsStartDate = isBefore(circle1Date, circle2Date);

      setDates({
        startDate: firstCircleIsStartDate ? circle1Date : circle2Date,
        endDate: firstCircleIsStartDate ? circle2Date : circle1Date,
      });
      setCircles(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circles]);

  /** Interval re-fetch data logic */
  useEffect(() => {
    (async () => {
      if (!isStatic && formStatus === "success") {
        const periodPreviewMode = chartMode?.timeChart?.periodPreviewMode;
        const scopeMode = chartMode?.timeChart?.scopeMode;

        try {
          if (periodPreviewMode || scopeMode) {
            setStartSubscription(false);
          } else {
            setStartSubscription(true);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();

    return () => {
      setStartSubscription(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStatus, chartMode]);

  const handleOnMouseOver = () => {
    if (!chartAnimate) {
      setChartAnimate(true);
    }
  };

  const fetchAlertPoints = async (schema: ExcellenceChartDataSchema) => {
    try {
      if (!schema.parameters?.length || !schema.startTime || !schema.endTime) {
        setAlertPoints([]);
        return;
      }

      const promises = schema.parameters.map(async (param) => {
        const alertDetails = await callApi<any>({
          query: getQueryExcellenceAlertDetails(
            param.parameterId,
            param.container,
            schema.startTime || "",
            schema.endTime || ""
          ),
          auth: { setAuthedUser },
        });

        return alertDetails.map((alert: any) => ({
          x: alert.timestamp,
          y: alert.value 
        }));
      });

      const results = await Promise.all(promises);
      const flattenedResults = results.flat();
      setAlertPoints(flattenedResults);
    } catch (err) {
      console.error("Error fetching alert points:", err);
      setAlertPoints([]);
    }
  };

  const handleFetchData = async (
    timeConfig: ExcellenceDateTimeConfigurationData | null,
    existingSchema: ExcellenceChartDataSchema,
    noStateUpdate?: boolean,
    graphqlFilters?: GraphqlFilter[] | undefined
  ): Promise<TimeChartConfigAndData | undefined> => {
    try {
      let schemaToUse = { ...existingSchema };

      // 1. get start / end times
      if (timeConfig) {
        const { startTime, endTime } = getTimeChartStartEndTimes(timeConfig);
        schemaToUse = {
          ...existingSchema,
          startTime,
          endTime,
        };
      }

      // 2. fetch the data
      const result = await getTimeData({
        variables: {
          input: {
            ...schemaToUse,
            filters: buildGraphqlFilters(
              graphqlFilters || timeChart.config.graphqlFilters
            ),
          },
        },
      });
      if (!result.data || result.error) {
        throw new Error(JSON.stringify(result));
      }

      // 3. update the state data
      const resultData = result.data.timeSeriesData.chartData;
      const updatedChart = stateChart
        ? {
            ...stateChart,
            data: resultData,
            config: timeChart.config,
          }
        : stateChart;

      if (!noStateUpdate) {
        setStateChart(updatedChart);
      }

      // 4. adjust the X-axis values
      const newXScaleMinMax = getTimeChartXScaleMinMax(timeConfig, resultData, undefined);
      setXScaleMinMax(newXScaleMinMax);

      // 5. set the live data graphql vars
      if (
        timeChart.dateTimeConfig?.liveDataPeriodNumber &&
        timeChart.dateTimeConfig?.liveDataPeriodTime &&
        schemaToUse?.parameters
      ) {
        setLiveDataVars({
          parameters: schemaToUse.parameters,
          timeWindowInS: timeChartConvertToSeconds(
            timeChart.dateTimeConfig.liveDataPeriodNumber,
            timeChart.dateTimeConfig.liveDataPeriodTime as any
          ),
        });
      } else {
        setLiveDataVars(null);
      }

      await fetchAlertPoints(schemaToUse);

      return updatedChart;
    } catch (err) {
      console.log("time chart - handleFetchData() err ", err);
    }
  };

  return (
    <Box component="div" className={className} onMouseOver={handleOnMouseOver}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {titleComponent}

        {periodPreviewMode && dates ? (
          <ChartPeriodViewer dates={dates} setDates={setDates} period={period} setPeriod={setPeriod}/>
        ) : null}

        {scopeMode ? <ChartScopeMode /> : null}
      </Stack>

      {/* {timeChart?.dataSchema?.parameters?.length ? (
        <TimeChartLiveData dataSchema={timeChart.dataSchema} />
      ) : null} */}

      <Box
        component="div"
        sx={{
          width: "100%",
          height: scopeMode || periodPreviewMode ? `calc(100% - ${MODE_HEIGHT})` : "100%",
        }}
      >
        <TimeChart
          css={[styles.width100, styles.height100]}
          configuration={stateChart.config}
          data={
            subData?.liveData?.length &&
            !chartMode?.timeChart?.periodPreviewMode &&
            !chartMode?.timeChart?.scopeMode
              ? subData.liveData
              : stateChart.data
          }
          isStatic={isStatic}
          animate={chartAnimate}
          xScaleMinMax={xScaleMinMax}
          circles={
            !scopeMode
              ? undefined
              : {
                  circles,
                  setCircles,
                }
          }
          singlePoints={alertPoints || []}
          paramMapping={paramMapping}
        />

        {isStatic ? null : <LoadingBackdrop loading={loadingLineData} />}
      </Box>
    </Box>
  );
};

export default ExcellenceTimeChart;
