
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { WorkflowsJoinNodeData } from "../../workflowUtils";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";
import { Stack, Box, Typography } from "@mui/material";

interface NodeJoinDataModalProps {
  data: WorkflowsJoinNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeJoinDataModal: React.FC<NodeJoinDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();
  
  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      <LabelWithBoldedPart text={t("Join Type")} bolded={data.join_type || ""} />
      {data.join_type === "asof" && (
        <LabelWithBoldedPart text={t("Tolerance")} bolded={data.tolerance || ""} />
      )}
      <LabelWithBoldedPart text={t("Left Key")} bolded={data.key_left || ""} />
      <LabelWithBoldedPart text={t("Right Key")} bolded={data.key_right || ""} />
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_RowAggregation_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeJoinDataModal; 