import { Box, IconButton, Stack,Typography,useTheme, Tooltip } from "@mui/material";
import ResponsiveTableGrid from "../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { GetQueryProcessWizzardOrdersSnippet } from "../../../Api/ProcessWizzard/apiProcessWizzardSnippets";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ModalLayoutData } from "../../../Components/SmallComponents/TableGrid/CellActionButton";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useLanguageContext } from "../../../context/LanguageContext";
import React from "react";

const actionsColumn = {
    "id": "actions",
    "label": "Aktionen",
    "type": "string",
    "symbol": null,
    "width": null,
    "marked": false,
    "markedId": null,
    "columns": null,
    "disableEditing": true,
    "bolded100": false,
    "formatNumb": false,
    "formatNumbDecimals": 2,
    "aggregationFn": null,
    "alignCol": "center",
    "cellsConfig": null,
    "visible": true,
    "size": null
}

const addActionsColumn = (columns: any[]) => {
  return [...columns, actionsColumn];
}

interface OrdersTableProps {
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>;
  onViewTasks?: (orderId: string, orderNumber: string) => void;
  tableData: GetQueryProcessWizzardOrdersSnippet | null;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ setOpenInfo, setModalData, onViewTasks, tableData }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const rowsWithActions = tableData?.rows.map((row: any) => ({
    ...row,
    actions: (
      <Stack direction="row" justifyContent="center" alignItems="center">
        {onViewTasks && (
          <Tooltip title={t("View tasks")}>
            <IconButton
              onClick={() => onViewTasks(row.id, row.order_number)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("Manage Documents")}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpenInfo(true);
              setModalData({ type: "addDocuments", data: row });
            }}
          >
            <FileUploadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Create Statement")}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpenInfo(true);
              setModalData({ type: "addStatement", data: row });
            }}
          >
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    ),
  }));
  
  const processedTableData = tableData ? {
    ...tableData,
    columns: addActionsColumn(tableData.columns),
    rows: rowsWithActions
  } : null;

  return (
    <>
      <Stack
        mb={3}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <div style={{ flexGrow: 1 }} />
        <Typography css={styles.contentBreak} variant="h2" textAlign="center">
          {t("Orders")}
        </Typography>
        <div style={{ flexGrow: 1 }} />
      </Stack>

      {tableData && (
        <Box component="div">
          <ResponsiveTableGrid
            rows={processedTableData?.rows || []}
            colSchema={processedTableData?.columns || []}
            responsive="responsive"
            configuration={processedTableData?.configuration || {}}
            tableID="ProcessWizzard_OrdersPage_table"
          />
        </Box>
      )}
    </>
  );
};

export default React.memo(OrdersTable);
