import { Box, Chip, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { WorkflowsColumnFilterNodeData } from "../../workflowUtils";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";
interface NodeColumnFilterDataModalProps {
  data: WorkflowsColumnFilterNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeColumnFilterDataModal: React.FC<NodeColumnFilterDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();
  
  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      
      {data.include_columns && data.include_columns.length > 0 && (
        <Box component="div" sx={{ mt: 2 }}>
          <Typography variant="h4" align="center" color="textSecondary" gutterBottom>
            {t("Include Columns")}
          </Typography>
          <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {data.include_columns.map((col, index) => (
              <Chip key={index} label={col} />
            ))}
          </Box>
        </Box>
      )}
      
      {data.exclude_columns && data.exclude_columns.length > 0 && (
        <Box component="div" sx={{ mt: 2 }}>
          <Typography variant="h4" align="center" color="textSecondary" gutterBottom>
            {t("Exclude Columns")}
          </Typography>
          <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {data.exclude_columns.map((col, index) => (
              <Chip key={index} label={col} />
            ))}
          </Box>
        </Box>
      )}
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_RowAggregation_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeColumnFilterDataModal; 