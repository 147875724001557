import { useEffect, useState, useCallback } from "react";
import Modal from "../../Components/MaterialUI/Modal";
import { ModalLayoutData } from "../../Components/SmallComponents/TableGrid/CellActionButton";
import OrdersTable from "./Components/OrdersTable";
import TasksTable from "./Components/TasksTable";
import OrderDocuments from "./Components/OrderDocuments";
import { useLanguageContext } from "../../context/LanguageContext";
import StatementGenerator from "./Components/StatementGenerator";
import { GetQueryProcessWizzardOrdersSnippet } from "../../Api/ProcessWizzard/apiProcessWizzardSnippets";
import callApi from "../../Api/callApi";
import { getQueryProcessWizzardOrders } from "../../Api/ProcessWizzard/apiProcessWizzardGetQueries";
import { useAuthedContext } from "../../context/AuthContext";

const OrdersPage: React.FC = () => {
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string>("");
  const [selectedOrderNumber, setSelectedOrderNumber] = useState<string>("");
  const [tableData, setTableData] = useState<GetQueryProcessWizzardOrdersSnippet | null>(null);
  const [refetch, setRefetch] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const data = await callApi<GetQueryProcessWizzardOrdersSnippet>({
        query: getQueryProcessWizzardOrders(),
        auth: { setAuthedUser },
      });

      setTableData(data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  
  useEffect(() => {
    if(refetch) {
      fetchData();
      setRefetch(false);
    }
  }, [refetch]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewTasks = useCallback((orderId: string, orderNumber: string) => {
    setSelectedOrderId(orderId);
    setSelectedOrderNumber(orderNumber);
    setShowTasks(true);
  }, []);

  const handleBackToOrders = useCallback(() => {
    setShowTasks(false);
    setSelectedOrderId("");
    setSelectedOrderNumber("");
  }, []);

  const getModalLabel = (modalData: ModalLayoutData | null): string => {  
    if (!modalData) return "";
  
    switch (modalData.type) {
      case "details":
        return t("Details");
      case "addDocuments":
        return t("Documents");
      case "addStatement":
        return t("Statement Generator");
      default:
        return "";
    }
  };
  
  return (
    <>
      {showTasks ? (
        <TasksTable 
          orderNumber={selectedOrderNumber}
          orderId={selectedOrderId} 
          onBack={handleBackToOrders}
        />
      ) : (
        <OrdersTable 
          setOpenInfo={setOpenInfo} 
          setModalData={setModalData} 
          onViewTasks={handleViewTasks} 
          tableData={tableData}
        />
      )}

      {modalData && (
        <Modal
          open={openInfo}
          fullWidth
          label={getModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
        >
          {modalData.type === "addDocuments" && (
            <OrderDocuments orderData={modalData.data} />
          )}
          {modalData.type === "addStatement" && (
            <StatementGenerator orderId={modalData.data.id} statement={modalData.data.statement} setRefetch={setRefetch} />
          )}
        </Modal>
      )}
    </>
  );
};

export default OrdersPage;