import React, { memo } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Divider, Typography } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { KanbanWorkflowInitialNodeData } from "../../../../KanbanWorkflows/kanbanWorkflowUtils";
import FlowNode from "./FlowNode"
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { DATA_MANAGER_FLOW_NODE_TYPE } from "../workflowUtils";
import StorageIcon from "@mui/icons-material/Storage";
interface FlowCustomNodeProps {
  id: string;
  data: KanbanWorkflowInitialNodeData;
}

const InitialNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };

  return (
    <FlowNode id={id} nodeType={DATA_MANAGER_FLOW_NODE_TYPE.Initial} icon={<StorageIcon fontSize="small" />} handleOpenEditModal={() => {}}>
      <Typography variant="body1" align="center">
        {`<< ${t("Workflow")} >>`}
      </Typography>

      <Divider css={[styles.textBreak, styles.reverseTextBreak]} color="lightgrey" />

      <Typography variant="h2" align="center">
        {data.name}
      </Typography>
    </FlowNode>
  );
};

export default memo(InitialNode);
