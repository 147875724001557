import { SerializedStyles } from "@emotion/react";
import { Box, Stack, useTheme } from "@mui/material";
import { Datum, ResponsiveLine } from "@nivo/line";
import { LineAreaHeatMapScatterPlotData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { getChartColors } from "../nivoTheme";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import { memo, useMemo, useRef, useState } from "react";
import lineChartDefaultData from "../ExcellenceDefaultConfig/lineChartDefaultData";
import { LineChartConfig } from "./lineChartTypes";
import {
  TimeChartThreshold,
  TimeChartThresholdValues,
} from "../TimeChart/timeChartTypes";
import { timeChartMinMaxY } from "../TimeChart/timeChartUtils";
import { LineChartXScaleMinMax } from "./lineChartUtils";
import { CustomLegend } from "../ExcellenceSmallComponents";

interface LineChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: LineChartConfig;
  data: LineAreaHeatMapScatterPlotData | null;
  isStatic?: boolean;
  xScaleMinMax?: LineChartXScaleMinMax;
}

const LineChart: React.FC<LineChartProps> = ({
  className,
  configuration,
  data,
  // xScaleMinMax,
  isStatic,
}) => {
  const {
    axisLeftLegend,
    axisBottomLegend,
    enablePoints,
    interpolate,
    enableGridX,
    enableGridY,
    threshold,
  } = configuration;
  const theme = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width: chartWidth } = useContainerDimensions(chartContainerRef);

  const dynamicData = useMemo(
    () => getDynamicData(chartWidth, data || lineChartDefaultData),
    [chartWidth, data]
  );
  const longestLegend = dynamicData.reduce(
    (max, item) => Math.max(max, item.name.length),
    0
  );
  const yScaleMinMax = timeChartMinMaxY(dynamicData, configuration, threshold?.values);
  

  const MARKER_COLORS: Record<keyof TimeChartThresholdValues, string> = {
    lowLowThreshold: theme.palette.error.dark,
    lowThreshold: theme.palette.warning.dark,
    highThreshold: theme.palette.warning.dark,
    highHighThreshold: theme.palette.error.dark,
    percentage: theme.palette.primary.main,
    periodTime: theme.palette.primary.main,
    periodNumber: theme.palette.primary.main,
  };

  // Create a function to calculate the required bottom margin based on label rotation and length
  // const calculateBottomMargin = (rotation: number, tickLabels: string[] = []): number => {
  //   if (isStatic) return 10;
    
  //   // Base margin
  //   const baseMargin = 36; // For legend offset
    
  //   // If no rotation, use minimal margin
  //   if (rotation === 0) return baseMargin + 24;
    
  //   // Calculate average label length
  //   const avgLabelLength = tickLabels.length > 0 
  //     ? tickLabels.reduce((sum, label) => sum + String(label).length, 0) / tickLabels.length 
  //     : 0;
    
  //   // As rotation increases, especially past 45 degrees, we need more space
  //   // The sine of the angle (in radians) gives us a good multiplier for vertical space needed
  //   const rotationRadians = (rotation * Math.PI) / 180;
  //   const verticalSpaceFactor = Math.sin(rotationRadians);
    
  //   // Calculate additional margin needed based on rotation and label length
  //   const additionalMargin = Math.ceil(verticalSpaceFactor * avgLabelLength * 7); // 7px per character
    
  //   return baseMargin + additionalMargin + 10; // Add padding
  // };



  const [visibleKeys, setVisibleKeys] = useState<string[]>(
    dynamicData.map((item) => item.name)
  );

  const handleToggleVisibility = (key: string) => {
    setVisibleKeys((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };

  const filteredData = useMemo(
    () => dynamicData.filter((item) => visibleKeys.includes(item.name)),
    [dynamicData, visibleKeys]
  );

  const calculateTickValues = (data: any[], maxTicks: number) => {
    if (!data.length || !data[0].data.length) return [];
    
    const allXValues = data[0].data.map((d: any) => d.x);
    
    // If fewer points than max ticks, use all points
    if (allXValues.length <= maxTicks) return allXValues;
    
    const step = Math.ceil(allXValues.length / maxTicks);
    return allXValues.filter((_: any, i: number) => i % step === 0);
  };

  const xTickValues = useMemo(
    () => calculateTickValues(filteredData, 20),
    [filteredData]
  );

  const widthRotation = calculateLabelRotation(chartWidth, xTickValues);

  // Use the function to determine bottom margin
  // const bottomMargin = calculateBottomMargin(widthRotation, xTickValues);

  // Update chart margin with dynamic bottom value
  const chartMargin = {
    top: isStatic ? 10 : 25,
    right: isStatic ? 10 : 10 + longestLegend * 2,
    bottom: 60,
    left: isStatic ? 10 : 60,
  };

  return (
    <Box component="div" className={className} ref={chartContainerRef}>
      <Stack direction="row" style={{ width: "100%", height: "100%" }} spacing={0}>
        <Box
          component="div"
          style={{
            flexGrow: 1,
          }}
        >
          <ResponsiveLine
            data={filteredData}
            margin={chartMargin}
            enablePoints={enablePoints}
            enableGridX={enableGridX}
            enableGridY={enableGridY}
            isInteractive={isStatic ? false : filteredData.length > 0}
            curve={interpolate}
            colors={(d) => getLineColors(d, threshold, MARKER_COLORS)}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              crosshair:
                theme.palette.mode === "dark"
                  ? {
                      line: {
                        stroke: "#FFFFFF",
                        strokeWidth: 2,
                      },
                    }
                  : undefined,
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            xScale={{
              type: "point",
              // min: xScaleMinMax ? xScaleMinMax.min : undefined,
              // max: xScaleMinMax ? xScaleMinMax.max : undefined,
            }}
            yScale={{
              type: "linear",
              min: yScaleMinMax.min,
              max: yScaleMinMax.max,
              stacked: false,
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={
              isStatic || !dynamicData?.length
                ? null
                : {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: widthRotation,
                    legend: axisBottomLegend,
                    legendOffset: 36,
                    legendPosition: "middle",
                    tickValues: xTickValues,
                  }
            }
            axisLeft={
              isStatic || !dynamicData?.length
                ? null
                : {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: axisLeftLegend,
                    legendOffset: -50,
                    legendPosition: "middle",
                  }
            }
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{
              from: "serieColor",
              modifiers: theme.palette.mode === "light" ? [["darker", 1.6]] : undefined,
            }}
            pointLabelYOffset={-12}
            useMesh={filteredData.length > 0}
            animate={false}
          />
        </Box>

        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomLegend
            items={
              dynamicData.map((item) => ({ label: item.name, color: item.color })) || []
            }
            width={longestLegend * 11}
            visibleItems={visibleKeys}
            onToggleVisibility={handleToggleVisibility}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default memo(LineChart);

type LineDatum = {
  x: string;
  y: number;
};
const getDynamicData = (width: number, data: LineAreaHeatMapScatterPlotData) => {
  // 1. Calculate max numb of points based on chart width
  const maxDataPoints = Math.round(width / 1);

  // 2. Down-sample the data to have a length up to maxPoints
  const adjustedData = data.map((item, dataIndex) => {
    const typedDatum = item.data as LineDatum[];

    const downSampledArray = downSampleData(typedDatum, maxDataPoints);

    return {
      ...item,
      name: item.name || item.id,
      color: getChartColors()?.[dataIndex] || "black",
      data: downSampledArray,
    };
  });

  return adjustedData;
};

const downSampleData = (data: LineDatum[], maxDataPoints: number): LineDatum[] => {
  const dataLength = data.length;

  // If the data length is less than or equal to maxDataPoints, no need to downsample
  if (dataLength <= maxDataPoints) {
    return data;
  }

  // Calculate the step size for downsampling
  const stepSize = Math.ceil(dataLength / maxDataPoints);

  // Downsample the data by picking every nth point
  const downSampledData = data.filter((_, index) => index % stepSize === 0);

  return downSampledData;
};

type SingleDatum = {
  id: string;
  name: string;
  color: string;
  data: Datum[];
};

const getLineColors = (
  datum: SingleDatum,
  threshold: TimeChartThreshold | undefined,
  markerColors: Record<keyof TimeChartThresholdValues, string>
): string => {
  if (threshold?.mode === "valuesFromNodes" && threshold?.values) {
    if (datum.id === threshold.values.highHighThreshold?.node) {
      return markerColors.highHighThreshold;
    } else if (datum.id === threshold.values.highThreshold?.node) {
      return markerColors.highThreshold;
    } else if (datum.id === threshold.values.lowThreshold?.node) {
      return markerColors.lowThreshold;
    } else if (datum.id === threshold.values.lowLowThreshold?.node) {
      return markerColors.lowLowThreshold;
    }
  }
  return datum.color;
};

const calculateLabelRotation = (chartWidth: number, tickLabels: string[] = []): number => {
  // If no tick labels, use existing width-based calculation
  if (!tickLabels.length) {
    const maxChartWidth = 1400;
    const maxRotation = 90;
    const initialRotation = 2;

    if (chartWidth >= maxChartWidth) {
      return 0;
    } else {
      const additionalRotation = maxRotation * ((maxChartWidth - chartWidth) / maxChartWidth);
      return Math.min(maxRotation, initialRotation + additionalRotation);
    }
  }

  // Calculate average label length
  const avgLabelLength = tickLabels.reduce((sum, label) => sum + String(label).length, 0) / tickLabels.length;
  
  // Calculate estimated pixel width per character (approximation)
  const pixelsPerChar = 7; // Typical character width in pixels for most fonts
  
  // Calculate total width needed for all labels if horizontal
  const labelsCount = tickLabels.length;
  // const totalWidthNeeded = labelsCount * avgLabelLength * pixelsPerChar;
  
  // Available width per label
  const availableWidthPerLabel = chartWidth / labelsCount;
  
  // Calculate width ratio (needed vs available)
  const widthRatio = (avgLabelLength * pixelsPerChar) / availableWidthPerLabel;
  
  // Calculate rotation based on ratio
  if (widthRatio <= 0.9) {
    // Labels fit comfortably, no rotation needed
    return 0;
  } else if (widthRatio <= 1.2) {
    // Labels slightly overlap, small rotation
    return 25;
  } else if (widthRatio <= 2) {
    // Moderate overlap, medium rotation
    return 45;
  } else if (widthRatio <= 3) {
    // Significant overlap, larger rotation
    return 65;
  } else {
    // Extreme overlap, maximum rotation
    return 90;
  }
};
