import { Formik } from "formik";
import { Stack } from "@mui/material";
import { object } from "yup";
import { useState } from "react";
import { SerializedStyles } from "@emotion/react";
import { YUP_REQUIRED_STRING } from "../../../Global/Constants/yupConstants";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import TextField from "../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../Components/MaterialUI/Button";
import Alert from "../../../Components/MaterialUI/Alert";
import { useLanguageContext } from "../../../context/LanguageContext";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
  plantArea: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
  details?: string;
  plantArea?: string;
};

interface EditPidWorkflowFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  currentName: string;
  handleEdit: (
    updatedName: string,
    updatedPlant?: string,
    updatedDetails?: string
  ) => void;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  currentPlantArea?: string;
  currentDetails?: string;
}

const EditPidWorkflowForm: React.FC<EditPidWorkflowFormProps> = ({
  className,
  currentName,
  handleEdit,
  currentDetails,
  currentPlantArea,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const initialValues: FormValues = {
    name: currentName,
    ...(currentPlantArea && { plantArea: currentPlantArea }),
    ...(currentDetails && { details: currentDetails }),
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);
      setFormStatus("success");
      setUnsavedChanges(false);
      handleEdit(
        values.name,
        currentPlantArea ? values.plantArea : undefined,
        currentDetails ? values.details : undefined
      );
    } catch (err) {
      console.log("EditPidWorkflowForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              className={className}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                name="name"
                label={t("Chart Name")}
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />
              {currentPlantArea && (
                <TextField
                  name="plantArea"
                  label={t("Plant Area")}
                  error={touched["plantArea"] && !!errors["plantArea"]}
                  helperText={touched["plantArea"] && errors["plantArea"]}
                  onChange={handleChange}
                  value={values.plantArea}
                />
              )}
              {currentDetails && (
                <TextField
                  name="details"
                  label={t("Details")}
                  error={touched["details"] && !!errors["details"]}
                  helperText={touched["details"] && errors["details"]}
                  onChange={handleChange}
                  value={values.details}
                  multiline
                  minRows={2}
                />
              )}

              <Button type="submit" loading={formStatus === "loading"}>
                {t("Edit P&ID Chart")}
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditPidWorkflowForm;
