import { Box, IconButton, Stack, Theme, Typography } from "@mui/material";
import { css } from "@emotion/react";
import {
  AUTH_LAYOUT_PADDING,
  AppRouterProps,
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
} from "../../../Layout/layoutVariables";
import React, { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Modal from "../../../Components/MaterialUI/Modal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "../../../Components/MaterialUI/Button";
import Alert from "../../../Components/MaterialUI/Alert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../../Global/Hooks/useTranslations";
import CustomIconsTooltip from "../../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import {
  GetQueryReactWorkflowSnippet,
  ReactFlowMainType,
  RenderForm,
  SelectedType,
  WorkflowViewMode,
} from "./reactFlowUtils";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditPidWorkflowForm from "./EditReactWorkflowForm";
import ReactWorkflow from "./ReactWorkflow";
import {
  PID_FLOW_NODE_TYPE,
  PidFlowNodeTypeKey,
} from "../../../pages/PidCharts/Components/PidWorkflow/Nodes/pidNodesUtils";

const cssStyles = (theme: Theme) => ({
  flowWrapper: css({
    width: "100%",
    // minHeight: parent's minHeight - the padding
    minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING})`,
    [theme.breakpoints.down("sm")]: {
      // minHeight: parent's minHeight - the padding - extra_nav_menu
      minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING} - 75px)`,
    },
  }),
  flow: css({
    minHeight: "inherit",
    "& > div": {
      minHeight: "inherit",
    },
  }),
});

const modalTitleTranslations = {
  "Edit workflow": "Edit workflow",
  "Delete workflow": "Delete workflow",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface ReactFlowMainProps {
  viewMode?: WorkflowViewMode;
  setViewMode?: React.Dispatch<React.SetStateAction<WorkflowViewMode>>;
  getQueryWorkflow: () => Promise<GetQueryReactWorkflowSnippet | undefined>;
  saveReactWorkflow: (workflow: ReactFlowMainType) => Promise<void>;
  deleteReactWorkflow: (workflowId: string) => Promise<void>;
  redirectPathName: string;
  hasDetails?: boolean;
  hasPlantArea?: boolean;
  nodeTypes: any;
  selectedType?: SelectedType;
  setSelectedType?: React.Dispatch<React.SetStateAction<PID_FLOW_NODE_TYPE | "">>;
  typeConfig: any;
  renderForm: RenderForm;
  handleOnTypeChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  nodeOptions?: SelectOption<PidFlowNodeTypeKey>[];
  autosave?: boolean;
}

const ReactFlowMain: React.FC<ReactFlowMainProps> = ({
  viewMode,
  setViewMode,
  getQueryWorkflow,
  saveReactWorkflow,
  deleteReactWorkflow,
  redirectPathName,
  hasDetails = false,
  hasPlantArea = false,
  nodeTypes,
  selectedType,
  setSelectedType,
  typeConfig,
  renderForm,
  handleOnTypeChange,
  nodeOptions,
  autosave = false,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssSpacingStyles(theme) };
  const [workflow, setWorkflow] = useState<ReactFlowMainType | null>(null);
  const [initialWorkflow, setInitialWorkflow] = useState<ReactFlowMainType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [initialNodeUpdated, setInitialNodeUpdated] = useState<boolean>(false);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);

  const { smMediaQuery, setExtraRightNavMenu, setUnsavedChanges } =
    useOutletContext<AppRouterProps>();
  const navigate = useNavigate();
  const initialNode = workflow?.nodes.find((node) => node?.type === "initial");

  useEffect(() => {
    (async () => {
      try {
        const res = await getQueryWorkflow();
        if (res?.data) {
          console.log("Setting workflow data:", res.data);
          setWorkflow(res.data);
          setInitialWorkflow(res.data);
        }
        if (res?.workflow) {
          setWorkflow(res.workflow);
          setInitialWorkflow(res.workflow);
        }
      } catch (err) {
        console.error("error - fetch workflow", err);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraRightNavMenu(null);
    } else {
      setExtraRightNavMenu(
        <ManageWorkflow
          setModalType={setModalType}
          workflow={workflow}
          setUnsavedChanges={setUnsavedChanges}
          viewMode={viewMode}
          setViewMode={setViewMode}
          saveReactWorkflow={saveReactWorkflow}
          autosave={autosave}
        />
      );
    }

    return () => {
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, workflow, viewMode]);

  useEffect(() => {
    if (workflow && initialWorkflow) {
      const areEqual = JSON.stringify(workflow) === JSON.stringify(initialWorkflow);
      setUnsavedChanges(!areEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const handleDeleteWorkflow = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (workflow?.nodes.length) {
        deleteReactWorkflow(workflow.id);
      }

      setFormStatus("success");
      setAlertMessage(null);
      navigate({
        pathname: redirectPathName,
      });
    } catch (err) {
      console.log("error - handleDeleteWorkflow() ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleEdit = (
    updatedName: string,
    updatedPlant?: string,
    updatedDetails?: string
  ) => {
    if (workflow?.nodes.length) {
      const updatedNodes = workflow.nodes.map((item) => {
        if (item.type === "initial") {
          return {
            ...item,
            data: {
              ...item.data,
              name: updatedName,
            },
          };
        }
        return item;
      });
      const updatedWorkflow: ReactFlowMainType = {
        ...workflow,
        name: updatedName,
        ...(updatedDetails && { details: updatedDetails }),
        ...(updatedPlant && { plantArea: updatedPlant }),
        nodes: updatedNodes,
        updatedOn: new Date().toISOString(),
      };

      setWorkflow(updatedWorkflow);
      setInitialNodeUpdated((prev) => !prev);
      setModalType(null);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  return (
    <Box component="div" css={styles.flowWrapper}>
      {smMediaQuery ? (
        <Box component="div" css={styles.labelBreak}>
          <ManageWorkflow
            setModalType={setModalType}
            workflow={workflow}
            setUnsavedChanges={setUnsavedChanges}
            viewMode={viewMode}
            setViewMode={setViewMode}
            saveReactWorkflow={saveReactWorkflow}
            autosave={autosave}
          />
        </Box>
      ) : null}

      {workflow?.id ? (
        <ReactWorkflow
          css={styles.flow}
          workflow={workflow}
          setWorkflow={setWorkflow}
          initialNodeUpdated={initialNodeUpdated}
          nodeTypes={nodeTypes}
          typeConfig={typeConfig}
          renderForm={renderForm}
          {...(selectedType && { selectedType: selectedType })}
          {...(setSelectedType && { setSelectedType: setSelectedType })}
          {...(handleOnTypeChange && { handleOnTypeChange: handleOnTypeChange })}
          {...(viewMode && { viewMode: viewMode })}
          {...(nodeOptions && { nodeOptions: nodeOptions })}
        />
      ) : null}

      <Modal
        open={!!modalType}
        onClose={() => setModalType(null)}
        fullWidth
        maxWidth="sm"
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
      >
        {modalType === "Edit workflow" ? (
          <EditPidWorkflowForm
            currentName={initialNode?.data.name || ""}
            handleEdit={handleEdit}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedEditChanges}
            {...(hasPlantArea && { currentPlantArea: workflow?.plantArea })}
            {...(hasDetails && { currentDetails: workflow?.details })}
          />
        ) : null}

        {modalType === "Delete workflow" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete your workflow")}
            </Typography>
            <Alert
              message={alertMessage}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
            <Button onClick={handleDeleteWorkflow}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default ReactFlowMain;

interface ManageWorkflowProps {
  setModalType: React.Dispatch<React.SetStateAction<ModalType | null>>;
  workflow: ReactFlowMainType | null;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  viewMode?: WorkflowViewMode;
  setViewMode?: React.Dispatch<React.SetStateAction<WorkflowViewMode>>;
  saveReactWorkflow: (workflow: ReactFlowMainType) => Promise<void>;
  autosave?: boolean;
}

const ManageWorkflow: React.FC<ManageWorkflowProps> = ({
  setModalType,
  workflow,
  setUnsavedChanges,
  viewMode,
  setViewMode,
  saveReactWorkflow,
  autosave = false,
}) => {
  const { t } = useLanguageContext();

  useEffect(() => {
    if(autosave) {
      handleSaveWorkflow();
    }
  }, [JSON.stringify(workflow?.nodes.map((node) => node.data))]);

  const handleSaveWorkflow = async () => {
    if (workflow?.nodes.length) {
      try {
        await saveReactWorkflow(workflow);
        setUnsavedChanges(false);
      } catch (err) {
        console.error("Error in handleSaveWorkflow:", err);
      }
    }
  };

  const handleChangeMode = () => {
    if (setViewMode) {
      setViewMode((prev) => {
        if (prev === "Edit Mode") {
          return "View Mode";
        }
        return "Edit Mode";
      });
    }
  };

  return (
    <Stack spacing={1} justifyContent="flex-end" direction="column">
      {viewMode ? (
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={!viewMode ? t("Edit Mode") : t("View Mode")}>
            <IconButton aria-label="edit workflow" onClick={handleChangeMode}>
              {viewMode === "View Mode" ? <DesignServicesIcon /> : <VisibilityIcon />}
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
      ) : null}
      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Edit")}>
          <IconButton
            aria-label="edit workflow"
            onClick={() => setModalType("Edit workflow")}
            disabled={viewMode === "View Mode"}
          >
            <EditOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Export")}>
          <IconButton aria-label="export" disabled>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Save")}>
          <IconButton aria-label="save changes" onClick={() => handleSaveWorkflow()}>
            <SaveOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>

      <Stack alignItems="center">
        <CustomIconsTooltip tooltipText={t("Delete")}>
          <IconButton
            aria-label="delete workflow"
            onClick={() => setModalType("Delete workflow")}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </CustomIconsTooltip>
      </Stack>
    </Stack>
  );
};
