import { Query } from "../callApi";

export const getQueryProcessWizzardOrders = (): Query => ({
  endpoint: "mechanic/orders",
  method: "GET",
});

export const getQueryProcessWizzardOrderTasks = (orderId: string): Query => ({
  endpoint: `mechanic/orders/${orderId}/tasks`,
  method: "GET",
});

export const getQueryProcessWizzardOrderDocuments = (orderId: string): Query => ({
  endpoint: `mechanic/orders/${orderId}/documents`,
  method: "GET",
});

export const getQueryProcessWizzardTemplates = (): Query => ({
  endpoint: "mechanic/templates",
  method: "GET",
});

export const getQueryProcessWizzardOrderStatement = (orderId: string): Query => ({
  endpoint: `mechanic/orders/${orderId}/statement`,
  method: "GET",
});

export const getQueryProcessWizzardKanbanColumns = (): Query => ({
  endpoint: "mechanic/kanban",
  method: "GET",
});

export const getQueryProcessWizzardKanbanTasks = (): Query => ({
  endpoint: "mechanic/tasks",
  method: "GET",
});

export const getQueryProcessWizzardTaskStatuses = (): Query => ({
  endpoint: "mechanic/task-statuses",
  method: "GET",
});
