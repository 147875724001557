import { useEffect, useState } from "react";
import { useAuthedContext } from "../../../../context/AuthContext";
import ResponsiveTableGrid from "../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { Box, useTheme } from "@mui/material";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { getQueryFiscalPeriods } from "./API/apiFiscalPeriodsGetQuery";
import LoadingBackdrop from "../../../../Components/MaterialUI/LoadingBackdrop";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { GetFiscalPeriodsSnippet } from "./API/GetFiscalPeriodsSnippet";
import callApi from "../../../../Api/callApi";


const FiscalPeriods: React.FC = () => {
    const [tableData, setTableData] = useState<GetFiscalPeriodsSnippet | null>(null);
    const { setAuthedUser } = useAuthedContext();
    const theme = useTheme();
    const styles = {
        ...cssLayoutStyles,
        ...cssSpacingStyles(theme),
    };

    const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
    const fetchData = async () => {
        try {
            const data = await callApi<GetFiscalPeriodsSnippet>({
                query: getQueryFiscalPeriods(),
                auth: { setAuthedUser },
            });

            setTableData(data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        setPageStatus("loading");
        fetchData();
        setPageStatus(null);
    }, [setAuthedUser]);

    return (
        <>
            {pageStatus === "loading" ? (
                <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
                    <LoadingBackdrop loading={pageStatus === "loading"} />
                </Box>
            ) : (<>
                <ResponsiveTableGrid
                    rows={tableData?.rows || []}
                    colSchema={tableData?.columns || []}
                    editMode={false}
                    responsive="desktop"
                    configuration={tableData?.configuration}
                    tableID="Fiscal-Periods-Table"
                />
            </>
            )}
        </>
    );
};


export default FiscalPeriods;