import {
  Box, 
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useTheme,
} from "@mui/material";
import {
  DATA_MANAGER_FLOW_NODE_TYPE,
  WorkflowsRowAggregationNodeData,
} from "../../workflowUtils";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses, SelectOption } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import Select from "../../../../../../Components/MaterialUI/FormFields/Select";
interface NodeAggregationFormProps {
  handleCreateSubmit?: (data: WorkflowsRowAggregationNodeData) => void;
  handleEditSubmit?: (data: WorkflowsRowAggregationNodeData) => void;
  data?: WorkflowsRowAggregationNodeData;
  nodes: Node<WorkflowsRowAggregationNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
  columnOptions: SelectOption[];
}

const aggregationOptions: SelectOption[] = [
  { value: "sum", description: "Sum" },
  { value: "avg", description: "Average" },
  { value: "min", description: "Minimum" },
  { value: "max", description: "Maximum" },
  { value: "prod", description: "Product" },
  { value: "count", description: "Count" },
];

const NodeRowAggregationForm: React.FC<NodeAggregationFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  columnOptions
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Aggregation");
  const [columns, setColumns] = useState<string[]>(data?.target_columns || []);
  const [aggregation, setAggregation] = useState<string>(data?.aggregation || "sum");
  const [resultColumn, setResultColumn] = useState<string>(data?.result_column || "");
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation;

  const initialFormState = useRef({
    name,
    columns,
    aggregation,
    resultColumn
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, columns, aggregation, resultColumn },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    if (columnOptions.length) {
      if (!columns || columns.length === 0) {
        setAlertMessage(t("At least one column is required"));
        setFormStatus("warning");
        return;
      }

      if (!aggregation) {
        setAlertMessage(t("Aggregation type is required"));
        setFormStatus("warning");
        return;
      }
    }

    const current = new Date().toISOString();

    const result: WorkflowsRowAggregationNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      target_columns: columns,
      aggregation: aggregation as 'sum' | 'avg' | 'min' | 'max' | 'prod' | 'count',
      result_column: resultColumn || undefined,
      createdOn: data?.createdOn || current,
      updatedOn: current,
      container_id: data?.container_id || "",
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Row Aggregation Configuration")}
          </Typography>
          {!columnOptions.length ? (
            <Alert
              message={t("This node requires 1 input node to be connected before configuration")}
              showAlert={true}
              severity="warning"
            />
          ) : null}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                selectOptions={columnOptions}
                fullWidth
                label={t("Columns")}
                value={columns}
                onChange={(e) => setColumns(Array.isArray(e.target.value) ? e.target.value : [])}
                required
                error={formStatus === "warning" && columns.length === 0}
                helperText={formStatus === "warning" && columns.length === 0 ? t("Columns are required") : ""}
                multiple
                disabled={!columnOptions.length}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                selectOptions={aggregationOptions}
                fullWidth
                label={t("Aggregation Type")}
                value={aggregation}
                onChange={(e) => setAggregation(e.target.value)}
                required
                error={formStatus === "warning" && !aggregation}
                disabled={!columnOptions.length}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="resultColumn"
                label={t("Result Column (optional)")}
                onChange={(e) => setResultColumn(e.target.value)}
                value={resultColumn}
                placeholder="aggregation_result"
                disabled={!columnOptions.length}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button 
          css={[styles.width100, styles.widthLimit20]} 
          onClick={handleFormSubmit}
        >
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeRowAggregationForm; 