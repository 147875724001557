import {
  Box, 
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useTheme
} from "@mui/material";
import {
  DATA_MANAGER_FLOW_NODE_TYPE,
  WorkflowsParameterNodeData,
} from "../../../DataFlow/workflowUtils";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceParameters } from "../../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { ExcellenceParameter } from "../../../../../../GraphQL/Excellence/graphQLTypesExcellence";
import { AutocompleteOption, SelectOption } from "../../../../../../Global/Types/commonTypes";
import Autocomplete from "../../../../../../Components/MaterialUI/FormFields/Autocomplete";

interface NodeParameterFormProps {
  handleCreateSubmit?: (data: WorkflowsParameterNodeData) => void;
  handleEditSubmit?: (data: WorkflowsParameterNodeData) => void;
  data?: WorkflowsParameterNodeData;
  nodes: Node<WorkflowsParameterNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const NodeParameterForm: React.FC<NodeParameterFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  //nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  //unsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Parameter");
  const [selectedConnection, setSelectedConnection] = useState<AutocompleteOption>({ value: "", description: "" });
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [parameters, setParameters] = useState<ExcellenceParameter[]>([]);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.Parameter;

  const [getParameters] = useLazyQuery<{ parameters: ExcellenceParameter[] }>(
    graphQlQueryExcellenceParameters
  );

  useEffect(() => {
    fetchParameters();
  }, []);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    setConnectionOptions(allConnectionOptions);

    // If we have existing data, set the initial connection
    if (data?.container_id) {
      const container = parameters.find(p => p.container === data.container_id);
      if (container) {
        setSelectedConnection({
          value: container.container,
          description: container.containerName
        });
      }
    }
  }, [parameters, data]);

  const fetchParameters = async () => {
    try {
      const result = await getParameters();
      if (result.data?.parameters) {
        setParameters(result.data.parameters);
      }
    } catch (err) {
      console.error("Error fetching parameters:", err);
      setFormStatus("error");
      setAlertMessage(t("Failed to fetch parameters"));
    }
  };

  const initialFormState = useRef({
    name,
    selectedConnection,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, selectedConnection },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    if (!selectedConnection.value) {
      setAlertMessage(t("Please select a connection"));
      setFormStatus("warning");
      return;
    }

    const current = new Date().toISOString();
    const result: WorkflowsParameterNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      container_id: selectedConnection.value,
      createdOn: data?.createdOn || current,
      updatedOn: current,
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Parameter Configuration")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                css={styles.width100}
                label={t("Selected Connection")}
                options={connectionOptions}
                value={selectedConnection}
                handleOnChange={(val: AutocompleteOption | null) => {
                  setSelectedConnection(val || { value: "", description: "" });
                }}
                disabled={formStatus === "loading"}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button css={[styles.width100, styles.widthLimit20]} onClick={handleFormSubmit}>
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeParameterForm;