import {
  Box, 
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useTheme
} from "@mui/material";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses, SelectOption } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { DATA_MANAGER_FLOW_NODE_TYPE, WorkflowsJoinNodeData } from "../../workflowUtils";
import Select from "../../../../../../Components/MaterialUI/FormFields/Select";

interface NodeJoinFormProps {
  handleCreateSubmit?: (data: WorkflowsJoinNodeData) => void;
  handleEditSubmit?: (data: WorkflowsJoinNodeData) => void;
  data?: WorkflowsJoinNodeData;
  nodes: Node<WorkflowsJoinNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
  firstColumnOptions: SelectOption[];
  secondColumnOptions: SelectOption[];
}

const joinOptions: SelectOption[] = [
  { value: "inner", description: "Inner" },
  { value: "left", description: "Left" },
  { value: "right", description: "Right" },
  { value: "outer", description: "Outer" },
  { value: "asof", description: "Asof" },
];

const NodeJoinForm: React.FC<NodeJoinFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  firstColumnOptions,
  secondColumnOptions
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Join");
  const [keyLeft, setKeyLeft] = useState<string>(data?.key_left || "");
  const [keyRight, setKeyRight] = useState<string>(data?.key_right || "");
  const [joinOption, setJoinOption] = useState<string>(data?.join_type || "inner");
  const [tolerance, setTolerance] = useState<string>(data?.tolerance || "10 minutes"); 
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.Join;

  const initialFormState = useRef({
    name,
    joinOption,
    keyLeft,
    keyRight,
    tolerance
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, joinOption, keyLeft, keyRight, tolerance },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    if (firstColumnOptions.length && secondColumnOptions.length) {
      if (!joinOption) {
        setAlertMessage(t("Join type is required"));
        setFormStatus("warning");
        return;
      }

      if (!keyLeft) {
        setAlertMessage(t("Left key is required"));
        setFormStatus("warning");
        return;
      }

      if (!keyRight) {
        setAlertMessage(t("Right key is required"));
        setFormStatus("warning");
        return;
      }

      if (joinOption === "asof" && !tolerance) {
        setAlertMessage(t("Tolerance is required for asof join"));
        setFormStatus("warning");
        return;
      }
    }

    const current = new Date().toISOString();
    const result: WorkflowsJoinNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      key_left: keyLeft,
      key_right: keyRight,
      tolerance: tolerance,
      join_type: joinOption as "inner" | "left" | "right" | "outer" | "asof",
      createdOn: data?.createdOn || current,
      updatedOn: current,
      container_id: data?.container_id || "",
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Join Configuration")}
          </Typography>
          {!firstColumnOptions.length || !secondColumnOptions.length ? (
            <Alert
              message={t("This node requires 2 input nodes to be connected before configuration")}
              showAlert={true}
              severity="warning"
            />
          ) : null}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                selectOptions={joinOptions}
                fullWidth
                label={t("Join Type")}
                value={joinOption}
                onChange={(e) => setJoinOption(e.target.value)}
                required
                error={formStatus === "warning" && !joinOption}
                disabled={!firstColumnOptions.length || !secondColumnOptions.length}
              />
            </Grid>
            {joinOption === "asof" && (
              <Grid item xs={12}>
                <TextField
                  name="tolerance"
                  label={t("Tolerance")}
                  onChange={(e) => setTolerance(e.target.value)}
                  value={tolerance}
                  required
                  error={formStatus === "warning" && !tolerance}
                  helperText={formStatus === "warning" && !tolerance ? t("Tolerance is required") : t("Tolerance value for asof join")}
                  disabled={!firstColumnOptions.length || !secondColumnOptions.length}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                selectOptions={firstColumnOptions}
                fullWidth
                label={t("Left Key")}
                value={keyLeft}
                onChange={(e) => setKeyLeft(e.target.value)}
                required
                error={formStatus === "warning" && !keyLeft}
                helperText={formStatus === "warning" && !keyLeft ? t("Left key is required") : t("Column name for the left DataFrame")}
                disabled={!firstColumnOptions.length}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                selectOptions={secondColumnOptions}
                fullWidth
                label={t("Right Key")}
                value={keyRight}
                onChange={(e) => setKeyRight(e.target.value)}
                required
                error={formStatus === "warning" && !keyRight}
                helperText={formStatus === "warning" && !keyRight ? t("Right key is required") : t("Column name for the right DataFrame")}
                disabled={!secondColumnOptions.length}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button 
          css={[styles.width100, styles.widthLimit20]} 
          onClick={handleFormSubmit}
        >
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeJoinForm; 