import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ResponsiveTableGrid from '../../Components/SmallComponents/TableGrid/ResponsiveTableGrid';
import callApi from '../../Api/callApi';
import { useAuthedContext } from '../../context/AuthContext';
import { getQueryDataManagerNotifications } from '../../Api/DataManager/apiDataManagerGetQueries';

const Notifications = () => {
  const [tableData, setTableData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    const fetchAnomalyRules = async () => {
      try {
        const data = await callApi<any>({
          query: getQueryDataManagerNotifications(),
          auth: { setAuthedUser },
        });
        setTableData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnomalyRules();
  }, [setAuthedUser]);

  return (
    <Box component="div" p={2}>
      <ResponsiveTableGrid
        rows={tableData?.rows || []}
        colSchema={tableData?.columns || []}
        responsive="desktop"
        loading={loading}
        configuration={{
          density: "compact",
          initialRowsPerPage: 15,
        }}
        tableID="DataManager_Notifications"
      />
    </Box>
  );
};

export default Notifications;
