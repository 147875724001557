import { useParams } from "react-router-dom";
import { useAuthedContext } from "../../../context/AuthContext";
import {
  GetQueryReactWorkflowSnippet,
  ReactFlowMainType,
  RenderForm,
} from "../../../Components/SmallComponents/ReactFlowComponents/reactFlowUtils";
import callApi from "../../../Api/callApi";
import { getQueryKanbanWorkflow } from "../api/queries";
import ReactFlowMain from "../../../Components/SmallComponents/ReactFlowComponents/ReactFlowMain";
import { deleteQueryKanbanWorkflow, putQueryKanbanWorkflow } from "../api/mutations";
import ROUTES_MAPPING from "../../../Layout/Router/routesMapping";
import KanbanWorkflowInitialNode from "./Components/KanbanWorkflow/Nodes/KanbanWorkflowInitialNode";
import {
  KAN_FLOW_NODE_TYPE,
  KANBAN_WORKFLOW_GENERAL_NODE_CONFIG,
} from "../kanbanWorkflowUtils";
import KanbanWorkflowGeneralNode from "./Components/KanbanWorkflow/Nodes/General/KanbanWorkflowGeneralNode";
import { useState } from "react";
import NodeGeneralForm from "./Components/KanbanWorkflow/Nodes/General/NodeGeneralForm";

const WorkflowSinglePage: React.FC = () => {
  const { id } = useParams();
  const { setAuthedUser } = useAuthedContext();
  const [selectedType, setSelectedType] = useState<KAN_FLOW_NODE_TYPE | "">(
    KAN_FLOW_NODE_TYPE.General
  );
  const [typeConfig, setTypeConfig] = useState<any>(KANBAN_WORKFLOW_GENERAL_NODE_CONFIG);

  const redirectPathName = `${ROUTES_MAPPING["Organizer-Workflow Modeler"]}`;

  const nodeTypes = {
    initial: KanbanWorkflowInitialNode,
    [KAN_FLOW_NODE_TYPE.General]: KanbanWorkflowGeneralNode,
  };

  const handleOnTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as KAN_FLOW_NODE_TYPE;
    switch (val) {
      case KAN_FLOW_NODE_TYPE.General: {
        setTypeConfig(KANBAN_WORKFLOW_GENERAL_NODE_CONFIG);
        break;
      }
    }
    setSelectedType(val);
  };

  const getQueryWorkflow = async () => {
    try {
      const res: GetQueryReactWorkflowSnippet = await callApi({
        query: getQueryKanbanWorkflow(id!),
        auth: { setAuthedUser },
      });

      return res;
    } catch (err) {
      console.error("error - fetch workflow", err);
    }
  };

  const saveReactWorkflow = async (workflow: ReactFlowMainType) => {
    const input = {
      data: { ...workflow, updatedOn: new Date().toISOString() },
    };

    await callApi({
      query: putQueryKanbanWorkflow(input, workflow.id),
      auth: { setAuthedUser },
    });
  };

  const deleteQueryWorkflow = async (workflowId: string) => {
    await callApi({
      query: deleteQueryKanbanWorkflow(workflowId),
      auth: { setAuthedUser },
    });
  };

  return (
    <ReactFlowMain
      getQueryWorkflow={getQueryWorkflow}
      saveReactWorkflow={saveReactWorkflow}
      deleteReactWorkflow={deleteQueryWorkflow}
      redirectPathName={redirectPathName}
      nodeTypes={nodeTypes}
      selectedType={selectedType}
      typeConfig={typeConfig}
      renderForm={renderForm}
      handleOnTypeChange={handleOnTypeChange}
    />
  );
};

export default WorkflowSinglePage;

const renderForm: RenderForm = ({
  selectedType,
  handleCreateSubmit,
  nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  switch (selectedType) {
    case KAN_FLOW_NODE_TYPE.General:
      return (
        <NodeGeneralForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              General: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    default:
      return null;
  }
};
