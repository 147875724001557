import { Query } from "../callApi";

export const postQueryDataManagerCreateWorkflow = (name: string, description: string, workflow_data: any): Query => ({
    endpoint: "/data_manager/container_creation/workflow",
    method: "POST",
    variables: {
      name: name,
      description: description,
      workflow_data: workflow_data
    }
  });

export const postQueryDataManagerSaveWorkflow = (name: string, description: string, workflow_id: string): Query => ({
    endpoint: "/data_manager/container_creation/save_workflow",
    method: "POST",
    variables: {
      name: name,
      description: description,
      workflow_id: workflow_id
    }
  });