import { format, subDays, startOfWeek, parseISO } from "date-fns";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { getChartColors } from "../../ExcellenceWidgets/nivoTheme";
import { BasicTableColumnCell, BasicTableData, BasicTableRow } from "../../MaterialUI/BasicTable/basicTableUtils";


export type MessageType = {
  timeLength: string;
  durationInSeconds?: number;
  station: string;
};

export type MessagesType = {
  [key: string]: MessageType;
};

export type MachineStatusDataType = {
  timeReport: string;
  messages: MessagesType;
};

export type MachineStatusHistoryData = {
  [machineId: string]: MachineStatusDataType[];
};

export function getMachineSelectOptions(data: MachineStatusHistoryData) {
  return Object.keys(data);
}

type timeRangesScheme = "Today" | "Yesterday" | "This Week";
const timeRangesSchemeArray: timeRangesScheme[] = ["Today", "Yesterday", "This Week"];
export const timeRangesSchemeOptions: SelectOption[] = handleGetSelectOption(
  timeRangesSchemeArray,
  true
);

export const fullMessagesNames: Record<string, string> = {
  All: "All",
  AUTO: "Automatic",
  HA: "Hand",
  AB: "Autlet Occupied",
  TB: "Part Machining",
};

type MessageColorMapping = {
  [key: string]: string;
};

const colorPalette = getChartColors();
const messageTypes = Object.keys(fullMessagesNames).filter((key) => key !== "All");

const messageColorMapping: MessageColorMapping = messageTypes.reduce(
  (acc, message, index) => {
    acc[message] = colorPalette[index % colorPalette.length];
    return acc;
  },
  {} as MessageColorMapping
);

export const getMessageColor = (messageType: string): string => {
  return messageColorMapping[messageType] || "rgba(0, 0, 0, 0)";
};

export const machineMessagesSchemeOptions: SelectOption[] = handleGetSelectOption(
  Object.keys(fullMessagesNames),
  true
);

export const getMachineMessageFullName = (messageType: string): string => {
  return fullMessagesNames[messageType] || messageType;
};

export const parseSecondsToHMS = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m, s].map((v) => String(v).padStart(2, "0")).join(":");
};

export const parseTimeToSeconds = (timeStr: string): number => {
  const [hours, minutes, seconds] = timeStr.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const calculateMachineStatusDurationInSeconds = (
  data: MachineStatusDataType[]
): MachineStatusDataType[] => {
  return data.map((segment) => {
    const updatedMessages: MessagesType = Object.fromEntries(
      Object.entries(segment.messages).map(([key, message]) => [
        key,
        {
          ...message,
          durationInSeconds: parseTimeToSeconds(message.timeLength),
        },
      ])
    );

    return {
      ...segment,
      messages: updatedMessages,
    };
  });
};

export type MachineStatusChartData = {
  id: string;
  [key: string]: number | string;
};

export const transformMachineStatusChartData = (
  data: MachineStatusDataType[],
  machineId: string
): MachineStatusChartData[] => {
  const aggregatedData: { [key: string]: { [key: string]: number } } = {};

  data.forEach((item) => {
    const timeReport = item.timeReport;
    Object.keys(item.messages).forEach((key) => {
      const durationInSeconds = item.messages[key].durationInSeconds;
      if (durationInSeconds !== undefined) {
        if (!aggregatedData[key]) {
          aggregatedData[key] = {};
        }
        aggregatedData[key][timeReport] =
          (aggregatedData[key][timeReport] || 0) + durationInSeconds;
      }
    });
  });

  const result: MachineStatusChartData = { id: machineId };
  Object.keys(aggregatedData).forEach((key) => {
    Object.keys(aggregatedData[key]).forEach((timeReport) => {
      result[`${timeReport}-${key}`] = aggregatedData[key][timeReport];
    });
  });

  return [result];
};

export type OperatingConditionChartData = {
  id: string;
  duration?: number;
  [key: string]: any;
};

export const transformOperatingConditionChartData = (
  data: MachineStatusDataType[]
): OperatingConditionChartData[] => {
  const transformedData: OperatingConditionChartData[] = [];
  const totalDurations: { [key: string]: number } = {};

  data.forEach((status) => {
    const messages = status.messages;
    Object.keys(messages).forEach((key) => {
      const duration = parseTimeToSeconds(messages[key].timeLength);
      if (!totalDurations[key]) {
        totalDurations[key] = 0;
      }
      totalDurations[key] += duration;

      const existingEntry = transformedData.find((entry) => entry.id === key);
      if (existingEntry) {
        existingEntry.duration = (existingEntry.duration || 0) + duration;
      } else {
        transformedData.push({
          id: key,
          duration,
        });
      }
    });
  });

  const totalEntry: OperatingConditionChartData = { id: "Total" };
  const sortedKeys = Object.keys(totalDurations).sort(
    (a, b) => totalDurations[b] - totalDurations[a]
  );

  sortedKeys.forEach((key) => {
    totalEntry[key] = totalDurations[key];
  });

  transformedData.push(totalEntry);

  transformedData.sort((a, b) => {
    if (b.id === "Total") return -1;
    if (a.id === "Total") return 1;
    return (a.duration || 0) - (b.duration || 0);
  });

  return transformedData;
};

const DATE_TYPE = {
  CURRENT: "current",
  YESTERDAY: "yesterday",
  THIS_WEEK: "thisWeek",
};

type DateType = (typeof DATE_TYPE)[keyof typeof DATE_TYPE];

const dateCalculators: { [key in DateType]: () => Date } = {
  current: () => new Date(),
  yesterday: () => subDays(new Date(), 1),
  thisWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
};

const getFormattedDate = (date: Date) => format(date, "MM.dd");

export const formatMachineStatusChartDate = (() => {
  const counter: { [key in DateType]: number } = {
    current: 0,
    yesterday: 0,
    thisWeek: 0,
  };
  const incrementSeconds = 30 * 60;

  return (type: DateType): string => {
    const dateCalculator = dateCalculators[type];
    const date = dateCalculator();
    date.setSeconds(date.getSeconds() + counter[type] * incrementSeconds);
    counter[type]++;
    return format(date, "MM.dd HH:mm:ss");
  };
})();

export const extractUniqueTimePoints = (data: MachineStatusChartData[]): string[] => {
  const timePoints = data
    .map((item) => {
      const datePart = item.id.split("-").slice(0, 2).join(" ");
      const parsedDate = parseISO(datePart);

      if (isNaN(parsedDate.getTime())) {
        console.error(`Invalid date: ${datePart}`);
        return null;
      }

      return parsedDate;
    })
    .filter((date) => date !== null) as Date[];

  if (timePoints.length === 0) {
    console.error("No valid time points found.");
  }

  const uniqueTimePoints = Array.from(new Set(timePoints)).sort(
    (a, b) => a.getTime() - b.getTime()
  );

  return uniqueTimePoints.map((time) => format(time, "MM.dd HH:mm:ss"));
};

export const filterDataByTimeRange = (
  data: MachineStatusDataType[],
  timeRange: string
): MachineStatusDataType[] => {
  const today = getFormattedDate(dateCalculators.current());
  const yesterday = getFormattedDate(dateCalculators.yesterday());
  const startOfThisWeek = getFormattedDate(dateCalculators.thisWeek());

  return data.filter((item) => {
    const date = item.timeReport.split(" ")[0];
    if (timeRange === "Today") {
      return date === today;
    }
    if (timeRange === "Yesterday") {
      return date === yesterday;
    }
    if (timeRange === "This Week") {
      return date >= startOfThisWeek && date <= today;
    }
    return true;
  });
};

export const filterDataByMessage = (
  data: MachineStatusDataType[],
  selectedMessage: string
): MachineStatusDataType[] => {
  return data
    .map((segment) => {
      if (selectedMessage === "All") {
        return segment;
      }
      const filteredMessages: MessagesType = segment.messages[selectedMessage]
        ? { [selectedMessage]: segment.messages[selectedMessage] }
        : {};
      return {
        ...segment,
        messages: filteredMessages,
      };
    })
    .filter((segment) => Object.keys(segment.messages).length > 0);
};

export const filterMachineStatusData = (
  data: MachineStatusHistoryData,
  selectedMachineId: string,
  selectedTimeRange: string,
  selectedMessage: string
): MachineStatusDataType[] => {
  let filteredData = data[selectedMachineId] || [];
  filteredData = filterDataByTimeRange(filteredData, selectedTimeRange);
  filteredData = filterDataByMessage(filteredData, selectedMessage);
  return filteredData;
};

export const MachineStatusTableColumns: BasicTableColumnCell[] = [
  { id: "timeReport", label: "Time Report" },
  { id: "timeLength", label: "Time Length" },
  { id: "station", label: "Station" },
  { id: "message", label: "Message" },
];

export const getMachineStatusTableData = (
  filteredData: MachineStatusDataType[]
): BasicTableData<BasicTableRow> => {
  const rows: BasicTableRow[] = [];

  filteredData.forEach((status) => {
    Object.keys(status.messages).forEach((messageKey) => {
      rows.push({
        timeReport: status.timeReport,
        timeLength: status.messages[messageKey].timeLength,
        station: status.messages[messageKey].station,
        message: messageKey,
      });
    });
  });

  return {
    rows,
    columns: MachineStatusTableColumns,
  };
};
