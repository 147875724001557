import {
  Box, 
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useTheme
} from "@mui/material";
import {
  DATA_MANAGER_FLOW_NODE_TYPE,
  WorkflowsArithmeticNodeData,
} from "../../../DataFlow/workflowUtils";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses, SelectOption } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import Select from "../../../../../../Components/MaterialUI/FormFields/Select";

interface NodeArithmeticFormProps {
  handleCreateSubmit?: (data: WorkflowsArithmeticNodeData) => void;
  handleEditSubmit?: (data: WorkflowsArithmeticNodeData) => void;
  data?: WorkflowsArithmeticNodeData;
  nodes: Node<WorkflowsArithmeticNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
  columnOptions: SelectOption[];
}

const operationOptions: SelectOption[] = [
  { value: "add", description: "Add" },
  { value: "subtract", description: "Subtract" },
  { value: "multiply", description: "Multiply" },
  { value: "divide", description: "Divide" },
];

const NodeArithmeticForm: React.FC<NodeArithmeticFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  //nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  columnOptions,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Arithmetic");
  const [leftColumn, setLeftColumn] = useState<string>(data?.left_column || "");
  const [rightColumn, setRightColumn] = useState<string>(data?.right_column || "");
  const [operation, setOperation] = useState<string>(data?.operation || "add");
  const [resultColumn, setResultColumn] = useState<string>(data?.result_column || "");
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic;

  const initialFormState = useRef({
    name,
    leftColumn,
    rightColumn,
    operation,
    resultColumn
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, leftColumn, rightColumn, operation, resultColumn },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    if (!leftColumn) {
      setAlertMessage(t("Left column is required"));
      setFormStatus("warning");
      return;
    }

    if (!rightColumn) {
      setAlertMessage(t("Right column is required"));
      setFormStatus("warning");
      return;
    }

    if (!operation) {
      setAlertMessage(t("Operation is required"));
      setFormStatus("warning");
      return;
    }

    const current = new Date().toISOString();

    const result: WorkflowsArithmeticNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      left_column: leftColumn,
      right_column: rightColumn,
      operation: operation as 'add' | 'subtract' | 'multiply' | 'divide',
      result_column: resultColumn || undefined,
      createdOn: data?.createdOn || current,
      updatedOn: current,
      container_id: data?.container_id || "",
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Arithmetic Configuration")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                selectOptions={columnOptions}
                fullWidth
                label={t("Left Column")}
                value={leftColumn}
                onChange={(e) => setLeftColumn(e.target.value)}
                required
                error={formStatus === "warning" && !leftColumn}
                helperText={formStatus === "warning" && !leftColumn ? t("Left column is required") : ""}
                disabled={!columnOptions.length}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                selectOptions={columnOptions}
                fullWidth
                label={t("Right Column")}
                value={rightColumn}
                onChange={(e) => setRightColumn(e.target.value)}
                required
                error={formStatus === "warning" && !rightColumn}
                helperText={formStatus === "warning" && !rightColumn ? t("Right column is required") : ""}
                disabled={!columnOptions.length}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                selectOptions={operationOptions}
                fullWidth
                label={t("Operation")}
                value={operation}
                onChange={(e) => setOperation(e.target.value)}
                required
                error={formStatus === "warning" && !operation}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="resultColumn"
                label={t("Result Column (optional)")}
                onChange={(e) => setResultColumn(e.target.value)}
                value={resultColumn}
                placeholder="result"
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button 
          css={[styles.width100, styles.widthLimit20]} 
          onClick={handleFormSubmit}
        >
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeArithmeticForm; 