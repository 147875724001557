import { Box, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { WorkflowsFilterNodeData } from "../../workflowUtils";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";

interface NodeFilterDataModalProps {
  data: WorkflowsFilterNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeFilterDataModal: React.FC<NodeFilterDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();
  
  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      
      <Box component="div" sx={{ mt: 2 }}>
        <Typography variant="h4" align="center" color="textSecondary" gutterBottom>
          {t("Filter Expression")}
        </Typography>
        <Box 
          component="div"
          sx={{ 
            p: 2, 
            bgcolor: 'action.hover', 
            borderRadius: 1,
            fontFamily: 'monospace'
          }}
        >
          <Typography variant="body2">
            {data.filter_expression}
          </Typography>
        </Box>
      </Box>
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_Filter_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeFilterDataModal; 