import { useState, useEffect } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { KanbanBoardColumn } from "../../../Components/SmallComponents/KanbanBoard/kanbanBoardUtils";
import { GetQueryProcessWizzardTaskStatusesSnippet, KanbanTask } from "../../../Api/ProcessWizzard/apiProcessWizzardSnippets";
import { useLanguageContext } from "../../../context/LanguageContext";
import ViewKanbanTask from "./ViewKanbanTask";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { getQueryProcessWizzardTaskStatuses } from "../../../Api/ProcessWizzard/apiProcessWizzardGetQueries";

interface OrdersKanbanBoardProps {
  tasks: KanbanTask[];
  columns: KanbanBoardColumn[];
  updateTask: (orderId: string, taskId: string, status: string, workDescription: string, beforeImage: File | null, afterImage: File | null) => void;
}

const OrdersKanbanBoard: React.FC<OrdersKanbanBoardProps & { loading: boolean }> = ({ 
  tasks, 
  columns,
  updateTask,
  loading
}) => {
  const { t } = useLanguageContext();
  const [openedTask, setOpenedTask] = useState<KanbanTask | null>(null);
  const { setAuthedUser } = useAuthedContext();
  const [statuses, setStatuses] = useState<string[]>([]);

  useEffect(() => {
    const loadStatuses = async () => {
        const data = await callApi<GetQueryProcessWizzardTaskStatusesSnippet>({
            query: getQueryProcessWizzardTaskStatuses(),
            auth: { setAuthedUser },
        });
        setStatuses(data.statuses);
    };
    loadStatuses();
  }, []);
  
  return (
    <Stack spacing={2} alignItems="center" width="100%">
      {loading ? (
        <Typography component="p" variant="body1">
          {t("Loading...")}
        </Typography>
      ) : (
        <Box
          component="div"
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            overflowX: "auto",
            overflowY: "hidden",
            padding: "20px",
          }}
        >
          <Box
            component="div"
            style={{
              margin: "auto",
              display: "flex",
              gap: "1rem",
            }}
          >
            {columns.map((column) => (
              <ColumnContainer 
                key={column.id} 
                column={column} 
                tasks={tasks.filter(task => task.workplace_id === column.id)}
                onTaskClick={setOpenedTask}
              />
            ))}
          </Box>

          {openedTask && (
            <ViewKanbanTask task={openedTask} onClose={() => setOpenedTask(null)} updateTask={updateTask} statuses={statuses || []} editMode={true}/>
          )}
        </Box>
      )}
    </Stack>
  );
};

// Column container component
interface ColumnContainerProps {
  column: KanbanBoardColumn;
  tasks: KanbanTask[];
  onTaskClick: (task: KanbanTask) => void;
}

const ColumnContainer: React.FC<ColumnContainerProps> = ({ column, tasks, onTaskClick }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();

  return (
    <Box
      component="div"
      style={{
        width: "280px",
        height: "700px",
        maxHeight: "700px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.mode === "dark"
          ? theme.palette.customColors?.darkGray
          : theme.palette.grey[100],
        borderRadius: "0.375rem",
      }}
    >
      <Box
        component="div"
        style={{
          backgroundColor: theme.palette.mode === "dark"
            ? theme.palette.customColors?.darkGray
            : theme.palette.grey[100],
          height: "60px",
          borderRadius: "0.375rem",
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          padding: "0.75rem",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          component="div"
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Box
            component="div"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.palette.mode === "dark"
                ? theme.palette.customColors?.darkGray
                : theme.palette.grey[100],
              border: `1px solid ${
                theme.palette.mode === "dark"
                  ? theme.palette.grey[900]
                  : theme.palette.grey[400]
              }`,
              padding: "2.5px 10px",
              borderRadius: "100%",
            }}
          >
            <Typography variant="body2">{tasks.length}</Typography>
          </Box>
          <Typography>{column.title}</Typography>
        </Box>
      </Box>

      <Box
        component="div"
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
          padding: "0.5rem",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {tasks.length === 0 ? (
          <Typography 
            variant="body2" 
            style={{
              color: theme.palette.text.secondary,
              textAlign: "center",
              padding: "10px",
              fontStyle: "italic",
            }}
          >
            {t("No tasks")}
          </Typography>
        ) : (
          tasks.map((task) => (
            <TaskCard key={task.id} task={task} onClick={() => onTaskClick(task)} />
          ))
        )}
      </Box>
    </Box>
  );
};

// Task card component
interface TaskCardProps {
  task: KanbanTask;
  onClick: () => void;
}

const TaskCard: React.FC<TaskCardProps> = ({ task, onClick }) => {
  const theme = useTheme();
  
  return (
    <Box
      component="div"
      onClick={onClick}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.common.white,
        padding: "0.625rem",
        height: "130px",
        minHeight: "130px",
        alignItems: "center",
        display: "flex",
        textAlign: "left",
        borderRadius: "1rem",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Stack
        style={{
          margin: "auto",
          width: "100%",
          maxHeight: "95%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        spacing={0.5}
        justifyContent="space-between"
        height="100%"
      >
        <Typography variant="body2" color="text.secondary" sx={{ height: '20px' }}>
          {task.order_number || "No order number"}
        </Typography>
        <Typography 
          whiteSpace="pre-wrap" 
          fontWeight="bold" 
          sx={{ height: '40px', display: 'flex', alignItems: 'center'}}
        >
          {task.description || "#" + task.id.substring(0, 8)}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ height: '20px' }}>
          {task.status || "No status"}
        </Typography>
      </Stack>
    </Box>
  );
};

export default OrdersKanbanBoard; 