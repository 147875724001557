import { Grid } from "@mui/material";
import {
  machineMessagesSchemeOptions,
  timeRangesSchemeOptions,
} from "./machineStatusUtils";
import ContentBox from "../../MaterialUI/ContentBox";
import { SelectOption } from "../../../Global/Types/commonTypes";
import Select from "../../MaterialUI/FormFields/Select";

interface MachineStatusFiltersProps {
  selectedMachineId: string;
  setSelectedMachineId: React.Dispatch<React.SetStateAction<string>>;
  machineIdSelectOptions: SelectOption[];
  selectedTimeRange: string;
  setSelectedTimeRange: React.Dispatch<React.SetStateAction<string>>;
  selectedMachineMessage: string;
  setSelectedMachineMessage: React.Dispatch<React.SetStateAction<string>>;
}

const MachineStatusFilters: React.FC<MachineStatusFiltersProps> = ({
  selectedMachineId,
  setSelectedMachineId,
  machineIdSelectOptions,
  selectedTimeRange,
  setSelectedTimeRange,
  selectedMachineMessage,
  setSelectedMachineMessage,
}) => {
  return (
    <ContentBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Select
            selectOptions={machineIdSelectOptions}
            name="machine"
            label="Machine"
            onChange={(e) => setSelectedMachineId(e.target.value)}
            value={selectedMachineId}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Select
            selectOptions={timeRangesSchemeOptions}
            name="timeRange"
            label="Time Range"
            onChange={(e) => setSelectedTimeRange(e.target.value)}
            value={selectedTimeRange}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Select
            selectOptions={machineMessagesSchemeOptions}
            name="machineMessages"
            label="Messages"
            onChange={(e) => setSelectedMachineMessage(e.target.value)}
            value={selectedMachineMessage}
          />
        </Grid>
      </Grid>
    </ContentBox>
  );
};

export default MachineStatusFilters;
