import {
  MRT_ColumnDef,
  MRT_DensityState,
  MRT_FilterFn,
  MRT_TableState,
  MRT_VisibilityState,
} from "material-react-table";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import isWithinInterval from "date-fns/isWithinInterval";
import { SerializedStyles } from "@emotion/react";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import { TableAggregationFns, TableAggregationKey, TableGridColumnSchema } from "./constructTableGrid";
import { camelCaseToTitleCase } from "../../../Global/Utils/commonFunctions";

export type TableGridDateTimeFilterModeOptions = "Before" | "After" | "Between dates";
export type TableGridDateTimeFilterValue = {
  filterMode: TableGridDateTimeFilterModeOptions;
  firstDate: Date | null;
  secondDate: Date | null;
};
export type TableGridDateType = "date" | "time" | "dateTime" | "datetime";
export type TableGridColumnDataTypes =
  | "string"
  | "number"
  | "boolean"
  | "button"
  | "dropdown"
  | TableGridDateType;
export type TableGridColAlign = "left" | "right" | "center";
export type TableGridColSymbol = {
  align: Omit<TableGridColAlign, "center">;
  value: string;
};

export type TableGridHandleSaveCellChanges = (
  rowIndex: number,
  colKey: string,
  value: any
) => void;
export interface TableGridProps<T extends Record<string, any>> {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  rows: T[];
  columns: MRT_ColumnDef<T>[];
  editMode?: TableGridPropsEditMode;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  configuration?: TableGridConfiguration;
  isStatic?: boolean;
  onRowClick?: (row: T) => void;
  loading?: boolean;
  onSave: (state: MRT_TableState<T>) => void;
  openConfigModal: () => void;
  tableState: MRT_TableState<T> | null;
  loadingConfigState: FormStatuses;
  hideConfigButton: boolean;
  virtualizedColsNumber?: number;
  showAddFromCatalog?: boolean;
  handleAddFromCatalog?: (row: T) => void;
  handleRecalcAggregationsOnSearch: (rows: T[]) => void;
}

export type TableGridPropsEditMode = {
  handleAddFromCatalog?: (row: Record<string, any>) => void;
  handleDeleteRow: (rowIndex: number) => void;
  handleAddNewRow: () => void;
  handleSaveCellChanges: TableGridHandleSaveCellChanges;
};

export type TableGridConfiguration = {
  grouping?: string[]; //done
  density?: MRT_DensityState; //done
  hideAddButton?: boolean; //done
  hideDeleteRowButton?: boolean; //done
  expanded?: boolean | undefined; //done
  columnsConfig?: {
    columnSizing?: {
      [key: string]: number; //done
    };
    columnVisibility?: {
      [key: string]: boolean; //done
    };
  };
  layoutConfig?: {
    density?: MRT_DensityState; //done
    enableStickyHeader?: boolean; //done
    maxTableHeight?: number; //done
    isFullScreen?: boolean;
    activeColumnFilters?: boolean;
    activeGlobalFilter?: boolean;
    showToolbarDropZone?: boolean; //done
  };
  tableButtons?: {
    enableCustomConfig?: boolean; //done
    enableGlobalFilter?: boolean; //done
    enableColumnFilters?: boolean; //done
    enableColumnSettings?: boolean; //done
    enableDensity?: boolean; //done
    enableFullScreen?: boolean; //done
    enableRowPinning?: boolean; //done
  };
  hideMobilePagination?: boolean; //done
  hideMobileSearch?: boolean; //done
  disableHeader?: boolean; //done
  disablePagination?: boolean; //done
  initialRowsPerPage?: number; //done
  columnVisibility?: MRT_VisibilityState; //done
  enableStickyHeader?: boolean;
  maxTableHeight?: number;
  columnPinning?: {
    left: string[];
    right: string[];
  };
  pinnedRows?: {
    top: string[];
    bottom: string[];
  };
  sorting?: [
    {
      id: string;
      desc: boolean;
    }
  ]
};

export const tableGridDateFilterFunc: MRT_FilterFn<Record<string, any>> = (
  row,
  id,
  filterValue
) => {
  const { filterMode, firstDate, secondDate } = filterValue;
  const rowDate: Date | null =
    row.getValue(id) && isValid(new Date(row.getValue(id)))
      ? new Date(row.getValue(id))
      : null;

  let result: boolean = true;

  switch (filterMode) {
    case "Before": {
      if (firstDate && isValid(firstDate) && rowDate) {
        result = isBefore(rowDate, firstDate);
        break;
      }
      result = true;
      break;
    }
    case "After": {
      if (firstDate && isValid(firstDate) && rowDate) {
        result = isBefore(firstDate, rowDate);
        break;
      }
      result = true;
      break;
    }
    case "Between": {
      if (
        firstDate &&
        isValid(firstDate) &&
        secondDate &&
        isValid(secondDate) &&
        rowDate
      ) {
        result = isWithinInterval(rowDate, {
          start: firstDate,
          end: secondDate,
        });
        break;
      }
      result = true;
      break;
    }
    default: {
      result = true;
      break;
    }
  }

  return result;
};

export const tableGridAddNewRowData = <T extends Record<string, any>>(
  column: MRT_ColumnDef<T>
) => {
  const key = (column.accessorKey || column.id) as string;
  // @ts-ignore
  const colType = column.meta?.type as string;
  let val: string | boolean | null = "";

  switch (colType) {
    case "date": {
      val = null;
      break;
    }
    case "dateTime": {
      val = null;
      break;
    }
    case "boolean": {
      val = false;
      break;
    }
    default: {
      val = "";
      break;
    }
  }

  return {
    [key]: val,
  };
};

export const toCamelCase = (str: string): string => {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );
};

export const transformToCamelCase = <T extends Record<string, any>>(obj: T): T => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(v => transformToCamelCase(v)) as any;
  }

  return Object.keys(obj).reduce((result, key) => {
    const camelKey = toCamelCase(key);
    const value = obj[key];
    result[camelKey as keyof T] = transformToCamelCase(value);
    return result;
  }, {} as T);
};


export const tableAggregationOptions: SelectOption<TableAggregationKey>[] = Object.values(
  TableAggregationFns
).map((ope) => ({
  value: ope as TableAggregationKey,
  description: camelCaseToTitleCase(ope),
}));

export const getDeepestColumns = (columns: TableGridColumnSchema[]): TableGridColumnSchema[] => {
  let deepestColumns: TableGridColumnSchema[] = [];

  const findDeepestColumns = (cols: TableGridColumnSchema[]) => {
    cols.forEach(col => {
      if (col.columns && col.columns.length > 0) {
        findDeepestColumns(col.columns);
      } else {
        deepestColumns.push(col);
      }
    });
  };

  findDeepestColumns(columns);
  return deepestColumns;
};

export const updateDeepestColumns = (cols: TableGridColumnSchema[], deepestColumnsArray: TableGridColumnSchema[]) => {
  let currentIndex = 0;
  
  const updateColumns = (columns: TableGridColumnSchema[]): void => {
    columns.forEach(col => {
      if (col.columns && col.columns.length > 0) {
        updateColumns(col.columns);
      } else {
        // Update the leaf column with the corresponding column from deepestColumnsArray
        if (currentIndex < deepestColumnsArray.length) {
          Object.assign(col, deepestColumnsArray[currentIndex]);
          currentIndex++;
        }
      }
    });
  };

  updateColumns(cols);
  return cols;
};



