import { useEffect, useState } from "react";
import { useAuthedContext } from "../../../../context/AuthContext";
import callApi from "../../../../Api/callApi";
import { getQueryAccountsTree } from "./API/apiAccountsTreeGetQueries";
import CollapsibleSelectTree from "../../../../Components/SmallComponents/CollapsibleSelectTree/CollapsibleSelectTree";
import { CollapsibleTreeItemData } from "../../../../Components/SmallComponents/CollapsibleSelectTree/collapsibleTreeTypes";

interface AccountNode {
    id: string;
    account_name: string;
    account_group?: string;
    account_number?: string;
    children?: AccountNode[];
}

const transformAccountsToTree = (account: AccountNode): CollapsibleTreeItemData => {
  return {
    id: account.id,
    uniqueID: account.id,
    label: `${account.account_group ? account.account_group + " - " : ""}${account.account_name}`,
    children: account.children && Array.isArray(account.children) && account.children.length > 0 
      ? account.children.map((child) => ({
        ...transformAccountsToTree(child),
        label: `${child.account_number ? child.account_number + " - " : ""}${child.account_name}`
      })) 
      : undefined,
  };
};

const AccountsTreePage = () => {
  const [treeData, setTreeData] = useState<CollapsibleTreeItemData[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const { setAuthedUser } = useAuthedContext();


  useEffect(() => {
    const fetchAccountsTree = async () => {
      try {
        const response = await callApi({ query: getQueryAccountsTree(), auth: { setAuthedUser } });
        const accountsData: AccountNode[] = Array.isArray(response) ? response : [];
        const formattedTree = accountsData.map(transformAccountsToTree);
        setTreeData(formattedTree);
      } catch (error) {
        console.error("Error fetching account tree:", error);
      }
    };

    fetchAccountsTree();
  }, [setAuthedUser]);

  return (
       <CollapsibleSelectTree selected={selectedNodes} setSelected={setSelectedNodes} data={treeData} />

  );
};

export default AccountsTreePage;
