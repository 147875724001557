import React from "react";
import { Typography, useTheme } from "@mui/material";
import { getMachineStatusTableData } from "./machineStatusUtils";
import { filterMachineStatusData } from "./machineStatusUtils";
import { machineStatusHistoryDefaultData } from "./machineStatusData";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import ContentBox from "../../MaterialUI/ContentBox";
import BasicTable from "../../MaterialUI/BasicTable/BasicTable";

interface MachineStatusHistoryTableProps {
  selectedMachineId: string;
  selectedTimeRange: string;
  selectedMachineMessage: string;
}

const MachineStatusHistoryTable: React.FC<MachineStatusHistoryTableProps> = ({
  selectedMachineId,
  selectedTimeRange,
  selectedMachineMessage,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };

  const filteredData = filterMachineStatusData(
    machineStatusHistoryDefaultData,
    selectedMachineId,
    selectedTimeRange,
    selectedMachineMessage
  );
  const tableData = getMachineStatusTableData(filteredData);

  return (
    <ContentBox>
      <Typography css={styles.leftSpacer2} variant="h5" color="textSecondary" mb={0.5}>
        Machine {selectedMachineId}
      </Typography>
      <Typography css={styles.leftSpacer2} variant="h3" mb={2}>
        Machine Status History
      </Typography>

      <BasicTable
        data={tableData}
        dense={true}
        defaultOrderBy="timeReport"
        pageRows={5}
        emptyTableMessage={"There is no machine status history yet."}
        noPagination={false}
      />
    </ContentBox>
  );
};

export default MachineStatusHistoryTable;
