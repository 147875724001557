import { useParams } from "react-router-dom";
import { useAuthedContext } from "../../context/AuthContext";
import {
  GetQueryReactWorkflowSnippet,
  ReactFlowMainType,
  RenderForm,
} from "../../Components/SmallComponents/ReactFlowComponents/reactFlowUtils";
import callApi from "../../Api/callApi";
import ReactFlowMain from "../../Components/SmallComponents/ReactFlowComponents/ReactFlowMain";
import {
  deleteQueryBoschProcess,
  putQueryBoschProcess,
} from "../../Api/BoschWorkflows/apiBoschWorkflowsPostQueries";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import { getQueryBoschProcess } from "../../Api/BoschWorkflows/apiBoschWorkflowsGetQueries";
import FlowInitialNode from "../../Components/SmallComponents/ReactFlow/FlowNodes/Nodes/FlowInitialNode";
import {
  FLOW_NODE_TYPE,
  FLOW_TEXT_NODE_CONFIG,
} from "../../Components/SmallComponents/ReactFlow/FlowNodes/flowNodesTypes";
import FlowTextNode from "../../Components/SmallComponents/ReactFlow/FlowNodes/Nodes/FlowTextNode";
import FlowEnhancedNode from "../../Components/SmallComponents/ReactFlow/FlowNodes/Nodes/FlowEnhancedNode";
import { useState } from "react";
import FlowTextForm from "../../Components/SmallComponents/ReactFlow/FlowNodes/Forms/FlowTextForm";

const ProcessPage: React.FC = () => {
  const { id } = useParams();
  const { setAuthedUser } = useAuthedContext();
  const [typeConfig] = useState<any>(FLOW_TEXT_NODE_CONFIG);

  const redirectPathName = `${ROUTES_MAPPING["Forms and Flows-Workflows"]}`;

  const nodeTypes = {
    initial: FlowInitialNode,
    [FLOW_NODE_TYPE.Text]: FlowTextNode,
    [FLOW_NODE_TYPE.Enhanced]: FlowEnhancedNode,
  };

  const getQueryWorkflow = async () => {
    try {
      const res: GetQueryReactWorkflowSnippet = await callApi({
        query: getQueryBoschProcess(id!),
        auth: { setAuthedUser },
      });

      return res;
    } catch (err) {
      console.error("error - fetch workflow", err);
    }
  };

  const saveReactWorkflow = async (workflow: ReactFlowMainType) => {
    const input = {
      data: { ...workflow, updatedOn: new Date().toISOString() },
    };

    await callApi({
      query: putQueryBoschProcess(input, workflow.id),
      auth: { setAuthedUser },
    });
  };

  const deleteQueryWorkflow = async (workflowId: string) => {
    await callApi({
      query: deleteQueryBoschProcess(workflowId),
      auth: { setAuthedUser },
    });
  };

  return (
    <ReactFlowMain
      getQueryWorkflow={getQueryWorkflow}
      saveReactWorkflow={saveReactWorkflow}
      deleteReactWorkflow={deleteQueryWorkflow}
      redirectPathName={redirectPathName}
      nodeTypes={nodeTypes}
      typeConfig={typeConfig}
      renderForm={renderForm}
    />
  );
};

export default ProcessPage;

const renderForm: RenderForm = ({
  handleCreateSubmit,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  return (
    <FlowTextForm
      handleCreateSubmit={handleCreateSubmit}
      handleSetUnsavedChanges={handleSetUnsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    />
  );
};
