import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetQueryReactWorkflowSnippet,
  PutQueryPidReactWorkflowInput,
  ReactFlowMainType,
  RenderForm,
  WorkflowViewMode,
} from "../../Components/SmallComponents/ReactFlowComponents/reactFlowUtils";
import callApi from "../../Api/callApi";
import { getQueryPidSingleWorkflow } from "../../Api/PidWorkflows/apiPidWorkflowsGetQueries";
import { useAuthedContext } from "../../context/AuthContext";
import ReactFlowMain from "../../Components/SmallComponents/ReactFlowComponents/ReactFlowMain";
import { PidWorkflowDataFieldInput } from "../../Api/PidWorkflows/apiPidWorkflowsInputs";
import {
  PID_WORKFLOW_INPUT_NODE_CONFIG,
  PidWorkflowsInputNodeData,
} from "./Components/PidWorkflow/Nodes/Input/pidInputNodeUtils";
import {
  deleteQueryPidWorkflow,
  putQueryPidWorkflow,
} from "../../Api/PidWorkflows/apiPidWorkflowsPostQueries";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import {
  PID_FLOW_NODE_TYPE,
  PidFlowNodeTypeKey,
} from "./Components/PidWorkflow/Nodes/pidNodesUtils";
import PidWorkflowInitialNode from "./Components/PidWorkflow/Nodes/Initial/PidWorkflowInitialNode";
import PidWorkflowTextNode from "./Components/PidWorkflow/Nodes/Text/PidWorkflowTextNode";
import PidWorkflowSvgNode from "./Components/PidWorkflow/Nodes/Svg/PidWorkflowSvgNode";
import PidWorkflowInputNode from "./Components/PidWorkflow/Nodes/Input/PidWorkflowInputNode";
import PidWorkflowBackgroundNode from "./Components/PidWorkflow/Nodes/Background/PidWorkflowBackgroundNode";
import PidNodeTextForm from "./Components/PidWorkflow/Nodes/Text/PidNodeTextForm";
import PidNodeSvgForm from "./Components/PidWorkflow/Nodes/Svg/PidNodeSvgForm";
import PidNodeInputForm from "./Components/PidWorkflow/Nodes/Input/PidNodeInputForm";
import PidNodeBackgroundForm from "./Components/PidWorkflow/Nodes/Background/PidNodeBackgroundForm";
import { PID_WORKFLOW_TEXT_NODE_CONFIG } from "./Components/PidWorkflow/Nodes/Text/pidTextNodeUtils";
import { PID_WORKFLOW_SVG_NODE_CONFIG } from "./Components/PidWorkflow/Nodes/Svg/pidSvgNodeUtils";
import { PID_WORKFLOW_BACKGROUND_NODE_CONFIG } from "./Components/PidWorkflow/Nodes/Background/pidBackgroundNodeUtils";
import { SelectOption } from "../../Global/Types/commonTypes";

const SinglePidChart: React.FC = () => {
  const [viewMode, setViewMode] = useState<WorkflowViewMode>("View Mode");
  const { id } = useParams();
  const { setAuthedUser } = useAuthedContext();
  const [selectedType, setSelectedType] = useState<PID_FLOW_NODE_TYPE | "">("");
  const [typeConfig, setTypeConfig] = useState<any>(null);

  const redirectPathName = `${ROUTES_MAPPING["Layouts-P&ID"]}`;

  const nodeTypes = {
    [PID_FLOW_NODE_TYPE.initial]: PidWorkflowInitialNode,
    [PID_FLOW_NODE_TYPE.Text]: PidWorkflowTextNode,
    [PID_FLOW_NODE_TYPE.SVG]: PidWorkflowSvgNode,
    [PID_FLOW_NODE_TYPE.Input]: PidWorkflowInputNode,
    [PID_FLOW_NODE_TYPE.Background]: PidWorkflowBackgroundNode,
  };

  const handleOnTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as PID_FLOW_NODE_TYPE;
    switch (val) {
      case PID_FLOW_NODE_TYPE.Text: {
        setTypeConfig(PID_WORKFLOW_TEXT_NODE_CONFIG);
        break;
      }
      case PID_FLOW_NODE_TYPE.Input: {
        setTypeConfig(PID_WORKFLOW_INPUT_NODE_CONFIG);
        break;
      }
      case PID_FLOW_NODE_TYPE.SVG: {
        setTypeConfig(PID_WORKFLOW_SVG_NODE_CONFIG);
        break;
      }
      case PID_FLOW_NODE_TYPE.Background: {
        setTypeConfig(PID_WORKFLOW_BACKGROUND_NODE_CONFIG);
        break;
      }
    }
    setSelectedType(val);
  };

  const getQueryWorkflow = async () => {
    try {
      const res: GetQueryReactWorkflowSnippet = await callApi({
        query: getQueryPidSingleWorkflow(id!),
        auth: { setAuthedUser },
      });

      return res;
    } catch (err) {
      console.error("error - fetch workflow", err);
    }
  };

  const saveReactWorkflow = async (workflow: ReactFlowMainType) => {
    const dataFields: PidWorkflowDataFieldInput[] = workflow.nodes
      .filter((node) => node.data.type === "Input")
      .map((item) => {
        const data = item.data as PidWorkflowsInputNodeData;
        return {
          container_id: data.dataField.container_id,
          node_id: data.dataField.node_id,
          parameter_id: data.dataField.parameter_id,
        };
      });

    const putInput: PutQueryPidReactWorkflowInput = {
      workflow,
      data_fields: dataFields,
    };

    await callApi({
      query: putQueryPidWorkflow(putInput, workflow.id),
      auth: { setAuthedUser },
    });
  };

  const deleteQueryWorkflow = async (workflowId: string) => {
    await callApi({
      query: deleteQueryPidWorkflow(workflowId),
      auth: { setAuthedUser },
    });
  };

  return (
    <ReactFlowMain
      viewMode={viewMode}
      setViewMode={setViewMode}
      getQueryWorkflow={getQueryWorkflow}
      saveReactWorkflow={saveReactWorkflow}
      deleteReactWorkflow={deleteQueryWorkflow}
      redirectPathName={redirectPathName}
      hasDetails={true}
      hasPlantArea={true}
      nodeTypes={nodeTypes}
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      typeConfig={typeConfig}
      renderForm={renderForm}
      handleOnTypeChange={handleOnTypeChange}
      nodeOptions={NODE_OPTIONS}
    />
  );
};

export default SinglePidChart;

const renderForm: RenderForm = ({
  selectedType,
  handleCreateSubmit,
  nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  switch (selectedType) {
    case PID_FLOW_NODE_TYPE.Text:
      return (
        <PidNodeTextForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Text: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    case PID_FLOW_NODE_TYPE.SVG:
      return (
        <PidNodeSvgForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              SVG: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    case PID_FLOW_NODE_TYPE.Input:
      return (
        <PidNodeInputForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Input: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    case PID_FLOW_NODE_TYPE.Background:
      return (
        <PidNodeBackgroundForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Background: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    default:
      return null;
  }
};

const NODE_OPTIONS: SelectOption<PidFlowNodeTypeKey>[] = [
  {
    value: "Text",
    description: "Label",
  },
  {
    value: "Input",
    description: "Process Parameter",
  },
  { value: "SVG", description: "P&ID Component" },
  { value: "Background", description: "Background" },
];
