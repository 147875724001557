import { Query } from "../callApi";

export const getQueryDataManagerNotifications = (): Query => ({
    endpoint: "/data_manager/notifications",
    method: "GET",
  });

export const getQueryDataManagerWorkflow = (id: string): Query => ({
  endpoint: `/data_manager/container_creation/workflow/${id}`,
  method: "GET",
});

export const getQueryDataManagerAllWorkflows = (): Query => ({
  endpoint: "/data_manager/container_creation/workflows",
  method: "GET",
});

export const getQueryDataManagerNodeTypes = (): Query => ({
  endpoint: "/data_manager/container_creation/node_types",
  method: "GET",
});

export const getQueryDataManagerRunWorkflow = (workflowId: string, nodeId: string): Query => ({
  endpoint: `/data_manager/container_creation/workflow/${workflowId}/run`,
  method: "GET",
  variables: {
    node_id: nodeId,
  },
});
