import { formatMachineStatusChartDate, MachineStatusHistoryData } from "./machineStatusUtils";


export const machineStatusHistoryDefaultData: MachineStatusHistoryData = {
  "105443": [
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AUTO: { timeLength: "00:02:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        HA: { timeLength: "00:00:31", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AB: { timeLength: "00:01:05", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        TB: { timeLength: "00:00:56", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AUTO: { timeLength: "00:02:01", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        HA: { timeLength: "00:00:13", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AB: { timeLength: "00:01:09", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        TB: { timeLength: "00:00:56", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        AUTO: { timeLength: "00:02:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        HA: { timeLength: "00:00:31", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        AUTO: { timeLength: "00:00:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        TB: { timeLength: "00:01:02", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("thisWeek"),
      messages: {
        AUTO: { timeLength: "00:02:03", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("thisWeek"),
      messages: {
        TB: { timeLength: "00:00:56", station: "M" },
      },
    },
  ],
  "025517": [
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AUTO: { timeLength: "00:02:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        HA: { timeLength: "00:00:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AB: { timeLength: "00:01:05", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        TB: { timeLength: "00:00:56", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AUTO: { timeLength: "00:02:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        HA: { timeLength: "00:00:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        AB: { timeLength: "00:01:05", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("current"),
      messages: {
        TB: { timeLength: "00:00:56", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        AUTO: { timeLength: "00:02:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        TB: { timeLength: "00:03:56", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        AUTO: { timeLength: "00:02:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        HA: { timeLength: "00:00:31", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("yesterday"),
      messages: {
        AUTO: { timeLength: "00:00:41", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("thisWeek"),
      messages: {
        AUTO: { timeLength: "00:01:31", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("thisWeek"),
      messages: {
        TB: { timeLength: "00:00:56", station: "M" },
      },
    },
    {
      timeReport: formatMachineStatusChartDate("thisWeek"),
      messages: {
        HA: { timeLength: "00:00:41", station: "M" },
      },
    },
  ],
};
