import { Query } from "../../../../Api/callApi";

export const getQueryAccountsTree = (
): Query => ({
  endpoint: `accounting/accounts`,
  method: "GET",
});

export const getQueryAccountingArticles = (): Query => ({
  endpoint: "accounting/accounting-article",
  method: "GET",
});


export const getQueryAccountingBalance = (): Query => ({
  endpoint: "accounting/balance",
  method: "GET",
});

export const getQueryAccountingTrialBalance = (): Query => ({
  endpoint: "accounting/trial-balance",
  method: "GET",
});

export const getQueryAccountingGeneralJournal  = (): Query => ({
  endpoint: "accounting/general-journal",
  method: "GET",
});

export const getQueryAccountingGeneralLedger  = (): Query => ({
  endpoint: "accounting/general-ledger",
  method: "GET",
});