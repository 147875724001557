import React, { useEffect, useState } from 'react';
import ResponsiveTableGrid from '../Components/SmallComponents/TableGrid/ResponsiveTableGrid';
import { Box } from '@mui/material';
import callApi, { Query } from '../Api/callApi';
import { useAuthedContext } from '../context/AuthContext';
import { convertObjectKeysToCamelCase } from '../Global/Utils/commonFunctions';

const getQueryTables = (): Query => ({
  endpoint: `/integrator/table`,
  method: "GET",
});

const TablesPage: React.FC = () => {
  const { setAuthedUser } = useAuthedContext();
  const [tableData, setTableData] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      const data = await callApi<any[]>({
        query: getQueryTables(),
        auth: { setAuthedUser },
      });
      setTableData(data);
    };
    fetchData();
  }, []);

  return (
    tableData && (
      <Box component="div">
        <ResponsiveTableGrid
          rows={tableData?.rows || []}
          colSchema={tableData?.columns || []}
          responsive="responsive"
          configuration={convertObjectKeysToCamelCase(tableData?.configuration || {})}
          tableID="TablesPage_table"
      />
      </Box>
    )
  );
};



export default TablesPage;