import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  KAN_FLOW_NODE_TYPE,
  KanbanWorkflowsGeneralNodeData,
  NodeCondition,
} from "../../../../../kanbanWorkflowUtils";
import cssLayoutStyles from "../../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../../Global/Styles/spacing";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../../Components/MaterialUI/Button";
import {
  FormStatuses,
  SelectOption,
} from "../../../../../../../Global/Types/commonTypes";
import callApi from "../../../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../../../context/AuthContext";
import Select from "../../../../../../../Components/MaterialUI/FormFields/Select";
import Alert from "../../../../../../../Components/MaterialUI/Alert";
import {
  GetQueryKanbanTaskConditionsSnippet,
  GetQueryKanbanTaskTemplatesSnippet,
  getQueryKanbanTaskConditions,
  getQueryKanbanTaskTemplates,
} from "../../../../../api/queries";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../../../../../../../Components/MaterialUI/Modal";
import AddKanTaskTemplate from "../../../../../../TaskManager/Components/KanbanTaskTemplate/AddKanTaskTemplate";
import { EMPTY_KANBAN_TASK_TEMPLATE } from "../../../../../../TaskManager/Components/taskManagerUtils";
import Autocomplete from "../../../../../../../Components/MaterialUI/FormFields/Autocomplete";
import { Node } from "reactflow";
import LabelWithBoldedPart from "../../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useDetectFormsUnsavedChanges } from "../../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../../../../../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Add New Template": "Add New Template",
  "Condition Details": "Condition Details",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface NodeGeneralFormProps {
  handleCreateSubmit?: (data: KanbanWorkflowsGeneralNodeData) => void;
  handleEditSubmit?: (data: KanbanWorkflowsGeneralNodeData) => void;
  data?: KanbanWorkflowsGeneralNodeData;
  nodes: Node<KanbanWorkflowsGeneralNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const NodeGeneralForm: React.FC<NodeGeneralFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "");
  const [formStatus, setFormStatus] = useState<FormStatuses>("loading");
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [allTemplates, setAllTemplates] = useState<GetQueryKanbanTaskTemplatesSnippet>(
    []
  );
  const [templateOptions, setTemplateOptions] = useState<SelectOption[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>(
    data?.template_id || ""
  );
  const [selectedTemplateAttrs, setSelectedTemplateAttrs] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [conditionMapping, setConditionMapping] = useState<Record<string, NodeCondition>>(
    {}
  );
  const [conditionOptions, setConditionOptions] = useState<SelectOption[]>([]);
  const [preConditions, setPreConditions] = useState<SelectOption[]>(
    getConditionOptions(data?.preConditions || [])
  );
  const [postConditions, setPostConditions] = useState<SelectOption[]>(
    getConditionOptions(data?.postConditions || [])
  );

  const { setAuthedUser } = useAuthedContext();

  const nodeType = KAN_FLOW_NODE_TYPE.General;

  const initialFormState = useRef({
    name,
    selectedTemplate,
    preConditions,
    postConditions,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, selectedTemplate, preConditions, postConditions },
    handleSetUnsavedChanges
  );

  useEffect(() => {
    (async () => {
      await handleFetchTemplates();
      await handleFetchConditions();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      const found = allTemplates.find((item) => item.id === selectedTemplate);
      const active = found?.versions.find((item) => item.status === "active");
      if (active && found) {
        const attrs = active.data_fields.map((item) => item.data_fields.name);
        const duplicates: number[] = [];
        nodes.forEach((item) => {
          if (item.data.template_id === selectedTemplate) {
            duplicates.push(1);
          }
        });
        if (duplicates.length) {
          setName(`${found.name} ${duplicates.length}`);
        } else {
          setName(found.name);
        }
        setSelectedTemplateAttrs(attrs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, allTemplates]);

  const handleFormSubmit = () => {
    if (!name) {
      setAlertMessage(t("Name is required"));
      return;
    }
    if (!selectedTemplate) {
      setAlertMessage("A template must be selected");
      return;
    }
    const templateName = templateOptions.find((item) => item.value === selectedTemplate);
    const preCond = preConditions?.map((item) => conditionMapping[item.value]);
    const postCond = postConditions?.map((item) => conditionMapping[item.value]);

    const current = new Date().toISOString();
    const result: KanbanWorkflowsGeneralNodeData = {
      id: uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      template_id: selectedTemplate,
      template_name: templateName?.description || selectedTemplate,
      createdOn: current,
      updatedOn: current,
      preConditions: preCond,
      postConditions: postCond,
      template_attributes: selectedTemplateAttrs,
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  const handleFetchTemplates = async () => {
    try {
      setFormStatus("loading");
      const templatesData = await callApi<GetQueryKanbanTaskTemplatesSnippet>({
        query: getQueryKanbanTaskTemplates,
        auth: { setAuthedUser },
      });
      const options: SelectOption[] = templatesData.map((item) => {
        return {
          description: item.name,
          value: item.id,
        };
      });
      setAllTemplates(templatesData);
      setTemplateOptions(options);
      setFormStatus(null);
      setOpenModal(null);
    } catch (err) {
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleFetchConditions = async () => {
    try {
      setFormStatus("loading");
      const conditions = await callApi<GetQueryKanbanTaskConditionsSnippet>({
        query: getQueryKanbanTaskConditions,
        auth: { setAuthedUser },
      });
      const options: SelectOption[] = conditions.map((item) => {
        return {
          description: item.data_field.name,
          value: item.id,
        };
      });
      setConditionOptions(options);
      setConditionMapping(getConditionMapping(conditions));
      setFormStatus(null);
    } catch (err) {
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack spacing={5} alignItems="center" justifyContent="center" direction="row">
        <Select
          label={t("Template")}
          selectOptions={templateOptions}
          fullWidth
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
          disabled={formStatus !== null}
        />
        <IconButton
          css={styles.marginLeftAuto}
          onClick={() => setOpenModal("Add New Template")}
        >
          <AddIcon />
        </IconButton>
      </Stack>

      <Stack css={styles.width100} spacing={2}>
        <Autocomplete
          label={t("Pre-Conditions")}
          options={conditionOptions}
          value={preConditions}
          handleOnChange={(val) => setPreConditions(val)}
          disabled={formStatus !== null}
          multiple
        />
        <Autocomplete
          label={t("Post-Conditions")}
          options={conditionOptions}
          value={postConditions}
          handleOnChange={(val) => setPostConditions(val)}
          disabled={formStatus !== null}
          multiple
        />
        <Box component="div">
          <Button
            color="secondary"
            variant="text"
            onClick={() => setOpenModal("Condition Details")}
          >
            {t("Condition Details")}
          </Button>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button css={[styles.width100, styles.widthLimit20]} onClick={handleFormSubmit}>
          {t("Save Changes")}
        </Button>
      </Box>

      <Modal
        onClose={() => setOpenModal(null)}
        open={!!openModal}
        fullWidth
        label={openModal ? getTranslatedModalTitle(openModal) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {openModal === "Add New Template" ? (
          <AddKanTaskTemplate
            emptyTemplate={EMPTY_KANBAN_TASK_TEMPLATE}
            onCreateNew={handleFetchTemplates}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {openModal === "Condition Details" ? (
          <Stack spacing={1}>
            {Object.values(conditionMapping).map((item) => (
              <LabelWithBoldedPart
                key={item.id}
                bolded={`${item.comparison_operator} ${item.value}`}
                text={item.data_field.name}
                inverse
              />
            ))}
          </Stack>
        ) : null}
      </Modal>
    </Stack>
  );
};

export default NodeGeneralForm;

const getConditionOptions = (conditions: NodeCondition[]): SelectOption[] => {
  return conditions.map((item) => ({
    value: item.id,
    description: item.data_field.name,
  }));
};

const getConditionMapping = (
  conditions: NodeCondition[]
): Record<string, NodeCondition> => {
  const result: Record<string, NodeCondition> = {};
  conditions.forEach((item) => {
    result[item.id] = item;
  });

  return result;
};
