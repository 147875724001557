import useTheme from "@mui/material/styles/useTheme";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import {
  TimeChartConfiguration,
  TimeChartThreshold,
  TimeChartThresholdValues,
} from "./timeChartTypes";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { useEffect, useState } from "react";
import TimeChart from "./TimeChart";
import {
  ChartConfigFormValuesWithTitle,
  ExcellenceChartFormProps,
  LineAreaHeatMapScatterPlotData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ExcellenceChartDataSchema,
  ExcellenceChartDataSchemaParameter,
  ExcellenceParameter,
} from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceTimeSeriesData } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { ExcellenceDateTimeConfigurationData } from "../../SmallComponents/DateTimeConfiguration/dateTimeConfigurationUtils";
import { getTimeChartStartEndTimes, getTimeChartXScaleMinMax } from "./timeChartUtils";
import { getUnitsOfMeasure } from "../../../Api/Excellence/apiExcellenceGetQueries";
import { GetUnitsOfMeasureSnippet } from "../../../Api/Excellence/apiExcellenceSnippets";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { getUnitsOfMeasureMapping } from "../../SmallComponents/UnitsOfMeasureDropdown/unitsOfMeasureDropdownUtils";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import { useLanguageContext } from "../../../context/LanguageContext";
import EditTimeChartDataSchemaForm from "./EditTimeChartDataSchemaForm";
import EditTimeChartThresholds from "./EditTimeChartThresholds";
import EditTimeChartConfig from "./EditTimeChartConfig";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import { postQueryExcellenceAnomalyRule } from "../../../Api/Excellence/apiExcellencePostQueries";

interface EditTimeChartFormProps extends ExcellenceChartFormProps {
  config: TimeChartConfiguration;
  data: LineAreaHeatMapScatterPlotData | null;
  dateTimeConfig: ExcellenceDateTimeConfigurationData | null;
}

const EditTimeChartForm: React.FC<EditTimeChartFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  widgetTitle,
  handleSaveChanges,
  parameters,
  dateTimeConfig,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState<number>(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<TimeChartConfiguration>,
      ExcellenceChartDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: dataSchema as ExcellenceChartDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<LineAreaHeatMapScatterPlotData | null>(
    data
  );
  const [removeInterval, setRemoveInterval] = useState<boolean>(false);

  // tab number 2 - edit data
  const [selectedNodes, setSelectedNodes] = useState<AutocompleteGroupedOption[]>([]);
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [thresholdNodeOptions, setThresholdNodeOptions] = useState<
    AutocompleteGroupedOption[]
  >([]);
  const [selectedConnections, setSelectedConnections] = useState<AutocompleteOption[]>(
    []
  );
  const [timeConfigData, setTimeConfigData] =
    useState<ExcellenceDateTimeConfigurationData | null>(dateTimeConfig);
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);

  const [unitsOfMeasure, setUnitsOfMeasure] = useState<GetUnitsOfMeasureSnippet | null>(
    null
  );
  const [unitsLoading, setUnitsLoading] = useState<boolean>(true);
  const [graphqlFilters, setGraphqlFilters] = useState<GraphqlFilter[]>(
    config?.graphqlFilters || []
  );

  const [getTimeData] = useLazyQuery(graphQlQueryExcellenceTimeSeriesData);
  const { setAuthedUser } = useAuthedContext();

  const availableNodes = getAvailableParams(parameters, updatedConfig.unitsOfMeasure);
  const xScaleMinMax = getTimeChartXScaleMinMax(timeConfigData, updatedData);
  const paramMappingLabelOnly: Record<string, string> | undefined =
    parameters?.parameters?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr.name,
      };
    }, {});

  useEffect(() => {
    (async () => {
      try {
        const units = await callApi<GetUnitsOfMeasureSnippet>({
          query: getUnitsOfMeasure,
          auth: { setAuthedUser },
        });
        if (dataSchema) {
          // do the fetch
          await handleFetchData({ input: dataSchema });
        }
        setUnitsOfMeasure(units);
      } catch (err) {
        console.log(err);
      }
      setUnitsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ( graphqlFilters.length > 0 ) {
      const selectedConnections: AutocompleteOption[] = Array.from(
        new Map(
          graphqlFilters.map((item) => [item.containerId, {
            value: item.containerId,
            description: item.containerName
          }])
        ).values()
      );
      setSelectedConnections(selectedConnections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ( graphqlFilters && graphqlFilters.length ) {
      setUpdatedConfig((prev) => ({
        ...prev,
        graphqlFilters
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphqlFilters]);

  useEffect(() => {
    if (initialFetchDone) {
      setThresholdNodeOptions(selectedNodes);
      setUpdatedConfig((prev) => {
        if (!prev.threshold) {
          return prev;
        }
        const updatedThreshold = updateThresholdBasedOnNodeChanges(
          prev.threshold,
          selectedNodes,
          nodeOptions
        );
        return {
          ...prev,
          threshold: updatedThreshold,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodes, initialFetchDone, nodeOptions]);

  useEffect(() => {
    if (dataSchema && !initialFetchDone) {
      const connectionsMap: Record<string, string> = {};
      const nodesMap: Record<string, string> = {};
      const selectedConnectionsSet: Set<string> = new Set();
      const selectedNodesArr: AutocompleteGroupedOption[] = [];

      parameters?.parameters.forEach((element) => {
        if (!connectionsMap[element.container]) {
          connectionsMap[element.container] = element.containerName;
        }
        if (!nodesMap[element.id]) {
          nodesMap[element.id] = element.name;
        }
      });

      for (const item of dataSchema.parameters) {
        selectedConnectionsSet.add(item.container);
        selectedNodesArr.push({
          value: item.parameterId,
          description: nodesMap[item.parameterId],
          groupName: connectionsMap[item.container],
        });
      }

      const selectedConnectionsArr: SelectOption[] = Array.from(
        selectedConnectionsSet
      ).map((item) => ({ value: item, description: connectionsMap[item] }));

      const paramsToUse = getAvailableParams(parameters, updatedConfig.unitsOfMeasure);
      const nodeOptionsFromSubs: AutocompleteGroupedOption[] =
        paramsToUse?.parameters
          .map((item) => ({
            groupName: item.containerName,
            value: item.id,
            description: item.name,
          }))
          .filter((node) =>
            selectedConnectionsArr.some((sub) => sub.description === node.groupName)
          ) || [];

      // pop out nodes which are threshold nodes
      const selectedDynamicThresholdValues: string[] = [];
      Object.values(config?.threshold?.values || {})?.forEach((value) => {
        if (value?.node) {
          selectedDynamicThresholdValues.push(value.node);
        }
      });
      const formattedSelectedNodesArr = selectedNodesArr.filter(
        (node) => !selectedDynamicThresholdValues.includes(node.value)
      );

      setSelectedConnections(selectedConnectionsArr);
      setNodeOptions(nodeOptionsFromSubs);
      setSelectedNodes(formattedSelectedNodesArr);
      setInitialFetchDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters, dataSchema, config.threshold]);

  useEffect(() => {
    let intervalRate: number | null = 10000;

    if (
      timeConfigData?.commonPeriod &&
      timeConfigData.commonPeriodStart &&
      timeConfigData.commonPeriodEnd &&
      timeConfigData.commonPeriodFetchRate
    ) {
      intervalRate = +timeConfigData.commonPeriodFetchRate;
    }
    const paramMapping: Record<string, ExcellenceParameter> | undefined =
      parameters?.parameters?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: curr,
        };
      }, {});

    const inputParameters: ExcellenceChartDataSchemaParameter[] =
      selectedNodes?.map((node) => ({
        container: paramMapping?.[node.value]?.container || "",
        parameterId: node.value,
      })) || [];

    const intervalId = intervalRate
      ? setInterval(async () => {
          if (updatedSchema?.startTime && updatedSchema.endTime && timeConfigData) {
            const { startTime, endTime } = getTimeChartStartEndTimes(timeConfigData);
            const liveSchema = {
              parameters: inputParameters,
              startTime,
              endTime,
            };

            const resultData = await handleFetchDataBase(liveSchema);
            setUpdatedData(resultData);
          }
        }, intervalRate)
      : null;

    if ((removeInterval || !updatedSchema?.parameters.length) && intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeConfigData, removeInterval, selectedNodes, initialFetchDone]);

  const handleOnConnectionChange = async (subs: AutocompleteOption[] | null) => {
    if (!subs?.length) {
      setNodeOptions([]);
      setSelectedNodes([]);
      const newSchema =
        updatedSchema === null ? null : { ...updatedSchema, parameters: [] };
      setUpdatedSchema(newSchema);
      if (newSchema) {
        const resultData = await handleFetchDataBase(newSchema);
        setUpdatedData(resultData);
      }
    } else {
      // 1. remove selectedNodes which are not in all node options
      setSelectedNodes((prev) =>
        prev.filter((node) => subs.some((sub) => sub.description === node.groupName))
      );

      // 2. set all node options based on selected subs
      const paramsToUse = getAvailableParams(parameters, updatedConfig.unitsOfMeasure);
      const nodeOptionsFromSubs: AutocompleteGroupedOption[] =
        paramsToUse?.parameters
          .map((item) => ({
            groupName: item.containerName,
            value: item.id,
            description: item.name,
          }))
          .filter((node) => subs.some((sub) => sub.description === node.groupName)) || [];
      setNodeOptions(nodeOptionsFromSubs);
    }

    setSelectedConnections(subs || []);
  };

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;

    let schemaToUse = updatedSchema ? { ...updatedSchema } : null;
    if (timeConfigData && updatedSchema) {
      const { startTime, endTime } = getTimeChartStartEndTimes(timeConfigData);
      schemaToUse = {
        ...updatedSchema,
        startTime,
        endTime,
      };
    }

    const updatedChart: DynamicGridChartOptions = {
      timeChart: {
        config: { ...otherConfig, graphqlFilters },
        data: updatedData,
        dataSchema: schemaToUse,
        dateTimeConfig: timeConfigData,
      },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else if (!otherConfig.axisBottomLegend) {
      setFieldAlert(t("X axis label is required"));
    } else if (!otherConfig.axisLeftLegend) {
      setFieldAlert(t("Y axis label is required"));
    } else if (
      otherConfig.threshold &&
      !isValidThresholdOrder(otherConfig.threshold.values)
    ) {
      setFieldAlert(
        t(
          "Invalid Order: Ensure that the values are in ascending order, with lowLow < low < high < highHigh"
        )
      );
    } else {
      // all fields checks have passed
      createAnomalyRule(updatedChart);
      handleSaveChanges(updatedChart, title);
    }
  };

  const createAnomalyRule = async (chart: DynamicGridChartOptions) => {
    try {
      if (chart.timeChart?.config.threshold?.mode === "anomalyDetection") {
        const threshold = chart.timeChart.config.threshold;
        const values = threshold.values;
        
        const percentage = parseFloat(values.percentage?.value || "0");
        const periodNumber = parseInt(values.periodNumber?.value || "0");
        const periodTime = values.periodTime?.value;
        
        if (percentage && periodNumber && periodTime && percentage > 0 && periodNumber > 0) {
          const periodInSeconds = convertPeriodToSeconds(periodTime, periodNumber);
          const selectedNode = chart.timeChart.dataSchema?.parameters[0];

          if (selectedNode) {
            await callApi({
              query: postQueryExcellenceAnomalyRule(
                selectedNode.container,
                selectedNode.parameterId, 
                periodInSeconds,
                percentage
              ),
              auth: { setAuthedUser }
            });
          }
        }
      }
    } catch (err) {
      console.error("Error creating anomaly rule:", err);
    }
  };

  const convertPeriodToSeconds = (periodTime: string, periodNumber: number): number => {
    switch (periodTime) {
      case 'm':
        return periodNumber * 60;
      case 'h':
        return periodNumber * 60 * 60;
      case 'd':
        return periodNumber * 24 * 60 * 60;
      case 'w':
        return periodNumber * 7 * 24 * 60 * 60;
      case 'M':
        return periodNumber * 30 * 24 * 60 * 60;
      case 'y':
        return periodNumber * 365 * 24 * 60 * 60;
      default:
        return periodNumber;
    }
  };

  const handleFetchData = async (
    {
      input,
      updatedTimeConfigData,
    }: {
      input: ExcellenceChartDataSchema;
      updatedTimeConfigData?: ExcellenceDateTimeConfigurationData | null;
    },
    noDataUpdate?: boolean
  ) => {
    const resultData = await handleFetchDataBase(input);

    if (noDataUpdate) {
      return resultData;
    } else {
      setUpdatedData(resultData);
      setUpdatedSchema(input);
      if (updatedTimeConfigData !== undefined) {
        setTimeConfigData(updatedTimeConfigData);
      }
    }
  };

  const handleFetchDataBase = async (input: ExcellenceChartDataSchema) => {
    const result = await getTimeData({
      variables: {
        input: {
          ...input,
          filters: buildGraphqlFilters(graphqlFilters),
        },
      },
    });
    if (!result.data || result.error) {
      throw new Error(JSON.stringify(result));
    }
    const resultData = result.data.timeSeriesData.chartData;
    return resultData;
  };

  const handleOnUnitChange = async (
    unitID: string | null,
    newNodes?: AutocompleteGroupedOption[],
    noRequest?: boolean
  ) => {
    if (!unitsOfMeasure) {
      return;
    }
    const unitsMapping = getUnitsOfMeasureMapping(unitsOfMeasure);
    const foundUnit = unitID ? unitsMapping?.[unitID] : null;

    const allFilteredNodes = foundUnit
      ? getAvailableParams(parameters, foundUnit.unitID)
      : parameters;

    const filteredSelectedNodes = (newNodes || selectedNodes).filter((item) => {
      return allFilteredNodes?.parameters.some((node) => node.id === item.value);
    });

    const filteredConnections = selectedConnections.filter((item) => {
      return filteredSelectedNodes.some((node) => node.groupName === item.description);
    });
    const nodeOptionsFromSubs: AutocompleteGroupedOption[] =
      allFilteredNodes?.parameters
        .map((item) => ({
          groupName: item.containerName,
          value: item.id,
          description: item.name,
        }))
        .filter((node) =>
          filteredConnections.some((sub) => sub.description === node.groupName)
        ) || [];

    const labelY = foundUnit
      ? `${foundUnit.unitName} (${foundUnit.unitSymbol})`
      : t("Y-axis label");

    setUpdatedConfig((prev) => ({
      ...prev,
      unitsOfMeasure: foundUnit ? foundUnit.unitID : "",
      axisLeftLegend: labelY,
    }));
    setSelectedConnections(filteredConnections);
    setSelectedNodes(filteredSelectedNodes);
    setNodeOptions(nodeOptionsFromSubs);

    if (!noRequest) {
      const schema: ExcellenceChartDataSchema | null = updatedSchema
        ? {
            ...updatedSchema,
            parameters: filteredSelectedNodes.map((item) => ({
              parameterId: item.value,
              container: item.groupName,
            })),
          }
        : null;
      if (schema) {
        await handleFetchData({ input: schema });
      }
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {tabValue === 0 ? (
            <EditTimeChartConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
            />
          ) : null}

          {tabValue === 1 ? (
            <Stack spacing={4}>
              <EditTimeChartDataSchemaForm
                parameters={availableNodes}
                dataSchema={updatedSchema}
                handleFetchData={handleFetchData}
                handleOnConnectionChange={handleOnConnectionChange}
                selectedNodes={selectedNodes}
                nodeOptions={nodeOptions}
                selectedConnections={selectedConnections}
                setSelectedNodes={setSelectedNodes}
                dateTimeConfig={timeConfigData}
                setRemoveInterval={setRemoveInterval}
                handleOnUnitChange={handleOnUnitChange}
                disabledNodeOptions={getDynamicThresholdNodeIDs(updatedConfig.threshold)}
                configUnit={updatedConfig?.unitsOfMeasure || null}
                unitsLoading={unitsLoading}
                fetchedUnits={unitsOfMeasure}
                graphqlFilters={graphqlFilters}
                setGraphqlFilters={setGraphqlFilters}
              />
              <Box component="div">
                <Divider css={styles.labelBreak} flexItem />
                <EditTimeChartThresholds
                  config={updatedConfig}
                  setUpdatedConfig={setUpdatedConfig}
                  parameters={availableNodes}
                  dataSchema={updatedSchema}
                  handleFetchData={handleFetchData}
                  nodeBoundaryOptions={thresholdNodeOptions}
                  nodeDynamicThresholdOptions={nodeOptions}
                  selectedNodes={selectedNodes}
                  updatedSchema={updatedSchema}
                />
              </Box>
            </Stack>
          ) : null}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography css={styles.labelBreak} variant="h3">
            {updatedConfig.title}
          </Typography>
          <TimeChart
            css={chartClass}
            configuration={updatedConfig}
            data={updatedData}
            xScaleMinMax={xScaleMinMax}
            paramMapping={paramMappingLabelOnly}
          />
        </Grid>
      </Grid>
      <LoadingBackdrop loading={unitsLoading} />
    </ExcellenceChartFormWrapper>
  );
};

export default EditTimeChartForm;

function isValidThresholdOrder(data: TimeChartThresholdValues): boolean {
  const { lowLowThreshold, lowThreshold, highThreshold, highHighThreshold } = data;
  const lowLow = lowLowThreshold?.value;
  const low = lowThreshold?.value;
  const high = highThreshold?.value;
  const highHigh = highHighThreshold?.value;

  // Count the number of defined values
  const definedValues = [lowLow, low, high, highHigh].filter(
    (val) => val !== undefined && !!`${val}`.length
  );

  // If 1 or 0 values are present - return true without comparison
  if (definedValues.length <= 1) {
    return true;
  }

  // Convert the defined values to numbers for comparison
  const numericValues = definedValues.map((val) =>
    typeof val === "number" ? val : parseFloat(val!)
  );

  // Check if values are valid numbers
  if (numericValues.some((val) => isNaN(val))) {
    return false;
  }

  // Check if the values are in ascending order
  let isAscending = true;

  numericValues.forEach((value, index) => {
    if (index > 0 && numericValues[index - 1] >= value) {
      isAscending = false;
    }
  });

  if (!isAscending) {
    return false;
  }

  return true;
}

const getAvailableParams = (
  params: { parameters: ExcellenceParameter[] } | null,
  selectedUnit: string
): { parameters: ExcellenceParameter[] } | null => {
  if (!params?.parameters) {
    return null;
  }
  if (!selectedUnit) {
    return params;
  }

  return {
    parameters: params?.parameters.filter(
      (item) => !item.unitId || item.unitId === selectedUnit
    ),
  };
};

const updateThresholdBasedOnNodeChanges = (
  threshold: TimeChartThreshold,
  selectedNodes: AutocompleteGroupedOption[],
  nodeOptions: AutocompleteGroupedOption[]
): TimeChartThreshold => {
  if (threshold.mode === "nodeMinMax") {
    const exists = selectedNodes.some(
      (item) => item.value === threshold.minMaxThresholdNode?.node
    );
    if (!exists) {
      return {
        ...threshold,
        minMaxThresholdNode: undefined,
      };
    }
  }

  if (threshold.mode === "valuesFromNodes" && nodeOptions.length) {
    const highHigh = nodeOptions.some(
      (item) => item.value === threshold.values.highHighThreshold?.node
    );
    const high = nodeOptions.some(
      (item) => item.value === threshold.values.highThreshold?.node
    );
    const low = nodeOptions.some(
      (item) => item.value === threshold.values.lowThreshold?.node
    );
    const lowLow = nodeOptions.some(
      (item) => item.value === threshold.values.lowLowThreshold?.node
    );

    return {
      ...threshold,
      values: {
        highHighThreshold: highHigh ? threshold.values.highHighThreshold : undefined,
        highThreshold: high ? threshold.values.highThreshold : undefined,
        lowThreshold: low ? threshold.values.lowThreshold : undefined,
        lowLowThreshold: lowLow ? threshold.values.lowLowThreshold : undefined,
      },
    };
  }

  // all good - return threshold, as it is
  return threshold;
};

const getDynamicThresholdNodeIDs = (
  threshold: TimeChartThreshold | undefined
): string[] => {
  const selectedDynamicValues: string[] = [];
  Object.values(threshold?.values || {})?.forEach((value) => {
    if (value?.node) {
      selectedDynamicValues.push(value.node);
    }
  });

  return selectedDynamicValues;
};
