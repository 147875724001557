import React, { useEffect, useRef, useState } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";

interface HelpContentDisplayProps {
  type: "text" | "image" | "video";
  content: string;
}

const HelpContentDisplay: React.FC<HelpContentDisplayProps> = ({ type, content }) => {
  const { t } = useLanguageContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [aspectRatio, setAspectRatio] = useState<number>(56.25); // Default 16:9 ratio

  useEffect(() => {
    if (type === "video" && videoRef.current) {
      const video = videoRef.current;
      
      // Function to calculate and set the aspect ratio
      const calculateAspectRatio = () => {
        if (video.videoWidth && video.videoHeight) {
          const ratio = (video.videoHeight / video.videoWidth) * 100;
          setAspectRatio(ratio);
        }
      };

      // Try to get dimensions immediately if metadata is already loaded
      if (video.readyState >= 1) {
        calculateAspectRatio();
      }

      // Set up event listener for when metadata loads
      video.addEventListener('loadedmetadata', calculateAspectRatio);
      
      return () => {
        video.removeEventListener('loadedmetadata', calculateAspectRatio);
      };
    }
  }, [type]);

  if (type === "text") {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  if (type === "image") {
    return (
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          height: 0,
          overflow: "hidden",
          maxWidth: "100%",
          backgroundColor: "black",
        }}
      >
        <img
          src={`https://${content}`}
          alt="Help Image"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  }

  if (type === "video") {
    return (
      <div
        style={{
          position: "relative",
          paddingBottom: `${aspectRatio}%`,
          height: 0,
          overflow: "hidden",
          maxWidth: "100%",
          backgroundColor: "black",
        }}
      >
        <video
          ref={videoRef}
          controls
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        >
          <source src={`https://${content}`} />
          {t("Your browser does not support the video tag.")}
        </video>
      </div>
    );
  }

  return null;
};

export default HelpContentDisplay;
