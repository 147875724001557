import { Query } from "../callApi";

export const putQueryDataManagerUpdateWorkflow = (id: string, name: string, description: string, workflow_data: any): Query => ({
    endpoint: `/data_manager/container_creation/workflow/${id}`,
    method: "PUT",
    variables: {
      name: name,
      description: description,
      workflow_data: workflow_data
    }
  });