import { Box, Stack, Typography } from "@mui/material";
import { WorkflowsParameterNodeData } from "../../../DataFlow/workflowUtils";
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceParameters } from "../../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { ExcellenceParameter } from "../../../../../../GraphQL/Excellence/graphQLTypesExcellence";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";

interface NodeParameterDataModalProps {
  data: WorkflowsParameterNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeParameterDataModal: React.FC<NodeParameterDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();
  const [containerInfo, setContainerInfo] = useState<{containerName: string} | null>(null);
  const [getParameters] = useLazyQuery<{ parameters: ExcellenceParameter[] }>(
    graphQlQueryExcellenceParameters
  );

  useEffect(() => {
    fetchContainerInfo();
  }, []);

  const fetchContainerInfo = async () => {
    try {
      const result = await getParameters();
      if (result.data?.parameters) {
        const container = result.data.parameters.find(p => p.container === data.container_id);
        if (container) {
          setContainerInfo({
            containerName: container.containerName
          });
        }
      }
    } catch (err) {
      console.error("Error fetching container info:", err);
    }
  };
  
  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      <LabelWithBoldedPart text={t("Container ID")} bolded={data.container_id || ""} />
      {containerInfo && (
        <LabelWithBoldedPart text={t("Container")} bolded={containerInfo.containerName} />
      )}
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_RowAggregation_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeParameterDataModal; 