import {
  Box, 
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useTheme
} from "@mui/material";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { DATA_MANAGER_FLOW_NODE_TYPE, WorkflowsFilterNodeData } from "../../workflowUtils";

interface NodeFilterFormProps {
  handleCreateSubmit?: (data: WorkflowsFilterNodeData) => void;
  handleEditSubmit?: (data: WorkflowsFilterNodeData) => void;
  data?: WorkflowsFilterNodeData;
  nodes: Node<WorkflowsFilterNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const NodeFilterForm: React.FC<NodeFilterFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  //nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  //unsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Filter");
  const [filterExpression, setFilterExpression] = useState<string>(
    data?.filter_expression || ""
  );
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.Filter;

  const initialFormState = useRef({
    name,
    filterExpression,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, filterExpression },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    if (!filterExpression) {
      setAlertMessage(t("Filter expression is required"));
      setFormStatus("warning");
      return;
    }

    const current = new Date().toISOString();
    const result: WorkflowsFilterNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      filter_expression: filterExpression,
      createdOn: data?.createdOn || current,
      updatedOn: current,
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Filter Configuration")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="filterExpression"
                label={t("Filter Expression")}
                onChange={(e) => setFilterExpression(e.target.value)}
                value={filterExpression}
                multiline
                rows={4}
                placeholder="age > 30 and country == 'US'"
                required
                error={formStatus === "warning" && !filterExpression}
                helperText={
                  formStatus === "warning" && !filterExpression 
                    ? t("Filter expression is required") 
                    : t("Example: age > 30 and country == 'US'")
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button css={[styles.width100, styles.widthLimit20]} onClick={handleFormSubmit}>
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeFilterForm; 