import { Box, useTheme, FormControlLabel, Checkbox } from "@mui/material";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import { KanbanBoardColumn } from "../../Components/SmallComponents/KanbanBoard/kanbanBoardUtils";
import { useLanguageContext } from "../../context/LanguageContext";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import { getQueryProcessWizzardKanbanColumns, getQueryProcessWizzardKanbanTasks } from "../../Api/ProcessWizzard/apiProcessWizzardGetQueries";
import { GetQueryProcessWizzardKanbanColumnsSnippet, KanbanTask, GetQueryProcessWizzardKanbanTasksSnippet } from "../../Api/ProcessWizzard/apiProcessWizzardSnippets";
import OrdersKanbanBoard from "./Components/OrdersKanbanBoard";
import { postQueryProcessWizzardUpdateTask, postQueryProcessWizzardUploadTaskDocs } from "../../Api/ProcessWizzard/apiProcessWizzardPostQueries";

const KanbanBoardPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  
  const [tasks, setTasks] = useState<KanbanTask[]>([]);
  const [displayedTasks, setDisplayedTasks] = useState<KanbanTask[]>([]);
  const [columns, setColumns] = useState<KanbanBoardColumn[]>([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const { setAuthedUser } = useAuthedContext();

  const updateTask = async (orderId: string, taskId: string, status: string, workDescription: string, beforeImage: File | null, afterImage: File | null) => {
    setLoading(true);
    if(beforeImage || afterImage) {
        await callApi({
        query: postQueryProcessWizzardUploadTaskDocs(orderId, taskId, beforeImage, afterImage),
        auth: { setAuthedUser },
        });
    }
    await callApi({
      query: postQueryProcessWizzardUpdateTask(orderId, taskId, status, workDescription),
      auth: { setAuthedUser },
    });
    setLoading(false);
    handleFetchTasks();
  }

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Process Wizzard Kanban Board")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    (async () => {
      await Promise.all([
        handleFetchTasks(),
        handleFetchColumns()
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showCompleted) {
      setDisplayedTasks(tasks.filter(item => item.status === "Offen" || item.status === "In Bearbeitung"));
    } else {
      setDisplayedTasks(tasks);
    }
  }, [tasks, showCompleted]);

  const handleFetchTasks = async () => {
    try {
      const data = await callApi<GetQueryProcessWizzardKanbanTasksSnippet>({
        query: getQueryProcessWizzardKanbanTasks(),
        auth: { setAuthedUser },
      });

      setTasks(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleFetchColumns = async () => {
    try {
      const data = await callApi<GetQueryProcessWizzardKanbanColumnsSnippet>({
        query: getQueryProcessWizzardKanbanColumns(),
        auth: { setAuthedUser },
      });
      
      const cols: KanbanBoardColumn[] = data
        .slice()
        .sort((a, b) => a.order - b.order)
        .map((item) => ({
          id: item.id,
          title: item.name,
        }));

      setColumns(cols);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleShowCompletedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCompleted(event.target.checked);
  };

  return (
    <Box component="div" css={[styles.width100]}>
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Process Wizzard Kanban Board")} />
      ) : null}

      <Box
        component="div"
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          px: 3,
          py: 1,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox 
              checked={showCompleted} 
              onChange={handleShowCompletedChange}
            />
          }
          label={t("Show completed tasks")}
        />
      </Box>

      <OrdersKanbanBoard 
        tasks={displayedTasks} 
        columns={columns} 
        updateTask={updateTask}
        loading={loading}
      />
    </Box>
  );
};

export default KanbanBoardPage;
