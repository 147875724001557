import {
  Box, 
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useTheme
} from "@mui/material";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Select from "../../../../../../Components/MaterialUI/FormFields/Select";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses, SelectOption } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { DATA_MANAGER_FLOW_NODE_TYPE } from "../../workflowUtils";
import { WorkflowsIntervalJoinNodeData } from "../../workflowUtils";

interface NodeIntervalJoinFormProps {
  handleCreateSubmit?: (data: WorkflowsIntervalJoinNodeData) => void;
  handleEditSubmit?: (data: WorkflowsIntervalJoinNodeData) => void;
  data?: WorkflowsIntervalJoinNodeData;
  nodes: Node<WorkflowsIntervalJoinNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
  firstColumnOptions: SelectOption[];
  secondColumnOptions: SelectOption[];
}

const NodeIntervalJoinForm: React.FC<NodeIntervalJoinFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  firstColumnOptions,
  secondColumnOptions
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Interval Join");
  const [leftKey, setLeftKey] = useState<string>(data?.left_key || "");
  const [rightStartKey, setRightStartKey] = useState<string>(data?.right_start_key || "");
  const [rightEndKey, setRightEndKey] = useState<string>(data?.right_end_key || "");
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin;

  const initialFormState = useRef({
    name,
    leftKey,
    rightStartKey,
    rightEndKey,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, leftKey, rightStartKey, rightEndKey },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    if (firstColumnOptions.length && secondColumnOptions.length) {
      if (!leftKey) {
        setAlertMessage(t("Left key is required"));
        setFormStatus("warning");
        return;
      }

      if (!rightStartKey) {
        setAlertMessage(t("Right start key is required"));
        setFormStatus("warning");
        return;
      }

      if (!rightEndKey) {
        setAlertMessage(t("Right end key is required"));
        setFormStatus("warning");
        return;
      }
    }

    const current = new Date().toISOString();
    const result: WorkflowsIntervalJoinNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      left_key: leftKey,
      right_start_key: rightStartKey,
      right_end_key: rightEndKey,
      createdOn: data?.createdOn || current,
      updatedOn: current,
      container_id: data?.container_id || "",
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Interval Join Configuration")}
          </Typography>
          {!firstColumnOptions.length || !secondColumnOptions.length ? (
            <Alert
              message={t("This node requires 2 input nodes to be connected before configuration")}
              showAlert={true}
              severity="warning"
            />
          ) : null}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                name="leftKey"
                label={t("Left Key")}
                onChange={(e) => setLeftKey(e.target.value)}
                value={leftKey}
                required
                error={formStatus === "warning" && !leftKey}
                helperText={formStatus === "warning" && !leftKey ? t("Left key is required") : t("Name of the datetime column in the left DataFrame")}
                disabled={!firstColumnOptions.length}
                selectOptions={firstColumnOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="rightStartKey"
                label={t("Right Start Key")}
                onChange={(e) => setRightStartKey(e.target.value)}
                value={rightStartKey}
                required
                error={formStatus === "warning" && !rightStartKey}
                helperText={formStatus === "warning" && !rightStartKey ? t("Right start key is required") : t("Column name for the start of the interval in the right DataFrame")}
                disabled={!secondColumnOptions.length}
                selectOptions={secondColumnOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="rightEndKey"
                label={t("Right End Key")}
                onChange={(e) => setRightEndKey(e.target.value)}
                value={rightEndKey}
                required
                error={formStatus === "warning" && !rightEndKey}
                helperText={formStatus === "warning" && !rightEndKey ? t("Right end key is required") : t("Column name for the end of the interval in the right DataFrame")}
                disabled={!secondColumnOptions.length}
                selectOptions={secondColumnOptions}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button css={[styles.width100, styles.widthLimit20]} onClick={handleFormSubmit}>
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeIntervalJoinForm; 