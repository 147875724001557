import { Query } from "../callApi";

export const postQueryProcessWizzardUploadDocument = (orderId: string, file: File): Query => ({
  endpoint: `mechanic/orders/${orderId}/upload`,
  method: "POST",
  multipartForm: true,
  variables: {
    file: file,
  },
});

export const postQueryProcessWizzardSaveStatement = (orderId: string, text: string): Query => ({
  endpoint: `mechanic/orders/${orderId}/statement`,
  method: "POST",
  variables: {
    text: text,
  },
}); 

export const postQueryProcessWizzardUpdateTask = (orderId: string, taskId: string, status: string, workDescription: string): Query => ({
  endpoint: `mechanic/orders/${orderId}/tasks/${taskId}`,
  method: "PUT",
  variables: {
    status: status,
    documentation_text: workDescription,
  },
}); 

export const postQueryProcessWizzardUploadTaskDocs = (orderId: string, taskId: string, beforeImage: File | null, afterImage: File | null): Query => ({
  endpoint: `mechanic/orders/${orderId}/tasks/${taskId}/upload`,
  method: "POST",
  multipartForm: true,
  variables: {
    ...(beforeImage && { before_file: beforeImage }),
    ...(afterImage && { after_file: afterImage }),
  },
});