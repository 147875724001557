import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { GetQueryMaterialsAndProductsSnippet } from "../../Api/SupplyChain/apiSupplyChainSnippets";
import { IconButton, Stack, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLanguageContext } from "../../context/LanguageContext";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import { useMemo } from "react";

interface ProductsTableProps {
  tableData: GetQueryMaterialsAndProductsSnippet | undefined;
  loading: boolean;
  onViewProduct?: (productId: string) => void;
}

const ProductsTable: React.FC<ProductsTableProps> = ({ tableData, loading, onViewProduct }) => {
  const { t } = useLanguageContext();

  const rowsWithActions = useMemo(() => 
    tableData?.rows.map((row) => ({
      ...row,
      actions: (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Tooltip title={t("View product")}>
            <IconButton onClick={() => onViewProduct?.(row.id)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    })) || []
  , [tableData?.rows, onViewProduct]);

  const columnsWithActions: TableGridColumnSchema[] = useMemo(() => 
    tableData ? [
      ...tableData.columns,
      {
        id: "actions",
        label: "Actions",
        type: "button",
        alignCol: "center",
      }
    ] : []
  , [tableData]);

  return tableData ? (
    <ResponsiveTableGrid
      rows={rowsWithActions}
      colSchema={useTranslateFields(columnsWithActions, ["label"])}
      loading={loading}
      responsive="responsive"
      configuration={{
        density: "compact",
        initialRowsPerPage: 15,
      }}
      tableID="ProductsTable_table"
    />
  ) : null;
};

export default ProductsTable;
