import {
  NAV_GIANT_HOME,
  NAV_GIANT_ANALYTICS,
  NAV_GIANT_PLANNING,
  NAV_GIANT_OPERATIONS,
  NAV_GIANT_TOOLBOX_MANAGER,
} from "../AppNavigation/leftNavData2";
import { LeftNavList } from "../layoutVariables";

export const allLeftNavRoutes: LeftNavList = [
  ...NAV_GIANT_HOME.list,
  ...NAV_GIANT_ANALYTICS.list,
  ...NAV_GIANT_PLANNING.list,
  ...NAV_GIANT_OPERATIONS.list,
  ...NAV_GIANT_TOOLBOX_MANAGER.list,
];

type RoutesMap = { [key: string]: string };

/**
 * Functions takes the routes data we provide for our left nav
 * and returns key-value pairs based of the nested structure.
 * Key is the text and value is the URL.
 */
const handleRoutesMapping = (navList: LeftNavList, parent?: string): RoutesMap => {
  return navList.reduce((acc: RoutesMap, curr) => {
    const currentText = parent ? `${parent}-${curr.text}` : curr.text;
    const key = parent
      ? `${acc[parent] ? `${acc[parent]}-` : ""}${currentText}`
      : currentText;

    if (curr.nested) {
      const result = handleRoutesMapping(curr.nested, currentText);
      return {
        ...acc,
        ...result,
      };
    }

    if (curr.url) {
      return {
        ...acc,
        [key]: curr.url,
      };
    }

    return acc;
  }, {});
};

const LEFT_NAV_ROUTES_MAPPING: RoutesMap = handleRoutesMapping(allLeftNavRoutes);

const INTEGRATOR_MAPPING: RoutesMap = {
  "Integrator-Connections-opc-da-information":
    "/GIANT-Toolbox-Manager/Integrator/Connections/opc-da-information/:id",
  "Integrator-Connections-opc-ua-information":
    "/GIANT-Toolbox-Manager/Integrator/Connections/opc-ua-information/:id",
  "Integrator-Connections-opc-mqtt-information":
    "/GIANT-Toolbox-Manager/Integrator/Connections/opc-mqtt-information/:id",
  "Integrator-Connections-opc-modbus-information":
    "/GIANT-Toolbox-Manager/Integrator/Connections/opc-modbus-information/:id",
};

const EXCELLENCE_MAPPING: RoutesMap = {
  excellenceDashboard: "/GIANT-Analytics/Excellence/:id",
};
const ADVANCED_ANALYTICS_MAPPING: RoutesMap = {
  advancedAnalyticsDashboard: "/GIANT-Analytics/Advanced-Analytics/:id",
};
const OEE_MAPPING: RoutesMap = {
  oeeDashboard: "/GIANT-Operations-Suite/OEE/Dashboard/:id",
};
const OPERATIONS_MAPPING: RoutesMap = {
  operationsForm: "/Forms-Designer/:id",
};
const PROJECTS_MAPPING: RoutesMap = {
  opExProject: `${LEFT_NAV_ROUTES_MAPPING["Projects"]}/:id`,
};
const WORKFLOW_MAPPING: RoutesMap = {
  workflow: `${LEFT_NAV_ROUTES_MAPPING["Organizer-Workflow Modeler"]}/:id`,
  process: `${LEFT_NAV_ROUTES_MAPPING["Forms and Flows-Workflows"]}/:id`,
};
const ACCOUNT_PROFILE_MAPPING: RoutesMap = {
  accountProfile: "/account/:id",
};
const GIANT_QUALITY: RoutesMap = {
  testPlan: "/GIANT-Quality/Test-Plans/:id",
};
const PID_CHARTS_MAPPING: RoutesMap = {
  pidChart: `${LEFT_NAV_ROUTES_MAPPING["Layouts-P&ID"]}/:id`,
};

const ELPROM_MAPPING: RoutesMap = {
  currencies: "/GIANT-Planning/currencies",
  projectHubKPIs: "/GIANT-Planning/project-hub-kpi",
  projectHubImport: "/GIANT-Planning/project-hub-import",
  projectHubData: "/GIANT-Planning/project-hub-data",
  projectHubClientOrder: "/GIANT-Planning/project-hub-data/:id",
  projectHubKsoClientOrder: "/GIANT-Planning/project-hub-data/kso/:id",
  projectHubCompareCollateral: "/GIANT-Planning/project-hub-data/compare/:id",
};

const TABLES_MAPPING: RoutesMap = {
  tables: "/Tables",
};

const PROCESS_WIZZARD_MAPPING: RoutesMap = {
  processWizzardOrders: "/GIANT-Operations-Suite/Process-Wizzard/Order-Management",
  processWizzardKanbanBoard: "/GIANT-Operations-Suite/Process-Wizzard/Kanban-Board",
};

const ROUTES_MAPPING: RoutesMap = {
  ...LEFT_NAV_ROUTES_MAPPING,
  ...INTEGRATOR_MAPPING,
  ...EXCELLENCE_MAPPING,
  ...ADVANCED_ANALYTICS_MAPPING,
  ...OEE_MAPPING,
  ...OPERATIONS_MAPPING,
  ...PROJECTS_MAPPING,
  ...WORKFLOW_MAPPING,
  ...ACCOUNT_PROFILE_MAPPING,
  ...GIANT_QUALITY,
  ...PID_CHARTS_MAPPING,
  ...ELPROM_MAPPING,
  ...TABLES_MAPPING,
  ...PROCESS_WIZZARD_MAPPING,
};

export default ROUTES_MAPPING;
