import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CollapsibleSelectTree from "../../../../Components/SmallComponents/CollapsibleSelectTree/CollapsibleSelectTree";
import { CollapsibleTreeItemData } from "../../../../Components/SmallComponents/CollapsibleSelectTree/collapsibleTreeTypes";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import { getQueryDivisions } from "./API/apiDivisionsGetQueries";

interface DivisionNode {
    id: string;
    code: string,
    name: string;
    parent_id?: string;
    created_at?: string;
    children?: DivisionNode[] | null;
}

const transformDivisionsToTree = (division: DivisionNode): CollapsibleTreeItemData => {
    return {
        id: division.id,
        uniqueID: division.id,
        label: `${division.code ? division.code + " - " : ""}${division.name}`,
        children: division.children && Array.isArray(division.children) && division.children.length > 0
            ? division.children.map(child => ({
                ...transformDivisionsToTree(child),
                label: `${child.code ? child.code + " - " : ""}${child.name}`
            }))
            : undefined,
    };
};

const DivisionPage: React.FC = () => {
    const [treeData, setTreeData] = useState<CollapsibleTreeItemData[]>([]);
    const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
    const { setAuthedUser } = useAuthedContext();

    useEffect(() => {
        const fetchAccountsTree = async () => {
            try {
                const response = await callApi({ query: getQueryDivisions(), auth: { setAuthedUser } });
                const accountsData: DivisionNode[] = Array.isArray(response) ? response : [];
                const formattedTree = accountsData.map(transformDivisionsToTree);
                setTreeData(formattedTree);
                console.log(`data: ${response}`)
            } catch (error) {
                console.error("Error fetching account tree:", error);
            }
        };

        fetchAccountsTree();
    }, [setAuthedUser]);

    return (
        <Box component="div">
            <CollapsibleSelectTree
                selected={selectedNodes}
                setSelected={setSelectedNodes}
                data={treeData} />
        </Box>
    );
};

export default DivisionPage;