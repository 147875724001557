import { Stack, useTheme } from "@mui/material";
import { Node, Edge, useReactFlow } from "reactflow";
import { useState } from "react";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Modal from "../../../Components/MaterialUI/Modal";
import Select from "../../../Components/MaterialUI/FormFields/Select";
import {
  PID_FLOW_NODE_TYPE,
  PidFlowAllNodesData,
  PidFlowNodeTypeKey,
} from "../../../pages/PidCharts/Components/PidWorkflow/Nodes/pidNodesUtils";
import { useLanguageContext } from "../../../context/LanguageContext";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { PidFlowNewNodeModal } from "../../../pages/PidCharts/Components/pidWorkflowUtils";
import { RenderForm, SelectedType } from "./reactFlowUtils";
import { SelectOption } from "../../../Global/Types/commonTypes";

interface ReactFlowCreateNewNodeModalProps {
  newNodeModal: PidFlowNewNodeModal;
  setNewNodeModal: React.Dispatch<React.SetStateAction<PidFlowNewNodeModal>>;
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  connectingNodeId: React.MutableRefObject<any>;
  typeConfig: any;
  renderForm: RenderForm;
  selectedType?: SelectedType;
  setSelectedType?: React.Dispatch<React.SetStateAction<PID_FLOW_NODE_TYPE | "">>;
  handleOnTypeChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  nodeOptions?: SelectOption<PidFlowNodeTypeKey>[];
}

const ReactFlowCreateNewNodeModal: React.FC<ReactFlowCreateNewNodeModalProps> = ({
  newNodeModal,
  setNewNodeModal,
  setNodes,
  setEdges,
  connectingNodeId,
  selectedType,
  setSelectedType,
  typeConfig,
  renderForm,
  handleOnTypeChange,
  nodeOptions,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { getNodes } = useReactFlow();

  const handleCreateSubmit = (nodeData: PidFlowAllNodesData) => {
    if (newNodeModal && typeConfig) {
      const data = getNodeData(nodeData);
      const dataID = data!.id!;
      const newNode = {
        id: dataID,
        position: newNodeModal.position,
        data: data,
        ...typeConfig,
      };

      setNodes((nds) => nds.concat(newNode));
      setEdges((eds) =>
        eds.concat({
          id: newNodeModal.id,
          source: connectingNodeId.current,
          target: dataID,
          type: "step",
        })
      );
      setNewNodeModal(null);
      if (setSelectedType) {
        setSelectedType("");
      }
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Modal
      onClose={() => setNewNodeModal(null)}
      open={!!newNodeModal}
      fullWidth
      label={t("Creating New Node")}
      unsavedChanges={unsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    >
      <Stack
        css={styles.width100}
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        {nodeOptions ? (
          <Select
            selectOptions={nodeOptions}
            fullWidth
            value={selectedType}
            onChange={handleOnTypeChange}
          />
        ) : null}

        {renderForm({
          selectedType,
          handleCreateSubmit,
          nodes: getNodes(),
          handleSetUnsavedChanges,
          setUnsavedChanges,
          unsavedChanges,
          sourceNode: getNodes().find(node => node.id === connectingNodeId.current) || null
        })}
      </Stack>
    </Modal>
  );
};

export default ReactFlowCreateNewNodeModal;

const getNodeData = (nodeData: PidFlowAllNodesData) => {
  const foundData = Object.values(nodeData).find((val) => !!val);
  return foundData;
};
