import { Edge, Node, XYPosition } from "reactflow";
import { ReactFlowMainType } from "../../../../Components/SmallComponents/ReactFlowComponents/reactFlowUtils";
import { SelectOption } from "../../../../Global/Types/commonTypes";

// ----- General Stuff START -----
export const getColumnOptionsFromTable = (table: any) : SelectOption[] => {
  if (!table) return [];
  return table.columns.map((column: any) => ({
    description: column.label,
    value: column.id,
  }));
};

export const DEFAULT_NODE_HEIGHT = 130;
export const DEFAULT_NODE_WIDTH = 300;
const CORE_NODE_CONFIG = {
  style: {
    background: "#fff",
    color: "#000",
    fontSize: 12,
    borderRadius: 15,
    width: DEFAULT_NODE_WIDTH,
    cursor: "default",
    height: DEFAULT_NODE_HEIGHT,
    padding: 7,
  },
  dragHandle: ".custom-drag-handle",
};

export type ColumnResultType = {
  id: string;
  label: string;
};

export type WorkflowType = {
  id: string;
  name: string;
  createdOn: string;
  updatedOn: string;
  nodes: WorkflowAllNodes;
  edges: Edge<any>[];
};

export type WorkflowAllNodes = (
  | WorkflowsInitialNodeType
  | WorkflowsParameterNodeType
  | WorkflowsConcatNodeType
  | WorkflowsFilterNodeType
  | WorkflowsColumnFilterNodeType
  | WorkflowsIntervalJoinNodeType
  | WorkflowsJoinNodeType
  | WorkflowsArithmeticNodeType
  | WorkflowsRowAggregationNodeType
  | WorkflowsColumnAggregationNodeType
)[];

export type FlowNewNodeModal = {
  position: XYPosition;
  id: string;
} | null;

export type NodeCondition = {
  comparison_operator: string | null;
  data_field: {
    type: string;
    name: string;
    id: string;
  };
  data_field_id: string;
  id: string;
  identifier: string | null;
  required_by: string | null;
  value: any;
};
// ----- General Stuff END -----

// ---------------- ALL NODE TYPES START ----------------=
export enum DATA_MANAGER_FLOW_NODE_TYPE {
  Initial = "Initial",
  Parameter = "ParameterNode",
  Concat = "ConcatNode",
  Filter = "FilterNode",
  ColumnFilter = "ColumnFilterNode",
  IntervalJoin = "IntervalJoinNode",
  RowAggregation = "RowAggregationNode",
  ColumnAggregation = "ColumnAggregationNode",
  Arithmetic = "ArithmeticNode",
  Join = "JoinNode",
}
export type FlowAllNodesData = {
  [DATA_MANAGER_FLOW_NODE_TYPE.Initial]?: WorkflowInitialNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.Parameter]?: WorkflowsParameterNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.Concat]?: WorkflowsConcatNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.Filter]?: WorkflowsFilterNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter]?: WorkflowsColumnFilterNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin]?: WorkflowsIntervalJoinNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation]?: WorkflowsRowAggregationNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation]?: WorkflowsColumnAggregationNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic]?: WorkflowsArithmeticNodeData;
  [DATA_MANAGER_FLOW_NODE_TYPE.Join]?: WorkflowsJoinNodeData; 
};
// ---------------- ALL NODE TYPES END ----------------=

// ----- Initial START -----
export type WorkflowInitialNodeData = {
  id: string;
  name: string;
  createdOn: string;
  updatedOn: string;
};
export type WorkflowsInitialNodeType = Node & {
  data: WorkflowInitialNodeData;
};
export const WORKFLOW_INITIAL_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  style: {
    ...CORE_NODE_CONFIG.style,
    border: "3px solid #30AFB8",
  },
  type: "initial",
};
// ----- Initial END -----

// ----- Parameter START -----
export type WorkflowsParameterNodeType = Node & {
  data: WorkflowsParameterNodeData;
};
export type WorkflowsParameterNodeData = {
  id: string;
  type: string;
  name: string;
  parameter_id?: string;
  container_id: string;
  createdOn: string;
  updatedOn: string;
};
export const WORKFLOW_PARAMETER_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.Parameter,
};
// ----- Parameter END -----

// ----- Concat START -----
export type WorkflowsConcatNodeType = Node & {
  data: WorkflowsConcatNodeData;
};
export type WorkflowsConcatNodeData = {
  id: string;
  type: string;
  name: string;
  include_common_only: boolean;
  createdOn: string;
  updatedOn: string;
};
export const WORKFLOW_CONCAT_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.Concat,
};
// ----- Concat END -----

// ----- Filter START -----
export type WorkflowsFilterNodeType = Node & {
  data: WorkflowsFilterNodeData;
};
export type WorkflowsFilterNodeData = {
  id: string;
  type: string;
  name: string;
  filter_expression: string;
  createdOn: string;
  updatedOn: string;
};
export const WORKFLOW_FILTER_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.Filter,
};
// ----- Filter END -----

// ----- ColumnFilter START -----
export type WorkflowsColumnFilterNodeType = Node & {
  data: WorkflowsColumnFilterNodeData;
};
export type WorkflowsColumnFilterNodeData = {
  id: string;
  type: string;
  name: string;
  include_columns?: string[];
  exclude_columns?: string[];
  createdOn: string;
  updatedOn: string;
  container_id: string;
};
export const WORKFLOW_COLUMN_FILTER_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter,
};
// ----- ColumnFilter END -----

// ----- Join START -----
export type WorkflowsJoinNodeType = Node & {
  data: WorkflowsJoinNodeData;
};
export type WorkflowsJoinNodeData = {
  id: string;
  type: string;
  name: string;
  join_type: 'inner' | 'left' | 'right' | 'outer' | 'asof';
  tolerance?: string;
  key_left: string;
  key_right: string;
  createdOn: string;
  updatedOn: string;
  container_id: string;
};
export const WORKFLOW_JOIN_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.Join,
};
// ----- Join END -----


// ----- IntervalJoin START -----
export type WorkflowsIntervalJoinNodeType = Node & {
  data: WorkflowsIntervalJoinNodeData;
};
export type WorkflowsIntervalJoinNodeData = {
  id: string;
  type: string;
  name: string;
  left_key: string;
  right_start_key: string;
  right_end_key: string;
  createdOn: string;
  updatedOn: string;
  container_id: string;
};
export const WORKFLOW_INTERVAL_JOIN_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin,
};
// ----- IntervalJoin END -----

// ----- RowAggregation START -----
export type WorkflowsRowAggregationNodeType = Node & {
  data: WorkflowsRowAggregationNodeData;
};
export type WorkflowsRowAggregationNodeData = {
  id: string;
  type: string;
  name: string;
  target_columns: string[];
  aggregation: 'sum' | 'avg' | 'min' | 'max' | 'prod' | 'count';
  result_column?: string;
  createdOn: string;
  updatedOn: string;
  container_id: string;
};
export const WORKFLOW_ROW_AGGREGATION_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation,
};
// ----- RowAggregation END -----

// ----- ColumnAggregation START -----
export type WorkflowsColumnAggregationNodeType = Node & {
  data: WorkflowsColumnAggregationNodeData;
};
export type WorkflowsColumnAggregationNodeData = {
  id: string;
  type: string;
  name: string;
  target_column: string;
  aggregation: 'sum' | 'avg' | 'min' | 'max' | 'prod' | 'count';
  result_column?: string;
  group_by_time?: 'minute' | 'hour' | 'day' | 'month';
  datetime_column?: string;
  group_by_column?: string;
  createdOn: string;
  updatedOn: string;
  container_id: string;
};
export const WORKFLOW_COLUMN_AGGREGATION_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation,
};
// ----- ColumnAggregation END -----

// ----- Arithmetic START -----
export type WorkflowsArithmeticNodeType = Node & {
  data: WorkflowsArithmeticNodeData;
};
export type WorkflowsArithmeticNodeData = {
  id: string;
  type: string;
  name: string;
  left_column: string;
  right_column: string;
  operation: 'add' | 'subtract' | 'multiply' | 'divide';
  result_column?: string;
  createdOn: string;
  updatedOn: string;
  container_id: string;
};
export const WORKFLOW_ARITHMETIC_NODE_CONFIG = {
  ...CORE_NODE_CONFIG,
  type: DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic,
};
// ----- Arithmetic END -----

// ----- Format Conversion Functions START -----
export type CompactWorkflowFormat = {
  nodes: {
    [key: string]: {
      class: string;
      config: Record<string, any>;
      position: XYPosition;
      type: string;
      id: string;
    };
  };
  edges: [string, string][];
};

export type FlatWorkflowFormat = {
  nodes: Node[];
  edges: Edge[];
};

export const convertToCompactFormat = (flatFormat: FlatWorkflowFormat): CompactWorkflowFormat => {
  const nodes: CompactWorkflowFormat['nodes'] = {};
  const edges: [string, string][] = [];

  flatFormat.nodes.forEach((node) => {

    nodes[node.id] = {
      class: node.type || '',
      config: node.data,
      position: node.position,
      type: node.type || '',
      id: node.id,
    };
  });

  // Convert edges
  flatFormat.edges.forEach((edge) => {
    edges.push([edge.source, edge.target]);
  });

  return { nodes, edges };
};

export const convertFromCompactFormat = (compactFormat: CompactWorkflowFormat): FlatWorkflowFormat => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  // Convert nodes
  Object.entries(compactFormat.nodes).forEach(([nodeId, nodeData]) => {
    const node: Node = {
      id: nodeId,
      type: nodeData.class,
      position: nodeData.position,
      data: nodeData.config,
      width: DEFAULT_NODE_WIDTH,
      height: DEFAULT_NODE_HEIGHT
    };

    nodes.push(node);
  });

  // Convert edges
  compactFormat.edges.forEach(([source, target], index) => {
    edges.push({
      id: `edge-${index}`,
      source,
      target,
      type: 'step'
    });
  });

  return { nodes, edges };
};
// ----- Format Conversion Functions END -----

export type GetQueryReactWorkflowSnippet = {
  id: string;
  workflow?: WorkflowType;
  data?: WorkflowType;
};

export type PutQueryWorkflowReactWorkflowInput = {
  workflow: ReactFlowMainType;
  data_fields: any[];
};