import { Query } from "../callApi";
import {
  DeleteQueryExcellenceDashboard,
  DeleteQueryExcellenceDashboardFileInput,
  PostQueryCreateDashboardPermission,
  PostQueryExcellenceDashboardInput,
  PostQueryExcellenceUploadFileInput,
  PostQueryExcellenceWidgetsInput,
  PutQueryExcellenceDashboardInput,
} from "./apiExcellenceInputs";

export const postQueryExcellenceDashboard = (
  input: PostQueryExcellenceDashboardInput
): Query => ({
  endpoint: "excellence/dashboard",
  method: "POST",
  variables: input,
});

export const postQueryExcellenceWidgets = (
  input: PostQueryExcellenceWidgetsInput
): Query => ({
  endpoint: "excellence/widgets",
  method: "POST",
  variables: input,
});

export const putQueryExcellenceDashboard = (
  input: PutQueryExcellenceDashboardInput
): Query => ({
  endpoint: "excellence/dashboard",
  method: "PUT",
  variables: input,
});
export const deleteQueryExcellenceDashboard = (
  id: DeleteQueryExcellenceDashboard
): Query => ({
  endpoint: `excellence/dashboard/${id}`,
  method: "DELETE",
});

export const postQueryExcellenceUploadFile = (
  dashboardID: string,
  input: PostQueryExcellenceUploadFileInput
): Query => ({
  endpoint: `excellence/dashboard/${dashboardID}/file`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const deleteQueryExcellenceDashboardFile = (
  id: DeleteQueryExcellenceDashboardFileInput,
  filename: string
): Query => ({
  endpoint: `excellence/dashboard/${id}/file/${filename}`,
  method: "DELETE",
});

export const postQueryExcellenceDashboardPermission = (
  dashboardId: string,
  input: PostQueryCreateDashboardPermission
): Query => ({
  endpoint: `excellence/dashboard/${dashboardId}/permission`,
  method: "POST",
  variables: input,
});

export const postQueryExcellenceDashboardPublic = (dashboardId: string): Query => ({
  endpoint: `excellence/dashboard/${dashboardId}/permission/public`,
  method: "POST",
});

export const deleteQueryExcellenceDashboardPublic = (dashboardId: string): Query => ({
  endpoint: `excellence/dashboard/${dashboardId}/permission/public`,
  method: "DELETE",
});

export const updateQueryExcellenceDashboardPermission = (
  dashboardId: string,
  input: PostQueryCreateDashboardPermission
): Query => ({
  endpoint: `excellence/dashboard/${dashboardId}/permission`,
  method: "PUT",
  variables: input,
});

export const deleteExcellenceDasboardPermission = (
  dashboardId: string,
  permissionId: string
): Query => ({
  endpoint: `excellence/dashboard/${dashboardId}/permission/${permissionId}`,
  method: "DELETE",
});

export const postQueryExcellenceAnomalyRule = (
  container_id: string,
  parameter_id: string,
  checked_period: number,
  threshold: number
): Query => ({
  endpoint: "data_manager/anomaly-rule",
  method: "POST",
  variables: {
    container_id,
    parameter_id,
    checked_period,
    threshold,
  },
});
