import { useParams } from "react-router-dom";
import {
  ReactFlowMainType,
  RenderForm,
} from "../../Components/SmallComponents/ReactFlowComponents/reactFlowUtils";
import ReactFlowMain from "../../Components/SmallComponents/ReactFlowComponents/ReactFlowMain";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import { useState } from "react";
import { convertFromCompactFormat, convertToCompactFormat, DATA_MANAGER_FLOW_NODE_TYPE, getColumnOptionsFromTable, WorkflowsRowAggregationNodeData } from "./Components/DataFlow/workflowUtils";
import InitialNode from "./Components/DataFlow/Nodes/InitialNode";
import ParameterNode from "./Components/DataFlow/Nodes/Parameter/ParameterNode";
import ConcatNode from "./Components/DataFlow/Nodes/Concat/ConcatNode";
import FilterNode from "./Components/DataFlow/Nodes/Filter/FilterNode";
import ColumnFilterNode from "./Components/DataFlow/Nodes/ColumnFilter/ColumnFilterNode";
import IntervalJoinNode from "./Components/DataFlow/Nodes/IntervalJoin/IntervalJoinNode";
import AggregationNode from "./Components/DataFlow/Nodes/Aggregation/RowAggregationNode";
import ArithmeticNode from "./Components/DataFlow/Nodes/Arithmetic/ArithmeticNode";
import NodeParameterForm from "./Components/DataFlow/Nodes/Parameter/NodeParameterForm";
import NodeConcatForm from "./Components/DataFlow/Nodes/Concat/NodeConcatForm";
import NodeFilterForm from "./Components/DataFlow/Nodes/Filter/NodeFilterForm";
import NodeColumnFilterForm from "./Components/DataFlow/Nodes/ColumnFilter/NodeColumnFilterForm";
import NodeIntervalJoinForm from "./Components/DataFlow/Nodes/IntervalJoin/NodeIntervalJoinForm";
import NodeArithmeticForm from "./Components/DataFlow/Nodes/Arithmetic/NodeArithmeticForm";
import callApi from "../../Api/callApi";
import { getQueryDataManagerRunWorkflow, getQueryDataManagerWorkflow } from "../../Api/DataManager/apiDataManagerGetQueries";
import { useAuthedContext } from "../../context/AuthContext";
import { DataManagerRunWorkflowSnippet, DataManagerWorkflowSnippet } from "../../Api/DataManager/apiDataManagerSnippets";
import { getQueryDataManagerDeleteWorkflow } from "../../Api/DataManager/apiDataManagerDeleteQueries";
import { putQueryDataManagerUpdateWorkflow } from "../../Api/DataManager/apiDataManagerPutQueries";
import { postQueryDataManagerSaveWorkflow } from "../../Api/DataManager/apiDataManagerPostQueries";
import NodeRowAggregationForm from "./Components/DataFlow/Nodes/Aggregation/NodeRowAggregationForm";
import { Box, Button, Stack } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Alert from "../../Components/MaterialUI/Alert";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { useLanguageContext } from "../../context/LanguageContext";
import JoinNode from "./Components/DataFlow/Nodes/Join/JoinNode";
import NodeJoinForm from "./Components/DataFlow/Nodes/Join/NodeJoinForm";
import ColumnAggregationNode from "./Components/DataFlow/Nodes/ColumnAggregation/ColumnAggregationNode";
import NodeColumnAggregationForm from "./Components/DataFlow/Nodes/ColumnAggregation/NodeColumnAggregationForm";
import { Node } from "reactflow";

const DataWorkflowSinglePage: React.FC = () => {
  const { t } = useLanguageContext();
  const [allTables, setAllTables] = useState<{ [nodeId: string]: any }>({});
  const nodeOptions = [
    {
      label: "Parameter Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.Parameter,
      description: "Parameter Node"
    },
    {
      label: "Concat Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.Concat,
      description: "Concat Node"
    },
    {
      label: "Filter Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.Filter,
      description: "Filter Node"
    },
    {
      label: "Column Filter Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter,
      description: "Column Filter Node"
    },
    {
      label: "Interval Join Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin,
      description: "Interval Join Node"
    },
    {
      label: "Join Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.Join,
      description: "Join Node"
    },
    {
      label: "Row Aggregation Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation,
      description: "Row Aggregation Node"
    },
    {
      label: "Column Aggregation Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation,
      description: "Column Aggregation Node"
    },
    {
      label: "Arithmetic Node",
      value: DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic,
      description: "Arithmetic Node"
    },
  ];


  const { id } = useParams();
  const { setAuthedUser } = useAuthedContext();
  const [selectedType, setSelectedType] = useState<DATA_MANAGER_FLOW_NODE_TYPE | "">(
    DATA_MANAGER_FLOW_NODE_TYPE.Parameter
  );
  const [typeConfig, setTypeConfig] = useState<any>({
    label: "Parameter Node",
    handle: true,
    type: DATA_MANAGER_FLOW_NODE_TYPE.Parameter,
  });
  const [workflow, setWorkflow] = useState<ReactFlowMainType | null>(null);
  const [runStatus, setRunStatus] = useState<FormStatuses>(null);
  const [runAlertMessage, setRunAlertMessage] = useState<string | null>(null);

  const redirectPathName = `${ROUTES_MAPPING["Data-Workflow Modeler"]}`;

  const nodeTypes = {
    [DATA_MANAGER_FLOW_NODE_TYPE.Initial]: (props: any) => <InitialNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.Parameter]: (props: any) => <ParameterNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.Concat]: (props: any) => <ConcatNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.Filter]: (props: any) => <FilterNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter]: (props: any) => <ColumnFilterNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin]: (props: any) => <IntervalJoinNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.Join]: (props: any) => <JoinNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation]: (props: any) => <AggregationNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation]: (props: any) => <ColumnAggregationNode {...props} allTables={allTables} />,
    [DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic]: (props: any) => <ArithmeticNode {...props} allTables={allTables} />,
  };

  const handleOnTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as DATA_MANAGER_FLOW_NODE_TYPE;
    
    // Set different config based on node type
    switch (val) {
      case DATA_MANAGER_FLOW_NODE_TYPE.Parameter:
        setTypeConfig({
          label: "Parameter Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.Parameter,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.Concat:
        setTypeConfig({
          label: "Concat Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.Concat,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.Filter:
        setTypeConfig({
          label: "Filter Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.Filter,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter:
        setTypeConfig({
          label: "Column Filter Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin:
        setTypeConfig({
          label: "Interval Join Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.Join:
        setTypeConfig({
          label: "Join Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.Join,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation:
        setTypeConfig({
          label: "Aggregation Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation:
        setTypeConfig({
          label: "Column Aggregation Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation,
        });
        break;
      case DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic:
        setTypeConfig({
          label: "Arithmetic Node",
          handle: true,
          type: DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic,
        });
        break;
      default:
        setTypeConfig({
          label: "Unknown Node",
          handle: true,
        });
    }
    
    setSelectedType(val);
  };

  const fetchTableData = async (nodeId: string) => {
    const res = await callApi<DataManagerRunWorkflowSnippet>({
      query: getQueryDataManagerRunWorkflow(id || '', nodeId),
      auth: { setAuthedUser },
    });
    return res;
  };

  const fetchAllTables = async (nodes: Node<any>[]) => {
    if (!id) {
      throw new Error("Workflow ID is required");
    }

    try {
      const allTableData: { [nodeId: string]: any } = {};
      
      const fetchPromises = nodes.map(async (node) => {
        try {
          const tableData = await fetchTableData(node.id);
          allTableData[node.id] = tableData;
        } catch (error) {
          console.error(`Error fetching data for node ${node.id}:`, error);
          allTableData[node.id] = null;
        }
      });
      
      await Promise.all(fetchPromises);
      
      return allTableData;
    } catch (error) {
      console.error("Error fetching all tables:", error);
      return {};
    }
  };

  const getQueryWorkflow = async () => {
    try {
      if (!id) {
        throw new Error("Workflow ID is required");
      }

      const res = await callApi<DataManagerWorkflowSnippet>({
        query: getQueryDataManagerWorkflow(id),
        auth: { setAuthedUser },
      })

      const formattedWorkflowData = convertFromCompactFormat(res.workflow_data);
      
      const workflowData = {
        id: res.id || id,
        name: res.name || "Untitled Workflow",
        details: res.description || "",
        createdOn: res.workflow_data.created_on || new Date().toISOString(),
        updatedOn: res.workflow_data.updated_on || new Date().toISOString(),
        nodes: formattedWorkflowData.nodes || [],
        edges: formattedWorkflowData.edges || []
      };

      setWorkflow(workflowData);
      
      return {
        id: res.id || id,
        data: workflowData
      };
    } catch (err) {
      console.error("Error: Fetch workflow", err);
    }
  };

  const saveReactWorkflow = async (workflow: ReactFlowMainType): Promise<void> => {
    try {
      if (!workflow.id) {
        throw new Error("Workflow ID is required");
      }
  
      const formattedWorkflowData = convertToCompactFormat(workflow);
  
      await callApi({
        query: putQueryDataManagerUpdateWorkflow(
          workflow.id,
          workflow.name,
          workflow.details || "",
          formattedWorkflowData
        ),
        auth: { setAuthedUser },
      });

      setWorkflow(workflow);
      const tablesData = await fetchAllTables(workflow.nodes);
      setAllTables(tablesData);
  
    } catch (err) {
      console.error("Error: Save workflow", err);
    }
  };

  const deleteQueryWorkflow = async (workflowId: string): Promise<void> => {
    await callApi({
      query: getQueryDataManagerDeleteWorkflow(workflowId),
      auth: { setAuthedUser },
    });
  };

  const runWorkflow = async () => {
    try {
      if (!workflow?.id) {
        throw new Error("Workflow ID is required");
      }
  
      setRunStatus("loading");
      setRunAlertMessage(t("Running workflow..."));
  
      await callApi({
        query: postQueryDataManagerSaveWorkflow(
          workflow.name,
          workflow.details || "",
          workflow.id
        ),
        auth: { setAuthedUser },
      });
  
      setRunStatus("success");
      setRunAlertMessage(t("Workflow completed successfully"));
      
      // Auto-clear success message after 3 seconds
      setTimeout(() => {
        setRunStatus(null);
        setRunAlertMessage(null);
      }, 3000);
  
    } catch (err) {
      console.error("Error: Run workflow", err);
      setRunStatus("error");
      setRunAlertMessage(t("Error running workflow"));
    }
  };

  return (
    <Box component="div" sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <Stack spacing={2} sx={{ position: 'absolute', top: 16, left: 16, zIndex: 1000 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={runWorkflow}
          startIcon={<PlayArrowIcon />}
          disabled={!workflow?.id || runStatus === "loading"}
          sx={{ width: 200 }}
        >
          {runStatus === "loading" ? "Running..." : "Run Workflow"}
        </Button>
        {runAlertMessage && (
          <Alert
            message={runAlertMessage}
            showAlert={!!runAlertMessage}
            severity={runStatus}
            autoClose={runStatus === "success"}
          />
        )}
      </Stack>
      <ReactFlowMain
        getQueryWorkflow={getQueryWorkflow}
        saveReactWorkflow={saveReactWorkflow}
        deleteReactWorkflow={deleteQueryWorkflow}
        redirectPathName={redirectPathName}
        nodeTypes={nodeTypes}
        selectedType={selectedType}
        typeConfig={typeConfig}
        renderForm={(props) => renderForm({ ...props, allTables: allTables || {} })}
        handleOnTypeChange={handleOnTypeChange}
        nodeOptions={nodeOptions as any}
        autosave={true}
      />
    </Box>
  );
};

export default DataWorkflowSinglePage;

const renderForm: RenderForm = ({
  selectedType,
  handleCreateSubmit,
  nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  unsavedChanges,
  sourceNode,
  allTables = {},
}) => {
  switch (selectedType) {
    case DATA_MANAGER_FLOW_NODE_TYPE.Parameter:
      return (
        <NodeParameterForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Parameter: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    case DATA_MANAGER_FLOW_NODE_TYPE.Concat:
      return (
        <NodeConcatForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Concat: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    case DATA_MANAGER_FLOW_NODE_TYPE.Filter:
      return (
        <NodeFilterForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Filter: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      );
    case DATA_MANAGER_FLOW_NODE_TYPE.ColumnFilter:
      return (
        <NodeColumnFilterForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              ColumnFilter: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
          columnOptions={sourceNode ? getColumnOptionsFromTable(allTables[sourceNode.id]) : []}
        />
      );
    case DATA_MANAGER_FLOW_NODE_TYPE.IntervalJoin:
      return (
        <NodeIntervalJoinForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              IntervalJoin: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
          firstColumnOptions={sourceNode ? getColumnOptionsFromTable(allTables[sourceNode.id]) : []}
          secondColumnOptions={[]}
        />
      );
    case DATA_MANAGER_FLOW_NODE_TYPE.Join:
      return (
        <NodeJoinForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Join: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
          firstColumnOptions={sourceNode ? getColumnOptionsFromTable(allTables[sourceNode.id]) : []}
          secondColumnOptions={[]}
        />
      );  
    case DATA_MANAGER_FLOW_NODE_TYPE.RowAggregation:
      return (
        <NodeRowAggregationForm
          handleCreateSubmit={(data: WorkflowsRowAggregationNodeData) =>
            handleCreateSubmit({
              RowAggregation: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
          columnOptions={sourceNode ? getColumnOptionsFromTable(allTables[sourceNode.id]) : []}
        />
      );
    case DATA_MANAGER_FLOW_NODE_TYPE.ColumnAggregation:
      return (
        <NodeColumnAggregationForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              ColumnAggregation: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
          columnOptions={sourceNode ? getColumnOptionsFromTable(allTables[sourceNode.id]) : []}
        />
      );
    case DATA_MANAGER_FLOW_NODE_TYPE.Arithmetic:
      return (
        <NodeArithmeticForm
          handleCreateSubmit={(data) =>
            handleCreateSubmit({
              Arithmetic: data,
            })
          }
          nodes={nodes}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
          columnOptions={sourceNode ? getColumnOptionsFromTable(allTables[sourceNode.id]) : []}
        />
      );
    default:
      return null;
  }
}; 