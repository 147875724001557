import { Box, Stack, Typography } from "@mui/material";
import { WorkflowsRowAggregationNodeData } from "../../workflowUtils";
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";
interface NodeRowAggregationDataModalProps {
  data: WorkflowsRowAggregationNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeRowAggregationDataModal: React.FC<NodeRowAggregationDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();

  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      <LabelWithBoldedPart text={t("Columns")} bolded={data.target_columns?.join(", ") || ""} />
      <LabelWithBoldedPart text={t("Aggregation")} bolded={data.aggregation || ""} />
      <LabelWithBoldedPart text={t("Result Column")} bolded={data.result_column || ""} />
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_RowAggregation_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeRowAggregationDataModal; 