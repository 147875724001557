import { Box, IconButton, Stack, Typography, useTheme, Tooltip } from "@mui/material";
import { useState, useEffect, useMemo, useCallback } from "react";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import ResponsiveTableGrid from "../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { getQueryProcessWizzardOrderTasks, getQueryProcessWizzardTaskStatuses } from "../../../Api/ProcessWizzard/apiProcessWizzardGetQueries";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useLanguageContext } from "../../../context/LanguageContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { GetQueryProcessWizzardTaskStatusesSnippet, KanbanTask } from "../../../Api/ProcessWizzard/apiProcessWizzardSnippets";
import ViewKanbanTask from "./ViewKanbanTask";
import { postQueryProcessWizzardUploadTaskDocs, postQueryProcessWizzardUpdateTask } from "../../../Api/ProcessWizzard/apiProcessWizzardPostQueries";

interface TasksTableProps {
  orderId: string;
  orderNumber: string;
  onBack: () => void;
}

const actionsColumn = {
  "id": "actions",
  "label": "Aktionen",
  "type": "string",
  "symbol": null,
  "width": null,
  "marked": false,
  "markedId": null,
  "columns": null,
  "disableEditing": true,
  "bolded100": false,
  "formatNumb": false,
  "formatNumbDecimals": 2,
  "aggregationFn": null,
  "alignCol": "center",
  "cellsConfig": null,
  "visible": true,
  "size": null
}

const addActionsColumn = (columns: any[]) => {
  return [...columns, actionsColumn];
}

const TasksTable: React.FC<TasksTableProps> = ({ orderId, orderNumber, onBack }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [tableData, setTableData] = useState<any>();
  const [openedTask, setOpenedTask] = useState<KanbanTask | null>(null);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const fetchData = async () => {
    if (!orderId) return;
    
    try {
      const data = await callApi({
        query: getQueryProcessWizzardOrderTasks(orderId),
        auth: { setAuthedUser },
      });
      
      setTableData(data);
    } catch (error) {
      console.error("Error fetching order tasks:", error);
    }
  };

  useEffect(() => {
    const loadStatuses = async () => {
        const data = await callApi<GetQueryProcessWizzardTaskStatusesSnippet>({
            query: getQueryProcessWizzardTaskStatuses(),
            auth: { setAuthedUser },
        });
        setStatuses(data.statuses);
    };
    loadStatuses();
  }, []);
  
  useEffect(() => { 
    fetchData();
  }, []);

  const updateTask = async (orderId: string, taskId: string, status: string, workDescription: string, beforeImage: File | null, afterImage: File | null) => {
    if(beforeImage || afterImage) {
        await callApi({
        query: postQueryProcessWizzardUploadTaskDocs(orderId, taskId, beforeImage, afterImage),
        auth: { setAuthedUser },
        });
    }
    await callApi({
      query: postQueryProcessWizzardUpdateTask(orderId, taskId, status, workDescription),
      auth: { setAuthedUser },
    });
    fetchData();
  }

  const handleViewClick = useCallback((e: React.MouseEvent, row: any) => {
    e.stopPropagation();
    setOpenedTask(row);
    setEditMode(false);
  }, []);

  const handleEditClick = useCallback((e: React.MouseEvent, row: any) => {
    e.stopPropagation();
    setOpenedTask(row);
    setEditMode(true);
  }, []);

  const rowsWithActions = useMemo(() => {
    return tableData?.rows?.map((row: any) => ({
      ...row,
      actions: (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Tooltip title={t("Details")}>
            <IconButton
              onClick={(e) => handleViewClick(e, row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Edit")}>
            <IconButton
              onClick={(e) => handleEditClick(e, row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    }));
  }, [tableData?.rows, handleViewClick, handleEditClick]);
  
  const processedTableData = useMemo(() => {
    if (!tableData) return null;
    return {
      ...tableData,
      columns: addActionsColumn(tableData.columns),
      rows: rowsWithActions
    };
  }, [tableData, rowsWithActions]);

  return (
    <>
      <Stack
        mb={3}
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <div />
        <Typography css={styles.contentBreak} variant="h2" textAlign="center">
          {t("Order Tasks")}
        </Typography>
        <Stack spacing={0.5} alignItems="flex-end" justifyContent="flex-end">
          <IconButton onClick={onBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="body2" color="textSecondary" align="right">
            {`${t("Order Number")}: ${orderNumber}`}
          </Typography>
        </Stack>
      </Stack>

      {tableData && (
        <Box component="div">
          <ResponsiveTableGrid
            rows={processedTableData?.rows || []}
            colSchema={processedTableData?.columns || []}
            responsive="responsive"
            configuration={processedTableData?.configuration || {}}
            tableID="ProcessWizzard_TasksTable"
          />
        </Box>
      )}
      {openedTask && (
        <ViewKanbanTask task={openedTask} onClose={() => setOpenedTask(null)} updateTask={updateTask} statuses={statuses || []} editMode={editMode}/>
      )}
    </>
  );
};

export default React.memo(TasksTable);
