import {
  Box, 
  Divider, 
  FormControlLabel,
  Grid, 
  Stack, 
  Switch,
  Typography, 
  useTheme
} from "@mui/material";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../../Components/MaterialUI/Button";
import { FormStatuses } from "../../../../../../Global/Types/commonTypes";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import { Node } from "reactflow";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { WorkflowsConcatNodeData } from "../../workflowUtils";
import { DATA_MANAGER_FLOW_NODE_TYPE } from "../../workflowUtils";

interface NodeConcatFormProps {
  handleCreateSubmit?: (data: WorkflowsConcatNodeData) => void;
  handleEditSubmit?: (data: WorkflowsConcatNodeData) => void;
  data?: WorkflowsConcatNodeData;
  nodes: Node<WorkflowsConcatNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const NodeConcatForm: React.FC<NodeConcatFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  //nodes,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  //unsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [name, setName] = useState<string>(data?.name || "Concat");
  const [includeCommonOnly, setIncludeCommonOnly] = useState<boolean>(
    data?.include_common_only || false
  );
  const [formStatus] = useState<FormStatuses>(null);
  const [alertMessage] = useState<string | null>(null);

  const nodeType = DATA_MANAGER_FLOW_NODE_TYPE.Concat;

  const initialFormState = useRef({
    name,
    includeCommonOnly,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { name, includeCommonOnly },
    handleSetUnsavedChanges
  );

  const handleFormSubmit = () => {
    const current = new Date().toISOString();
    const result: WorkflowsConcatNodeData = {
      id: data?.id || uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      include_common_only: includeCommonOnly,
      createdOn: data?.createdOn || current,
      updatedOn: current,
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Concat Configuration")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeCommonOnly}
                    onChange={(e) => setIncludeCommonOnly(e.target.checked)}
                    name="includeCommonOnly"
                  />
                }
                label={t("Include Common Only")}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Stack css={styles.width100} spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            {t("Metadata")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider />

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Box component="div" css={styles.flexCenter}>
        <Button css={[styles.width100, styles.widthLimit20]} onClick={handleFormSubmit}>
          {t("Save Changes")}
        </Button>
      </Box>
    </Stack>
  );
};

export default NodeConcatForm; 