import { FC, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF, Bounds } from "@react-three/drei";
import { Mesh, MeshStandardMaterial } from "three";

interface GLTFModelProps {
  modelPath: string;
  onLayersExtracted?: (layers: string[]) => void;
  highlightedLayer?: string | null;
  layerVisibility: { [key: string]: boolean };
}

const GLTFModel: FC<GLTFModelProps> = ({
  modelPath,
  onLayersExtracted,
  highlightedLayer,
  layerVisibility,
}) => {
  const { scene, nodes } = useGLTF(modelPath);
  const [originalMaterials, setOriginalMaterials] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (onLayersExtracted) {
      const layerNames = Object.keys(nodes).filter((key) => nodes[key] instanceof Mesh);
      onLayersExtracted(layerNames);
    }
  }, [nodes, onLayersExtracted]);

  useEffect(() => {
    // Store original materials to restore when unhighlighted
    const materials: { [key: string]: any } = {};
    Object.keys(nodes).forEach((key) => {
      if (nodes[key] instanceof Mesh) {
        materials[key] = (nodes[key] as Mesh).material;
      }
    });
    setOriginalMaterials(materials);
  }, [nodes]);

  useEffect(() => {
    Object.keys(nodes).forEach((key) => {
      if (nodes[key] instanceof Mesh) {
        const mesh = nodes[key] as Mesh;

        // Make sure mesh exists before manipulating
        if (mesh) {
          // Toggle visibility for the selected layers
          mesh.visible = layerVisibility[key] ?? true;

          // Highlight the selected layer
          mesh.material = key === highlightedLayer
            ? new MeshStandardMaterial({ color: "yellow", emissive: "yellow", emissiveIntensity: 0.5 })
            : originalMaterials[key];
        }
      }
    });
  }, [highlightedLayer, nodes, originalMaterials, layerVisibility]);

  return <primitive object={scene} />;
};

const GLTFViewer: FC<{ modelPath: string }> = ({ modelPath }) => {
  const [layers, setLayers] = useState<string[]>([]);
  const [highlighted, setHighlighted] = useState<string | null>(null);
  const [layerVisibility, setLayerVisibility] = useState<{ [key: string]: boolean }>({});

  const handleLayerClick = (layer: string) => {
    // Toggle visibility of the clicked layer
    setLayerVisibility((prev) => ({
      ...prev,
      [layer]: !prev[layer], // Toggle the visibility of this layer
    }));

    // Set the clicked layer as highlighted
    setHighlighted(layer === highlighted ? null : layer);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
      {/* Sidebar for Layer Selection */}
      <div
        style={{
          width: "250px",
          background: "#f4f4f4",
          padding: "10px",
          borderRight: "2px solid #ccc",
          height: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <strong>Highlight Layers:</strong>
        <div style={{ overflowY: "auto", flexGrow: 1 }}>
          {layers.map((layer) => (
            <button
              key={layer}
              onClick={() => handleLayerClick(layer)}
              style={{
                display: "block",
                width: "100%",
                margin: "5px 0",
                padding: "8px",
                background: highlighted === layer ? "yellow" : "#ddd",
                border: "none",
                cursor: "pointer",
              }}
            >
              {layer}
            </button>
          ))}
        </div>
      </div>

      {/* 3D Viewer */}
      <div style={{ flexGrow: 1, height: "100%", position: "relative" }}>
        <Canvas style={{ width: "100%", height: "100%" }}>
          <ambientLight intensity={0.5} />
          <directionalLight position={[2, 2, 5]} />
          <OrbitControls />
          <Bounds fit observe margin={1.2}>
            <GLTFModel
              modelPath={modelPath}
              onLayersExtracted={setLayers}
              highlightedLayer={highlighted}
              layerVisibility={layerVisibility}
            />
          </Bounds>
        </Canvas>
      </div>
    </div>
  );
};

export default GLTFViewer;
