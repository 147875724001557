import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import cssSpacingStyles from "../../Global/Styles/spacing";
import {
  calculateMachineStatusDurationInSeconds,
  filterDataByMessage,
  filterDataByTimeRange,
  getMachineSelectOptions,
  MachineStatusChartData,
  OperatingConditionChartData,
  transformMachineStatusChartData,
  transformOperatingConditionChartData,
} from "../../Components/PageComponents/MachineStatus/machineStatusUtils";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import { machineStatusHistoryDefaultData } from "../../Components/PageComponents/MachineStatus/machineStatusData";
import MachineStatusFilters from "../../Components/PageComponents/MachineStatus/MachineStatusFilters";
import MachineStatusHistoryChart from "../../Components/PageComponents/MachineStatus/MachineStatusHistoryChart";
import MachineStatusHistoryTable from "../../Components/PageComponents/MachineStatus/MachineStatusHistoryTable";
import MachineStatusOperatingConditionChart from "../../Components/PageComponents/MachineStatus/MachineStatusOperatingConditionChart";

const MachineStatusPage: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const [machineStatusHistoryChartData, setMachineStatusHistoryChartData] = useState<
    MachineStatusChartData[]
  >([]);
  const [operatingConditionChartData, setOperatingConditionChartData] = useState<
    OperatingConditionChartData[]
  >([]);
  const [selectedMachineId, setSelectedMachineId] = useState<string>("105443");
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>("Today");
  const [selectedMachineMessage, setSelectedMachineMessage] = useState<string>("All");

  const machineIdSelectOptions = useMemo(() => {
    return handleGetSelectOption(
      getMachineSelectOptions(machineStatusHistoryDefaultData),
      true
    );
  }, []);

  useEffect(() => {
    const filteredTimeData = filterDataByTimeRange(
      machineStatusHistoryDefaultData[selectedMachineId],
      selectedTimeRange
    );
    const updatedData = calculateMachineStatusDurationInSeconds(filteredTimeData);

    const transformedMachineStatusData = transformMachineStatusChartData(
      updatedData,
      selectedMachineId
    );

    const filteredMessageData = filterDataByMessage(
      filteredTimeData,
      selectedMachineMessage
    );

    const transformedOperatingConditionData =
      transformOperatingConditionChartData(filteredMessageData);

    setMachineStatusHistoryChartData(transformedMachineStatusData);
    setOperatingConditionChartData(transformedOperatingConditionData);
  }, [selectedMachineId, selectedTimeRange, selectedMachineMessage]);

  return (
    <>
      <Typography
        css={styles.labelBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        Machine Status
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MachineStatusFilters
            selectedMachineId={selectedMachineId}
            setSelectedMachineId={setSelectedMachineId}
            machineIdSelectOptions={machineIdSelectOptions}
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            selectedMachineMessage={selectedMachineMessage}
            setSelectedMachineMessage={setSelectedMachineMessage}
          />
        </Grid>

        <Grid item xs={12}>
          <MachineStatusHistoryChart
            machineStatusHistoryChartData={machineStatusHistoryChartData}
            selectedMachineMessage={selectedMachineMessage}
            selectedMachineId={selectedMachineId}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <MachineStatusHistoryTable
                selectedMachineId={selectedMachineId}
                selectedTimeRange={selectedTimeRange}
                selectedMachineMessage={selectedMachineMessage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MachineStatusOperatingConditionChart
                operatingConditionChartData={operatingConditionChartData}
                selectedMachineId={selectedMachineId}
                selectedTimeRange={selectedTimeRange}
                selectedMachineMessage={selectedMachineMessage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MachineStatusPage;
