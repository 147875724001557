import TextField from "../../MaterialUI/FormFields/TextFields";
import { Stack, Typography, Box, useTheme, Theme } from "@mui/material";
import {
  formatNumber,
  transformParsedStringToDate,
  transformParsedStringToTime,
} from "../../../Global/Utils/commonFunctions";
import {
  TableGridColAlign,
  TableGridColSymbol,
  TableGridColumnDataTypes,
  TableGridHandleSaveCellChanges,
} from "./tableGridUtils";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import isValid from "date-fns/isValid";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import TimePicker from "../../MaterialUI/DateTimePickers/TimePicker";
import { useCallback, useEffect, useState } from "react";
import Select from "../../MaterialUI/FormFields/Select";

const getHighlightColorForMarkedId = (
  value: number,
  markedId: string,
  row: Record<string, any>,
  getHighlightColor: (condition: boolean) => string | null
): string | null => {
  switch (markedId) {
    case "min":
      return getHighlightColor(value < row["soc_min"]);
    case "soc_min":
      return getHighlightColor(value > row["min"]);
    case "max":
      return getHighlightColor(value > row["soc_max"]);
    case "soc_max":
      return getHighlightColor(value < row["max"]);
    case "avg":
      return getHighlightColor(value > row["soc_avg"]);
    case "soc_avg":
      return getHighlightColor(value < row["avg"]);
    case "vark":
      return getHighlightColor(value > row["soc_vark"]);
    case "soc_vark":
      return getHighlightColor(value < row["vark"]);
    default:
      return null;
  }
};

const markedStyle = (marked: boolean, theme: Theme, value: any) => {
  if (marked) {
    return {
      fontWeight: 600,
      color:
        value === "Out of Order" || value === "Maintenance" || value === "Closed"
          ? theme.palette.error.dark
          : value === "Idle"
          ? theme.palette.warning.dark
          : value === "Planned"
          ? theme.palette.common.black
          : value === "Finished"
          ? theme.palette.success.light
          : theme.palette.success.dark,
    };
  }
  return {};
};

interface ColumnCellProps<T> {
  cellValue: number | string | boolean | null;
  type: TableGridColumnDataTypes;
  marked?: boolean;
  markedId?: string;
  rowData?: T;
  bolded100?: boolean;
  formatNumb?: boolean;
  formatNumbDecimals?: number;
  alignCol?: TableGridColAlign;
  symbol?: TableGridColSymbol;
}

export const TableGridCellComponent = <T,>({
  cellValue,
  type,
  marked,
  markedId,
  rowData,
  bolded100,
  formatNumb,
  formatNumbDecimals,
  alignCol,
  symbol,
}: ColumnCellProps<T>) => {
  const theme = useTheme();
  const getHighlightColor = (condition: boolean) => {
    return condition ? theme.palette.warning.dark : null;
  };

  switch (type) {
    case "string": {
      const value = cellValue as string;
      return (
        <Typography
          sx={{
            ...markedStyle(!!marked, theme, value),
            width: "100%",
          }}
          align={alignCol || "left"}
        >
          {renderCellValue(value ?? "", symbol)}
        </Typography>
      );
    }
    case "number": {
      const value = cellValue as number;
      const formattedValue = formatNumb
        ? formatNumber(+value, true, formatNumbDecimals, true)
        : value ?? "";
      let highlightColor = null;

      if (markedId) {
        const row = rowData as Record<string, any>;
        highlightColor = getHighlightColorForMarkedId(
          value,
          markedId,
          row,
          getHighlightColor
        );
      }
      if (bolded100) {
        console.log(bolded100);
      }
      return (
        <Typography
          sx={
            marked && markedId
              ? {
                  fontWeight: 600,
                  color: highlightColor,
                }
              : marked
              ? {
                  fontWeight: 600,
                  color:
                    +value < 50
                      ? theme.palette.error.dark
                      : +value >= 50 && +value < 80
                      ? theme.palette.warning.dark
                      : theme.palette.success.dark,
                }
              : bolded100 && cellValue == 100
              ? { fontWeight: 700 }
              : null
          }
          style={{ width: "100%" }}
          align={alignCol || "right"}
        >
          {renderCellValue(formattedValue ?? "", symbol)}
        </Typography>
      );
    }

    case "date": {
      const value = cellValue as string;
      return (
        <Typography style={{ width: "100%" }} align={alignCol || "center"}>
          {renderCellValue(value, symbol)}
        </Typography>
      );
    }
    case "time": {
      const value = cellValue as string;
      return (
        <Typography style={{ width: "100%" }} align={alignCol || "center"}>
          {renderCellValue(value, symbol)}
        </Typography>
      );
    }
    case "dateTime": {
      const value = cellValue as string;
      return (
        <Typography style={{ width: "100%" }} align={alignCol || "center"}>
          {renderCellValue(value, symbol)}
        </Typography>
      );
    }
    case "boolean": {
      return (
        <Stack style={{ width: "100%" }} alignItems={alignCol || "center"}>
          {cellValue ? <CheckIcon /> : <CloseIcon />}
        </Stack>
      );
    }
    case "dropdown": {
      const [dropDownValue, setDropDownValue] = useState("Open");
      return (
        <Select
          selectOptions={[
            { value: "Open", description: "Open" },
            { value: "Closed", description: "Closed" },
          ]}
          onChange={(e) => setDropDownValue(e.target.value)}
          value={dropDownValue}
          fullWidth
        />
      );
    }
    default: {
      return (
        <Typography style={{ width: "100%" }} alignItems={alignCol || "left"}>
          {cellValue || ""}
        </Typography>
      );
    }
  }
};

interface ColumnEditProps {
  rowIndex: number;
  colKey: string;
  colValue: any;
  handleSaveCellChanges: TableGridHandleSaveCellChanges;
  type: TableGridColumnDataTypes;
  label?: string;
  disableEditing?: boolean;
}

export const TableGridEditComponent: React.FC<ColumnEditProps> = ({
  rowIndex,
  colKey,
  colValue,
  handleSaveCellChanges,
  type,
  label,
  disableEditing,
}) => {
  const [editedValue, setEditedValue] = useState<any>(colValue);
  const [updatedVal, setUpdatedVal] = useState<any | null>(null);

  const memoisedHandleSaveCellChanges = useCallback(
    (rowIndex: number, colKey: string, value: any) => {
      handleSaveCellChanges(rowIndex, colKey, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (updatedVal !== null) {
      memoisedHandleSaveCellChanges(rowIndex, colKey, updatedVal);
      setUpdatedVal(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedVal]);

  useEffect(() => {
    if (colValue !== editedValue) {
      setEditedValue(colValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colValue]);

  switch (type) {
    case "string": {
      return (
        <TextField
          label={label}
          onChange={(e) => {
            setEditedValue(e.target.value);
          }}
          onBlur={(e) => {
            if (colValue !== e.target.value) {
              setUpdatedVal(e.target.value);
            }
          }}
          value={editedValue}
          disabled={disableEditing}
        />
      );
    }
    case "number": {
      return (
        <TextField
          label={label}
          onChange={(e) => {
            setEditedValue(e.target.value);
          }}
          onBlur={(e) => {
            if (colValue !== e.target.value) {
              setUpdatedVal(+e.target.value);
            }
          }}
          value={editedValue}
          numberField
          InputProps={{ type: "number" }}
          disabled={disableEditing}
          noDecimalLimit
        />
      );
    }
    case "date": {
      return (
        <DatePicker
          label={label}
          onChange={(e) => {
            setEditedValue(e);
          }}
          onBlur={(e) => {
            const parsedVal = transformParsedStringToDate(e.target.value);
            if (parsedVal && isValid(parsedVal)) {
              setUpdatedVal(parsedVal.toISOString());
            }
          }}
          onAccept={(e) => {
            if (e && isValid(e)) {
              setUpdatedVal(e.toISOString());
            }
          }}
          value={
            isValid(editedValue) ? editedValue : transformParsedStringToDate(editedValue)
          }
          disabled={disableEditing}
        />
      );
    }
    case "time": {
      return (
        <TimePicker
          label={label}
          views={["hours", "minutes", "seconds"]}
          onBlur={(e) => {
            const parsedVal = transformParsedStringToTime(e.target.value);
            if (parsedVal && isValid(parsedVal)) {
              setUpdatedVal(parsedVal.toISOString());
            }
          }}
          onAccept={(e) => {
            if (e && isValid(e)) {
              setUpdatedVal(e.toISOString());
            }
          }}
          value={
            isValid(editedValue) ? editedValue : transformParsedStringToTime(editedValue)
          }
          disabled={disableEditing}
        />
      );
    }
    case "dateTime": {
      return null;
    }
    case "boolean": {
      return (
        <Stack alignItems="center">
          <Checkbox
            label={label}
            checked={Boolean(editedValue)}
            onChange={(e) => {
              setEditedValue(e.target.checked);
            }}
            onBlur={() => {
              if (colValue !== editedValue) {
                setUpdatedVal(Boolean(editedValue));
              }
            }}
            disabled={disableEditing}
          />
        </Stack>
      );
    }
    case "button": {
      return <Box component="div">{editedValue}</Box>;
    }
    case "dropdown": {
      const [dropDownValue, setDropDownValue] = useState("Open");
      return (
        <Select
          selectOptions={[
            { value: "Open", description: "Open" },
            { value: "Closed", description: "Closed" },
          ]}
          onChange={(e) => setDropDownValue(e.target.value)}
          value={dropDownValue}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export const renderCellValue = (
  realVal: string | number | Date,
  symbol: TableGridColSymbol | undefined
): string => {
  if (!symbol) {
    return realVal as string;
  }

  if (symbol.align === "right") {
    return `${realVal} ${symbol.value}`;
  }

  return `${symbol.value} ${realVal}`;
};
