import React, { memo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Divider, Typography } from "@mui/material";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { useReactFlow } from "reactflow";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  DATA_MANAGER_FLOW_NODE_TYPE,
  WorkflowsFilterNodeData,
} from "../../../DataFlow/workflowUtils";
import FlowNode from "../FlowNode";
import Button from "../../../../../../Components/MaterialUI/Button";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import { useTranslatedModalTitle } from "../../../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import NodeFilterForm from "./NodeFilterForm";
import NodeFilterDataModal from "./NodeFilterDataModal";

const modalTitleTranslations = {
  "Edit Node": "Edit Node",
  "Node Data": "Node Data",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

interface FlowCustomNodeProps {
  id: string;
  data: WorkflowsFilterNodeData;
  allTables: { [nodeId: string]: any }
}

const FilterNode: React.FC<FlowCustomNodeProps> = ({ id, data, allTables }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodes, getNodes } = useReactFlow();

  const handleEditSubmit = (nodeData: WorkflowsFilterNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit Node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  const handleViewData = async () => {
    setModalType("Node Data");
  };

  return (
    <>
      <FlowNode
        id={id}
        handleOpenEditModal={handleOpenEditModal}
        icon={<FilterAltOutlinedIcon fontSize="small" />}
        nodeType={DATA_MANAGER_FLOW_NODE_TYPE.Filter}
      >
        <Typography css={styles.textBreak} variant="h3" align="center">
          {data.name || 'Filter'}
        </Typography>
        <Divider css={styles.textBreak} color="lightgrey" />
        <Box component="div" css={styles.flexCenter}>
          <Button disabled={!allTables[id]} variant="outlined" onClick={handleViewData}>
            {t("View Data")}
          </Button>
        </Box>
      </FlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        maxWidth="lg"
        fullWidth
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Node" ? (
          <NodeFilterForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
        {modalType === "Node Data" ? <NodeFilterDataModal data={data} tableData={allTables[id]} /> : null}
      </Modal>
    </>
  );
};

export default memo(FilterNode); 