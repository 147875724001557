import { Box, Stack, Typography } from "@mui/material";
import { WorkflowsArithmeticNodeData } from "../../../DataFlow/workflowUtils";
import LabelWithBoldedPart from "../../../../../../Components/MaterialUI/LabelWithBoldedPart";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { DataManagerRunWorkflowSnippet } from "../../../../../../Api/DataManager/apiDataManagerSnippets";
import ResponsiveTableGrid from "../../../../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";

interface NodeArithmeticDataModalProps {
  data: WorkflowsArithmeticNodeData;
  tableData: DataManagerRunWorkflowSnippet | null;
}

const NodeArithmeticDataModal: React.FC<NodeArithmeticDataModalProps> = ({ data, tableData }) => {
  const { t } = useLanguageContext();
  
  const getOperationSymbol = (operation: string): string => {
    switch (operation) {
      case 'add': return '+';
      case 'subtract': return '-';
      case 'multiply': return '×';
      case 'divide': return '÷';
      default: return operation;
    }
  };
  
  return (
    <Stack spacing={1}>
      <LabelWithBoldedPart text={t("ID")} bolded={data.id} />
      <LabelWithBoldedPart text={t("Name")} bolded={data.name || ""} />
      <LabelWithBoldedPart text={t("Left Column")} bolded={data.left_column || ""} />
      <LabelWithBoldedPart text={t("Operation")} bolded={`${data.operation} ${getOperationSymbol(data.operation)}`} />
      <LabelWithBoldedPart text={t("Right Column")} bolded={data.right_column || ""} />
      <LabelWithBoldedPart text={t("Result Column")} bolded={data.result_column || ""} />
      {tableData && (
        <Box component="div">
          <Typography textAlign="center" variant="h5">{t("Workflow Result")}</Typography>
          <ResponsiveTableGrid
            rows={tableData?.rows || []}
            colSchema={tableData?.columns || []}
            responsive="responsive"
            configuration={tableData?.configuration || {}}
            tableID={`DataManager_Arithmetic_${data.id}_table`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default NodeArithmeticDataModal; 