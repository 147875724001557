import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import {
  BasicTableColumnCell,
  BasicTableRow,
} from "../../Components/MaterialUI/BasicTable/basicTableUtils";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { AppRouterProps } from "../../Layout/layoutVariables";
import Button from "../../Components/MaterialUI/Button";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import Alert from "../../Components/MaterialUI/Alert";
import TextField from "../../Components/MaterialUI/FormFields/TextFields";
import BasicTable from "../../Components/MaterialUI/BasicTable/BasicTable";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import Modal from "../../Components/MaterialUI/Modal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateDataWorkflowForm from "./Components/DataFlow/CreateDataWorkflowForm";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import { ModalLayoutData } from "../../Components/SmallComponents/TableGrid/CellActionButton";
import callApi from "../../Api/callApi";
import { DataManagerWorkflowSnippet } from "../../Api/DataManager/apiDataManagerSnippets";
import { getQueryDataManagerAllWorkflows } from "../../Api/DataManager/apiDataManagerGetQueries";
import { useAuthedContext } from "../../context/AuthContext";
import { formatDateAndTime } from "../../Global/Utils/commonFunctions";
import { getQueryDataManagerDeleteWorkflow } from "../../Api/DataManager/apiDataManagerDeleteQueries";

const TABLE_COLUMNS: BasicTableColumnCell[] = [
  { id: "name", label: "Workflow Name" },
  { id: "lastUpdated", label: "Last Updated" },
  { id: "createdAt", label: "Created on" },
  { id: "actions", label: "" },
];

const DataManagerWorkflowsPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [workflowRows, setWorkflowRows] = useState<BasicTableRow[]>([]);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string>("");

  const [displayedRows, setDisplayedRows] = useState<BasicTableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu } =
    useOutletContext<AppRouterProps>();

  const fetchWorkflows = async () => {
    try {
      setPageStatus("loading");
      setAlertMessage(t("Loading..."));

      const res = await callApi<DataManagerWorkflowSnippet[]>({
        query: getQueryDataManagerAllWorkflows(),
        auth: { setAuthedUser },
      });

      const rows: BasicTableRow[] = res.map((item) => ({
        id: item.id,
        name: item.name,
        lastUpdated: formatDateAndTime(item.updated_at),
        createdAt: formatDateAndTime(item.created_at),
      }));

      setWorkflowRows(rows);
      setPageStatus("success");
      setAlertMessage(null);
    } catch (err) {
      console.log("useEffect err ", err);
      setPageStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  useEffect(() => {
    fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Data Workflows")} />);
      setExtraRightNavMenu(
        <CreateNewWorkflow handleOpenModal={() => openModal("createNewWorkflow", "")} />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    setDisplayedRows(workflowRows);
  }, [workflowRows]);

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (!val) {
      setDisplayedRows(workflowRows);
    } else {
      setDisplayedRows(() =>
        workflowRows.filter((item) =>
          `${item.name}`.toLowerCase().includes(val.toLowerCase())
        )
      );
    }
    setSearchQuery(val);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  const openModal = (type: string, data: string) => {
    setOpenInfo(true);
    setModalData({ type, data });
  };

  const handleDeleteWorkflow = async () => {
    try {
      setPageStatus("loading");
      setAlertMessage(t("Deleting workflow..."));

      await callApi({
        query: getQueryDataManagerDeleteWorkflow(selectedWorkflowId),
        auth: { setAuthedUser },
      });

      await fetchWorkflows();
      
      setOpenInfo(false);
      setModalData(null);
      setSelectedWorkflowId("");
      
      setPageStatus("success");
      setAlertMessage(t("Workflow deleted successfully"));
      
      setTimeout(() => {
        setAlertMessage(null);
      }, 3000);
      
    } catch (err) {
      console.log("handleDeleteWorkflow error:", err);
      setPageStatus("error");
      setAlertMessage(t("Failed to delete workflow"));
    }
  };

  return (
    <Box component="div">
      {smMediaQuery ? (
        <Stack css={styles.flexCenter}>
          <PageTitle css={styles.labelBreak} title={t("Data Workflows")} />
          <Button
            css={styles.sectionBreak}
            onClick={() => openModal("createNewWorkflow", "")}
          >
            {t("Create New Data Workflow")}
          </Button>
        </Stack>
      ) : null}

      <Alert
        css={[styles.width100, styles.widthLimit25]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      {workflowRows.length ? (
        <ContentBox css={[styles.height100, styles.contentBreak]}>
          <TextField label={t("Search")} value={searchQuery} onChange={handleOnSearch} />
        </ContentBox>
      ) : null}

      <BasicTable
        css={styles.sectionBreak}
        data={{
          rows: displayedRows,
          columns: useTranslateFields(TABLE_COLUMNS, ["label"]),
        }}
        defaultOrderBy="name"
        isLoading={pageStatus === "loading"}
        emptyTableMessage={
          searchQuery
            ? "No results found. Try searching for something else."
            : "You haven't created any data workflows yet."
        }
        handleOnClick={{
          handleRowLink: (id) => `${ROUTES_MAPPING["Data Manager-Workflows"]}/${id}`,
        }}
        renderExtraCell={(row) => (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedWorkflowId(row.id.toString());
              openModal("deleteWorkflow", row.name.toString());
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )}
      />

      {modalData ? (
        <Modal
          open={openInfo}
          onClose={() => setOpenInfo(false)}
          fullWidth
          maxWidth="sm"
          label={t(getModalLabel(modalData))}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "createNewWorkflow" ? (
            <CreateDataWorkflowForm
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          
          {modalData.type === "deleteWorkflow" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete the workflow")} "{modalData.data}"?
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={handleDeleteWorkflow}
                >
                  {t("Delete Workflow")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </Box>
  );
};

export default DataManagerWorkflowsPage;

interface CreateNewWorkflowProps {
  handleOpenModal: () => void;
}

const CreateNewWorkflow: React.FC<CreateNewWorkflowProps> = ({ handleOpenModal }) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton aria-label="create new data workflow" onClick={handleOpenModal}>
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};

const getModalLabel = (modalData: ModalLayoutData | null): string => {
  if (!modalData) return "";

  switch (modalData.type) {
    case "createNewWorkflow":
      return "Create New Data Workflow";
    case "deleteWorkflow":
      return "Delete Workflow";
    default:
      return "";
  }
}; 