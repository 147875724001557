import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography, FormControl, Grid, Paper, Link } from "@mui/material";
import FileDropzone from "../../../Components/SmallComponents/Dropzone/FileDropzone";
import { KanbanTask } from "../../../Api/ProcessWizzard/apiProcessWizzardSnippets";
import Select from "../../../Components/MaterialUI/FormFields/Select";
import { useLanguageContext } from "../../../context/LanguageContext";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import Alert from "../../../Components/MaterialUI/Alert";

interface ViewKanbanTaskProps {
  task: KanbanTask;
  onClose: () => void;
  updateTask: (orderId: string, taskId: string, status: string, workDescription: string, beforeImage: File | null, afterImage: File | null) => void;
  statuses: string[];
  editMode: boolean;
}

const ViewKanbanTask: React.FC<ViewKanbanTaskProps> = ({ task, onClose, updateTask, statuses = [], editMode = false}) => {
  const { t } = useLanguageContext(); 
  const [beforeImage, setBeforeImage] = useState<File | null>(null);
  const [afterImage, setAfterImage] = useState<File | null>(null);
  const [workDescription, setWorkDescription] = useState(task.documentation_text || "");
  const [status, setStatus] = useState(task.status || "Offen");
  const [alertMessage, setAlertMessage] = useState("");
  const [formStatus, setFormStatus] = useState<FormStatuses | undefined>(undefined);

  const handleFinishTask = () => {
    if (task.requires_docs && (!(beforeImage || task.before_image_url) || !(afterImage || task.after_image_url) || !workDescription) && status === "Abgeschlossen") {
      setAlertMessage(t("Please upload both images and fill in the work description to finish the task."));
      setFormStatus("error");
      return;
    }
    setAlertMessage("");
    setFormStatus(undefined);
    updateTask(task.order_id, task.id, status, workDescription, beforeImage, afterImage);
    onClose();
  };

  const currentlyUploadedBeforeImageName = task.before_image_url ? task.before_image_url.split("/").pop() : null;
  const currentlyUploadedAfterImageName = task.after_image_url ? task.after_image_url.split("/").pop() : null;


  return (
    <Modal 
      open 
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box 
        component="div" 
        sx={{ 
          padding: 3, 
          backgroundColor: "white", 
          borderRadius: "8px",
          maxWidth: "600px",
          width: "90%",
          maxHeight: "90vh",
          overflow: "auto"
        }}
      >
        <Box component="div" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" fontWeight="bold">
            {task.description || "#" + task.id.substring(0, 8)}
          </Typography>
        </Box>
        
        <TextField
          label={t("Description")}
          placeholder={t("Describe what has been done for this task")}
          value={workDescription}
          onChange={(e) => setWorkDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
          disabled={!editMode}
        />
        
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>{t("Upload Images for Documentation Purposes")}:</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper 
              elevation={1} 
              sx={{ 
                p: 1, 
                height: '100%',
                border: beforeImage ? '2px solid #4caf50' : '1px dashed #ccc',
                borderRadius: 1,
                transition: 'all 0.3s',
                opacity: !editMode ? 0.7 : 1,
                pointerEvents: !editMode ? 'none' : 'auto'
              }}
            >
                <Box component="div">
                    <FileDropzone setFiles={(files) => files.length > 0 ? setBeforeImage(files[0]) : setBeforeImage(null)} accept={{ "image/*": [] }} setErrorMessage={() => {}}>
                        <Box component="div" sx={{ textAlign: 'center', py: 1 }}>
                        <Typography variant="subtitle2" gutterBottom>{t("Before")}</Typography>
                        {beforeImage ? (
                            <Typography variant="body2" color="success.main">
                            ✓ {beforeImage.name} ({Math.round(beforeImage.size / 1024)} KB)
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                            {t("Drop image or click to browse")}
                            </Typography>
                        )}
                        </Box>
                    </FileDropzone>
                </Box>      
            </Paper>
            {currentlyUploadedBeforeImageName && (
              <Typography variant="subtitle2" gutterBottom>
                {t("Uploaded")}: <Link href={`https://${task.before_image_url}`} target="_blank" rel="noopener noreferrer">{currentlyUploadedBeforeImageName}</Link>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper 
              elevation={1} 
              sx={{ 
                p: 1, 
                height: '100%',
                border: afterImage ? '2px solid #4caf50' : '1px dashed #ccc',
                borderRadius: 1,
                transition: 'all 0.3s',
                opacity: !editMode ? 0.7 : 1,
                pointerEvents: !editMode ? 'none' : 'auto'
              }}
            >
                <Box component="div">
                    <FileDropzone setFiles={(files) => files.length > 0 ? setAfterImage(files[0]) : setAfterImage(null)} accept={{ "image/*": [] }} setErrorMessage={() => {}}>
                        <Box component="div" sx={{ textAlign: 'center', py: 1 }}>
                        <Typography variant="subtitle2" gutterBottom>{t("After")}</Typography>
                        {afterImage ? (
                            <Typography variant="body2" color="success.main">
                            ✓ {afterImage.name} ({Math.round(afterImage.size / 1024)} KB)
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                              {t("Drop image or click to browse")}
                            </Typography>
                        )}
                        </Box>
                    </FileDropzone>
                </Box>
            </Paper>
            {currentlyUploadedAfterImageName && (
              <Typography variant="subtitle2" gutterBottom>
                {t("Uploaded")}: <Link href={`https://${task.after_image_url}`} target="_blank" rel="noopener noreferrer">{currentlyUploadedAfterImageName}</Link>
              </Typography>
            )}
          </Grid>
        </Grid>
        
        <Box component="div" sx={{ mt: 4}}>
          <FormControl fullWidth margin="normal">
            <Select 
              label="Status" 
              value={status} 
              selectOptions={statuses.map((status: string) => ({ value: status, description: status }))} 
              onChange={(e) => setStatus(e.target.value)}
              disabled={!editMode}
            />
          </FormControl>
        </Box>
        
        <Box component="div" sx={{ mt: 3, mb: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onClose}>
            {t("Cancel")}
          </Button>
          {editMode ? (
            <Button variant="contained" onClick={handleFinishTask}>
              {t("Save")}
            </Button>
          ) : (
            <Button variant="outlined" onClick={onClose}>
              {t("Close")}
            </Button>
          )}
        </Box>
        <Alert
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={formStatus}
        />
      </Box>
    </Modal>
  );
};

export default ViewKanbanTask; 